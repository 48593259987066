html[data='pseudo'] {
	background-color: transparent;
}
body {
	&.theme-bg-poker {

		&.bg {
			background: transparent url('../img/poker/background_okpoker.svg') no-repeat fixed center;
			background-size: cover;
		}
		main {
			.row.a-no-gutters {
				background-color: $color-w1;
			}

			.form-box {
				background-color: $color-w1;

				.form-control {
					outline: none;
					display: block;
					position: relative;
					margin: 0 auto;
					transition: none;
					border: solid 0.13rem $color-gris;
					border-radius: 5em;
					height: 4.8rem;
					line-height: 1;
					padding: 0 2.8rem;
					font-size: 1.4rem;

					&.label-top,
					&:focus {
						+ label {
							font-size: 0.75em;
							left: 2.75em;
							margin: 0;
							top: 0;
						}
					}

					&.valid-box {
						border-color: $color-gris;
					}

					&.invalid-box {
						border-color: $color-rouge-pseudo;
						border-width: 2px;
					}
				}

				.form-title {
					padding: 0.5em;
				}

				.form-title-inner {
					font-weight: 600;
					border-bottom: solid 0.08em $color-n1;
					color: $color-n1;
					font-size: 1.8rem;
					padding-bottom: 0.5rem;
					text-align: left;
				}

				.form-sub-title {
					.form-sub-title-inner {
						font-family: $font-graphik-medium;
					}
				}

				.form-group {
					margin: 2em 3em;

					#pokerPseudoForm {
						width: 100%;
					}

					.form-group-error {
						margin: 1em 0 0;
						text-align: left;

						.icomoon {
							display: inline-block;
							padding-right: 1.3rem;
							position: relative;
							top: 6px;

							&::before {
								font-size: 1.9rem;
							}
						}

						.icon-checkmark {
							color: $color-vert-pseudo;
						}

						.icon-cross:before {
							color: $color-rouge-pseudo;
						}

						> div > div {
							position: relative;
						}

						img {
							transform: scale(5.5);
							opacity: 1;
							left: 9em;
							top: 4em;
							position: relative;
						}

						.shadow-form {
							border: 1px solid #eee;
							border-radius: 20px;
							position: relative;
							z-index: 1;
							font-size: 1.25rem;
							padding: 2.5rem 0 2.5rem 2.5rem;
							box-shadow: 10px 10px 31px -6px rgba(0, 0, 0, 0.25);

							&:after {
								content: '';
								display: block;
								border-top: 1px solid #eee;
								border-left: 1px solid #eee;
								position: absolute;
								top: -8px;
								left: 20px;
								height: 13px;
								width: 13px;
								transform: rotate(45deg);
								background: white;
								z-index: 0;
							}
						}
					}

					#ok-poker {
						width: 85%;
						margin: 2em 0;
						font-size: 1.7rem;
						border-radius: 5rem;
						padding: 0.5em;
						border: solid 0.09em #ef7d00;

						font-family: $font-graphik-medium;
						color: $color-n1;

						&.active {
							color: $color-n1;
							border-color: #ef7d00;
						}

						&.deactive {
							color: $color-gris;
							border-color: $color-gris;
						}
					}
				}

				.form-back-error {
					padding: 2.1rem 0.7rem;
					border: solid $color-rouge-pseudo;
					margin-bottom: 1.4rem;

					.form-back-error-body {
						font-family: $font-graphik-medium;
						color: $color-rouge-pseudo;
						font-size: 1.2em;
						text-align: left;

						span,
						* {
							font-family: $font-graphik-medium;
							color: $color-rouge-pseudo;
							font-size: 1.3rem;
							text-align: left;
						}

						p {
							margin-left: 4em;
						}

						ul,
						ol {
							padding: 1em 0 1em 4em;

							li {
								list-style-position: inside;
								list-style-type: disc;
								line-height: 1.5em;
							}
						}

						li {
						}
					}

					span {
						color: $color-rouge-pseudo;
					}

					img {
						float: left;
						margin-top: -1.4rem;
						width: 4.2rem;
					}
				}

				.form-group-success {
					.form-group-success-title {
						margin: 4em 0;
						font-family: $font-graphik-black;

						img {
							width: 4em;
						}

						> div {
							margin: 1em 0;
						}
					}

					.form-group-success-body {
						padding: 2em 0;
						background-color: $blanc-casse-poker;
					}
				}
			}

			.info-box {
				background-color: $blanc-casse-poker;
				box-shadow: 0px 3px 40px 5px rgba(0, 0, 0, 0.18);
				min-height: 49rem;
				padding: 1em;

				.pseudologo {
					margin-bottom: 2.5em;
					img {
						width: 15em;
					}
				}

				.pseudo-info-bloc {
					margin-bottom: 2.5em;

					.pseudo-titre {
						font-family: $font-graphik-regular;;

						&:after {
							bottom: 0;
							content: '';
							height: 1rem;
							left: 0.2em;
							position: absolute;
							width: 5em;
							background-color: $color-n1;
						}
					}

					.pseudo-info {
						text-align: left;

						li {
							list-style: decimal;
							font-family: $font-graphik-regular;;
						}
					}
				}
			}

			@include media-breakpoint-down(sm) {
				.info-box {
					.pseudo-info-bloc {
						.pseudo-titre {
							&:after {
								width: 11em;
							}
						}
					}
				}
			}

			.info-box {
				.pseudologo img {
					width: 14rem;
				}

				.pseudo-info-bloc {
					text-align: left;

					.pseudo-titre {
						font-size: 3rem;
						line-height: 2;
						border-bottom: none;
					}

					.pseudo-info {
						padding-left: 2rem;
						padding-top: 1.4rem;

						li {
							font-size: 1.4rem;
							line-height: 1.7;
						}
					}
				}
			}

			.form-box {
				padding: 1em 0.5em;

				.form-group #ok-poker {
					font-size: 1.7rem;
					padding: 1.2rem;
				}
			}

			@include media-breakpoint-up(lg) {
				.form-box {
					min-height: 49rem;
					padding: 1em 2em;
				}
			}

			@include media-breakpoint-up(xl) {
				.form-box {
					padding: 1em 3em;
				}

				.info-box {
					.pseudo-info-bloc {
						.pseudo-titre {
							line-height: 1;
							text-align: right;

							&:after {
								display: none;
							}

							span {
								display: table;
							}

							&.titre-1 {
								padding: 1.6em 0;
							}
							&.titre-2 {
								padding: 1em 0;
							}
						}

						.pseudo-info {
							padding-left: 4.7rem;

							&:before {
								content: '';
								display: block;
								position: absolute;
								z-index: 1;
								left: 18px;
								top: 18px;
								bottom: 0;
								border: 1px solid;
								border-width: 0 0 0 1px;
							}
						}
					}
				}
			}

			@include media-breakpoint-up(xxl) {
				.form-box {
					padding: 1em 10em;
				}

				.info-box {
					.pseudo-info-bloc {
						.pseudo-titre {
							&.titre-1 {
								padding: 1em 0 0 2em;
							}
							&.titre-2 {
								padding: 1em 0 0 2em;
							}
						}
					}
				}
			}
		}
	}
}
