.media-lot {
    .tile-inner {
        margin-bottom: 3.6vw;
    }
    .content {
        font-size: 2.4rem;
        line-height: 1;
    }
    .tile-inner {
        padding: 7.33333vw;
    }
    .text-wrapper {
        font-family: $theme-font-medium;
        margin-bottom: 7.33333vw;
        text-align: center;
        .amount {
            font-family: $theme-font-bold;
        }
        p {
            margin-top: 0.7rem;
            font-size: 1.8rem;
            line-height: 1;
        }
    }
    &.lot-1-by-1 {
        img {
            width: 100%;
        }
        .tile-sizer:after { padding-top: 90%; }
    }
    &.lot-1-by-2 {
        img {
            width: 100%;
        }
        .tile-sizer:after { padding-top: 90%; }
    }
    &.lot-1-by-3 {
        img {
            width: 100%;
        }
        .tile-sizer:after { padding-top: 90%; }
    }
}
@include media-breakpoint-up(lg) {
	.media-lot {
        .tile-inner {
            margin-bottom: 0.71428rem;
            padding: 0;
        }
		transition: opacity 1s;

		position: relative;
		&.lot-1-by-1 {
			img {
				bottom: 1rem;
				width: 30%;
			}

			.content {
				font-size: 2.4rem;
				line-height: 2.4rem;
			}

			.text-wrapper {
				top: 10%;

				p {
					margin-top: 0.7rem;
					font-size: 1.8rem;
					line-height: 1.8rem;
				}
			}

			.tile-sizer:after{padding-top: $wide-ratio;}
		}
		&.lot-1-by-2 {
			img {
				width: 65%;
				bottom: 10%;
			}

			.content {
				font-size: 3.2rem;
				line-height: 3.2rem;
			}

			.text-wrapper {
				top: 20%;

				p {
					margin-top: 0.7rem;
					font-size: 2.4rem;
					line-height: 2.4rem;
				}
			}

			.tile-sizer:after{padding-top: 100%;}
		}
		&.lot-1-by-3 {
			img {
				width: 75%;
				bottom: 15%;
			}

			.content {
				font-size: 4rem;
				line-height: 4rem;
			}

			.text-wrapper {
				top: 25%;

				p {
					margin-top: 0.7rem;
					font-size: 2.6rem;
					line-height: 2.6rem;
				}
			}

			.tile-sizer:after{padding-top: 150%;}
		}
		&:hover .img-logo {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			opacity: 1;
			background: transparent none no-repeat center center;
		}

		img {
			display: block;
			position: absolute;
			transform: translateX(-50%);
			left: 50%;
			z-index: 1;
		}

		.content {
			position: relative;
			bottom: 0;
		}

		.text-wrapper {
            margin-bottom: 0;
            padding: 7.33333vw;
			position: absolute;
			width: 100%;
			z-index: 1;
		}

	}
}

@include media-breakpoint-up(lg) {
    .media-lot {
        .text-wrapper {
            padding: 2rem;
        }
    }
}
@include media-breakpoint-up(xxl) {
	.media-lot {
		position: relative;
		&.lot-1-by-1 {
			img {
				bottom: 2rem;
				width: 30%;
			}

			.content {
				font-size: 4rem;
				line-height: 4rem;
			}

			.text-wrapper {
				top: 2.5rem;

				p {
					margin-top: 0.7rem;
					font-size: 2.6rem;
					line-height: 2.6rem;
				}
			}

			.tile-sizer:after {
				padding-top: $wide-ratio;
			}
		}
		&.lot-1-by-2 {
			img {
				bottom: 15%;
			}

			.content {
				font-size: 5.8rem;
				line-height: 6rem;
			}

			.text-wrapper {
				top: 15%;

				p {
					margin-top: 1.3rem;
					font-size: 3rem;
					line-height: 3rem;
				}
			}

			.tile-sizer:after {
				padding-top: 100%;
			}
		}

		&.lot-1-by-3 {
			img {
				width: auto;
				bottom: 15%;
			}

			.content {
				font-size: 5.5rem;
				line-height: 5.5rem;
			}

			.text-wrapper {
				top: 25%;

				p {
					margin-top: 0.7rem;
					font-size: 3.6rem;
					line-height: 3.2rem;
				}
			}

			.tile-sizer:after {
				padding-top: 150%;
			}
		}
	}
}
