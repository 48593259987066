body {
	&.theme-bg-poker {

		.for-carousel-standard .media-text {
			padding-left: 0;
			padding-right: 0;
		}

		.aDecouvrir {
			.carousel-promotions .owl-stage-outer .content.content-img.with-text {
				.btnWrap {
					.btn {
						font-family: $font-graphik-bold;
					}
				}
			}
		}

		.mainCarrouselAcueil {
			a.btn:hover {
				color: white !important;
			}
		}

		.carousel-standard.carousel.carousel.carousel-full-width {
			.owl-dots {
				.owl-dot span {
					border-color: #000;
				}

				.owl-dot.active span {
					background: #000;
				}
			}

			.desktop-content {
				.row .columns {
					.btn {
						font-family: $font-graphik-medium;
					}
				}
			}
			div.mobile-content {
				.media-component.media-text {
					.content-inner {
						div.image-title {
							margin: auto 4%;
						}
						.rich-text {
							margin: auto 4%;
							
							.btn {
								color: black;
								&:hover {
									color: white;
								}
							}
						}
					}
				}
			}
		}

		.explorer .carousel-promotions {
			.media-image .content-text {
				left: 5%;
				position: relative;
				top: calc(53% + 1.4vw);

				@include media-breakpoint-up(md) {
					top: 44%;
				}
			}

			.over-title-text {
				line-height: 1.1;
			}

			.btn {
				margin-left: 10%;

				@include media-breakpoint-up(md) {
					margin-left: 5%;
				}
			}
		}

		.aDecouvrir.jeux .carousel-promotions .owl-stage-outer div.tile-inner .content.content-text {
			@include media-breakpoint-down(sm) {
				top: calc(50% + 1.4vw) !important;
			}
		}
	}
}
