section .multi-formats {

        .remontee-loteries.media-component.media-image {
            margin: 0;
            transform: perspective(1px);
            sup {
                font-size: 65%;
                top: -1.7vw;
            }
            @include media-breakpoint-up(md) {
                sup {
                    font-size: 50%;
                    top: -0.7em;
                }
            }
            .tile {
                .tile-inner {
                    background-image: none;
                    background-color: $color-background-defaut;
                    text-transform: uppercase;

                    .media-component {
                        height: 100%;
                        .btn {
                            @include media-breakpoint-up(lg) {
                                padding: 1rem 3rem;
                            }
                            &:hover {
                                color: $color-bleu-elctr;
                            }
                        }
                        .tirage {
                            float: left;
                            position: relative;
                            text-align: center;
                            height: 100%;
                            width: 100%;
                            background-color: #ffffff;
                            .date {
                                font-family: latoregular, Helvetica, Arial, sans-serif;
                                line-height: 1.4vw;
                                color: #000000;
                                display: inline;
                                white-space: nowrap;
                                font-size: 3.0666666667vw;

                                @include media-breakpoint-up(md) {
                                    font-size: 1.98rem;
                                }
                                
                                @include media-breakpoint-up(lg) {
                                    font-size: 2.4rem;
                                    margin-left: 0.5rem;
                                }
                                
                                .strong {
                                    display: inline;
                                    color: $color-n1;
                                    font-family: $gros-lot-details-font-strong;
                                }
                                @include media-breakpoint-down(sm) {
                                    margin-bottom: 0.4vw;
                                    sup {
                                        top: -1.2vw;
                                    }
                                }
                                @include media-breakpoint-up(md) {
                                    float: none;
                                    display: inline-block;
                                    font-size: 1vw;
                                    line-height: 1.25vw;
                                    text-transform: uppercase;
                                    text-align: left;
                                    .strong {
                                        font-family: $gros-lot-details-font-strong;
                                    }
                                }
                                @include media-breakpoint-up(xxl) {
                                    font-size: 12px;
                                    line-height: 14px;
                                }
                            }
                            @include media-breakpoint-up(md) {
                                .loterie-container {
                                    position: relative;
                                    height: 100%;
                                }
                            }
                            &.prod212 {
								.groslot-info-wrap {
	                                @include media-breakpoint-up(md) {
	                                    height: 50%;
	                                }
								}
								.groslot-info{
									padding-bottom: 1rem;
									@include media-breakpoint-up(md) {
                                        top: 30% !important;
                                    }
									@include media-breakpoint-down(sm) {
										padding-bottom: 1.5rem;
									}
									.groslot-avenir{
										margin-top:10%;
									}
								}
                                .groslot-valeur {
                                    color: $color-649-montant;
									font: 1.2rem GothamMediumRegular, sans-serif;
									@include media-breakpoint-up(md) {
                                        padding-bottom: 1rem;
                                    }
									@include media-breakpoint-down(sm) {
                                        font-size: 2.1333333333vw;
                                    }
									.texte{
										@include media-breakpoint-up(md) {
                                        	padding-left: 1.8rem;
											font-size: 0.972rem;
                                        }
										@include media-breakpoint-up(lg) {
											padding-left: 1rem;
											font-size: 1.2rem;
                                        }
									}
									.valeur{
										@include media-breakpoint-up(md) {
											font-size: 1.701rem;
                                        }
										@include media-breakpoint-up(lg) {
											font-size: 2.1rem;
                                        }
										@include media-breakpoint-down(sm) {
											font-size: 3.1333333333vw;
										}
									}
                                }
                                .groslot-plus {
                                    color: $color-649-million-garanti;
                                }
								.groslot-boule-or{
									display: flex;
									flex-direction: row;
									justify-content: center;
									align-items: center;
									@include media-breakpoint-down(sm) {
										justify-content: left;
									}									
									div {
										img{
											@include media-breakpoint-up(md) {
                                            	max-width: 3.8rem;
												margin-right: 0.4rem;
                                        	}
											@include media-breakpoint-up(lg) {
                                            	max-width: 4.6rem;
												margin-right: 0.5rem;
                                        	}
											@include media-breakpoint-down(sm) {
												max-width: 10vw;
												margin-right: 0.5rem;
											}
										}
										font: 1.8rem GothamMediumRegular, sans-serif;
										color: #8b6b21;
										@include media-breakpoint-up(md) {
                                            font-size: 1.3rem;
                                        }
										@include media-breakpoint-up(lg) {
                                            font-size: 1.8rem;
                                        }
										@include media-breakpoint-down(sm) {
											font-size: 3.1333333333vw;
										}
										.valeur{
											font-size: 3.9rem;
											color: #b78d2d;
											@include media-breakpoint-up(md) {
                                            	font-size: 3.125vw;
                                        	}
											@include media-breakpoint-up(lg) {
                                    			font-size: 3.9rem;
                                			}
											@include media-breakpoint-down(sm) {
												font-size: 6.13333vw;
											}
											sup{
												font: 2.4rem latoregular, sans-serif;
												@include media-breakpoint-up(md) {
                                            		font-size: 1.944rem;
                                        		}
												@include media-breakpoint-down(sm) {
													font-size: 1.495rem;
												}
											}
										}
									}
								}
                            }
                            &.prod223 {
                                border-bottom: 2px dotted #cccccc;
                                @include media-breakpoint-up(md) {
                                    border: none;
                                }
                                .groslot-valeur {
                                    color: $color-lottomax-montant;
                                }
                                .groslot-plus {
                                    color: $color-lottomax-lots1million;
                                    text-align: center;
                                    img {
                                        display: inline-block;
                                        margin-top: -4px;
                                        width: 5vw;
                                        @include media-breakpoint-up(md) {
                                            width: 22px;
                                        }
                                    }
                                }
                            }
                            .groslot-logo {
                                padding-top: 1vw;
								display: flex;
								justify-content: center;
								align-items: center;
								@include media-breakpoint-down(sm) {
									display: block;
									position: absolute;
                                    left: 3.5vw;
                                    top: 50%;
                                    transform: translateY(-50%);
								}
                                @include media-breakpoint-up(md) {
                                    padding-top: 4vw;
                                }
                                @include media-breakpoint-up(lg) {
                                    padding-top: 3.3vw;
                                }
                                @include media-breakpoint-up(xxl) {
                                    padding-top: 44px;
                                }
                                .logo-loterie {
                                    width: auto;
                                    display: block;
                                    @include media-breakpoint-up(md) {
                                        float: none;
                                        display: inline-block;
                                        margin-top: 2.5%;
                                    }
                                    @include media-breakpoint-up(lg) {
                                        float: none;
                                        display: inline-block;
                                        margin-top: 0;
                                    }
                                    @include media-breakpoint-up(xxl) {
                                        padding-top: 0;
                                    }
                                    img {
                                        max-width: 100%;

                                        @include media-breakpoint-down(sm) {
                                            width: 28vw;
                                            position: relative;
                                        }
                                        @include media-breakpoint-up(md) {
                                            height: 7vw;
                                        }
                                        @include media-breakpoint-up(lg) {
                                            height: 6.2vw;
                                            width: auto;
                                        }
                                        @include media-breakpoint-up(xxl) {
                                            height: 85px;
                                        }
                                    }
                                }
                            }
                            .groslot-valeur {
                                font-family: $gros-lot-montant-font;
                                line-height: 6.1333333333vw;
                                margin-top: 0.1333333333vw;
                                white-space: nowrap;

                                @include media-breakpoint-down(sm) {
                                    font-size: 6.13333vw;
                                    margin-top: 1vw;
									margin-bottom: 1vw;
                                }
                                @include media-breakpoint-up(md) {
                                    font-size: 3.73333vw;
                                    line-height: 1.92rem;
                                    margin-top: 0.6rem;
                                    text-align: center;
                                }
                                @include media-breakpoint-up(lg) {
                                    font-size: 3.2vw;
                                    line-height: 3rem;
                                    margin-top: 1rem;
                                }
                                @include media-breakpoint-up(xxl) {
                                    font-size: 4rem;
                                }
                            }
                            .groslot-avenir {
                                display: none;
                                width: 60vw;
                                font-family: $gros-lot-montant-font;
                                color: #000000;
                                font-size: 4vw;
                                line-height: 4.9vw;
                                margin-left: 5vw;
                                padding-right: 4vw;
                                text-transform: none;
                                @include media-breakpoint-down(sm) {
                                    margin-left: auto;
                                }
                                @include media-breakpoint-up(md) {
                                    width: 100%;
                                    padding: 0 15px;
                                    margin: 0;
                                    text-align: center;
                                    font-size: 2vw;
                                    line-height: 2.5vw;
                                }
                                @include media-breakpoint-up(lg) {
                                    font-size: 1.8rem;
                                    line-height: 1.33;
                                    padding: 0 40px;
                                }
                            }
							.superTirage{
								margin-left:0.5rem;
								margin-bottom: 0.5rem;
								text-align: left;
							}
                            .img-superTirage {
                                @include media-breakpoint-down(sm) {
									max-width: 9.1rem;
//                                    top: 0vw;
                                }
                                @include media-breakpoint-up(md) {
									max-width: 8rem;
                                }
                                @include media-breakpoint-up(lg) {
                                    max-width: 9rem;
//                                    right: 0.7vw;
//                                    bottom: -4.2vw;
                                }
                                @include media-breakpoint-up(xxl) {
//                                    max-width: 60px;
//                                    max-height: 60px;
//                                    bottom: -58px;
//                                    right: 16px;
                                }
                            }
                            .groslot-plus {
                                font-family: latoblack, Helvetica, Arial, sans-serif;
                                white-space: nowrap;
                                font-size: 4.1333333333vw;
                                margin-top: 0.28rem;
                                line-height: 4.8vw;

                                @include media-breakpoint-up(md) {
                                    text-align: center;
                                    font-size: 1.86667vw;
                                    margin-top: 0.8vw;
                                    line-height: 2.16rem;
                                }
                                @include media-breakpoint-up(lg) {
                                    font-size: 1.73333vw;
                                    line-height: 2.6rem;
                                    margin-top: 0.35vw;
                                }
                                @include media-breakpoint-up(xxl) {
                                    font-size: 2rem;
                                    margin-top: 0.6rem;
                                }
                            }
                            .groslot-approx {
                                font-size: 1rem;
                                font-family: $gros-lot-details-font;
                                color: $color-n1; // padding: 5px 0;
                                text-align: center;
                                position: relative;

                                @include media-breakpoint-down(sm) {
                                    position: absolute;
                                    right: 4vw;
                                    bottom: 3vw;
                                }
                                @include media-breakpoint-up(md) {
                                    position: relative;
                                    font-size: 0.78rem;
                                }
                                @include media-breakpoint-up(lg) {
                                    margin-bottom: 12px;
                                    font-size: 0.9rem;
                                }
                            }
                            .groslot-info-wrap {
                                @include media-breakpoint-up(md) {
                                    height: 32%;
                                }
                                .groslot-info {
                                    // display: none;
                                    float: none;
                                    height: auto;
                                    text-align: left;
                                    overflow: visible;
                                    @include media-breakpoint-down(sm) {
                                        position: absolute;
                                        width: 60vw;
                                        left: 68%;
                                        top: 38%;
                                        transform: translateX(-50%) translateY(-50%);
                                        .groslot-valeur {
                                            text-align: left;
                                        }
                                    }
                                    @include media-breakpoint-up(md) {
                                        top: 50%;
                                        position: relative;
                                        transform: translateY(-50%);
                                    }
                                }
                            }
                            .groslot-boutons-jouer {
                                @include media-breakpoint-up(md) {
                                    text-align: center;
                                    bottom: 0.5vw;
                                    position: absolute;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    width: 100%;
                                }
                                @include media-breakpoint-up(lg) {
                                    bottom: 29px;
                                }
                                .btn {
                                    background-color: $color-bouton-groupe;
                                    border-color: $color-bouton-groupe;
                                    /*margin-top: 10px;*/
                                    margin: 6px;
                                    /* Ajout styles page d'Accueil */
                                    border-radius: 8px;
                                    &:hover {
                                        background-color: $color-blanc;
                                        color: $color-bouton-groupe;
                                    }
                                    &.acheter {
                                        /*margin-right: 20px;*/
                                        margin-top: 4vw;
                                        background-color: $color-rouge;
                                        border-color: $color-rouge;
                                        &:hover {
                                            background-color: $color-blanc;
                                            color: $color-rouge;
                                        }
                                    }
                                    @include media-breakpoint-down(sm) {
                                        &.resultats {
                                            display: none;
                                        }
                                        &.acheter {
                                            /*margin-right: 0;*/
                                            position: absolute;
                                            bottom:10%;
                                            z-index:9999;
                                            left: 50%;
                                            transform: translateX(-50%);
                                        }
                                    }
                                    @include media-breakpoint-up(md) {
                                        font-size: 1.33333vw;
                                        &.acheter {
                                            margin-top: 0;
                                        }
                                    }
                                    @include media-breakpoint-up(lg) {
                                        font-size: 1.06667vw;
                                        margin-top: 0.4vw;
                                        padding-left: 2.5rem;
                                        padding-right: 2.5rem;
                                    }
                                    @include media-breakpoint-up(xxl) {
                                        font-size: 1.4rem;
                                    }
                                }
                            }
                        }
                    }
                }
                .tile-sizer {
                    @include media-breakpoint-down(sm) {
                        &:after {
                            padding-top: 44%;
                        }
                    }
                }
            }
        }
    
}

.tile-static {
    .text-block {
        display: table;
        width: 100%;
        height: 100%;
        .text-block-content {
            display: table-cell;
            width: 100%;
            height: 100%;
            vertical-align: middle;
        }
    }
}

.magnolia-editor {
    section .multi-formats {
        .remontee-loteries {
            .tile-inner {
                .tirage,
                .resultats-bouton {
                    display: block;
                }
            }
        }
    }
}