
.carousel-promotions {
	.container-progressif {
		display: flex;
		justify-content: center;
		padding-left: 0;

		.jackpot-number,
		.currency {
			font-size: 11vw;
			margin-left: 0.8vw;
			
			@include media-breakpoint-up(md) {
				font-size: 3vw;
				margin-left: 0.3vw;
			}
			
			@include media-breakpoint-up(lg) {
				font-size: 3.8vw;
			}

			@include media-breakpoint-up(xl) {
				font-size: 4rem;
				margin-left: 3px;
			}
		}

		.currency {
			position: relative;
			top: 2px;
		}

		.jackpot-number:lang(fr):nth-last-of-type(3n+3) {
			margin-left: 1.4vw;

			@include media-breakpoint-up(md) {
				margin-left: 0.5vw;
			}

			@include media-breakpoint-up(xl) {
				margin-left: 8px;
			}
		}
	}

	.progressif {
		justify-content: center;
	}

	.container-progressif.txtBlack {
		.currency {
			color: #fff;
		}
	}

	.owl-stage-outer{
		div{
			&.tile-inner{
				.filiale{
					display: flex;
					align-items: center;
					justify-content: center;
					p{
						top:0; 
						transform: none;
						margin-bottom: 0;
					}
				}
				.bg-img{
					&.lazyloaded{
						margin-top: 0!important;
					}
				}
			}
		}
	}
	
	.media-component{
		.tile-inner{
			
			.content{
				&.content-text{	
					
					.under-title-text{
						font-family: latomedium,Arial,Helvetica,sans-serif;
						&:last-child(){
							font-family: 'graphik_medium', Arial, Helvetica, sans-serif;
							border-width: 2px; 
							border-style: solid; 
							border-radius: 8px; 
							transition: all 0.2s;
							bottom: 0!important;
							padding: 9px 16px;
							text-transform: uppercase;
							@include media-breakpoint-up(md){
								margin-bottom: 15px!important;
							}
							@include media-breakpoint-down(sm){
								position: absolute!important;
								display: inherit;
								font-size: 0.9rem!important;
								padding: 1.7vw 4vw 1.6vw;
								float: none!important;
							}
						}
					}
					.over-title-text{
						font-family: latomedium,Arial,Helvetica,sans-serif;
						font-size: 1.2rem;
						line-height: 1;
						@include media-breakpoint-down(sm) {
							font-size: 2.1rem;
						}
					}
					@include media-breakpoint-down(sm) {
						height: calc(50% - 1.4vw);
						left: 10%!important;
						position: absolute!important;
						right: 10%!important;
						width: auto!important;
					}
				}
			}
		}
	}
}

