html {
	overflow-x: hidden;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased;
	padding: 0;
}

body.theme-bg-portail {
	background: #095892;
	font-size: 10px;
	font-family: $theme-font;
	font-weight: normal;
	font-feature-settings: 'liga', 'kern';
	transition: padding-top 0.35s ease;

	&.show-lq-bar {
		padding-top: 4rem;
	}

	&.no-scroll {
		height: 100vh !important;
		overflow: hidden !important;
		width: 100vw !important;
	}
}

.visually-hidden {
	margin: -1px;
	padding: 0;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(0 0 0 0);
	clip: rect(0, 0, 0, 0);
	position: absolute;
}

.theme-bg-portail {
	main {
		text-align: center;
		margin: 0 auto;
	}

	li,
	a,
	div {
		color: $color-theme-text-default;
	}

	input,
	select {
		border: 0;
		color: $color-input-default;
	}

	h1,
	.h1 {
		font-family: $theme-font-black;
		font-size: 8.26667vw;
		line-height: 9.06667vw;
		font-weight: normal;
		color: $color-theme-text-default;
	}

	h2,
	.h2 {
		font-family: $theme-font-black;
		font-size: 6.93333vw;
		font-weight: normal;
		color: $color-theme-text-default;
		position: relative;
		display: inline-block;
		line-height: 1em;

		&:after {
			background-color: $color-theme-text-default;
			bottom: -3.2vw;
			content: '';
			height: 0.2rem;
			right: 0;
			left: 0;
			margin: auto;
			position: absolute;
			width: 10vw;
		}

		&.light {
			font-family: $theme-font-light;
		}
	}

	h3,
	.h3 {
		font-family: $theme-font-bold;
		font-size: 4.8vw;
		line-height: 6.4vw;
		font-weight: normal;
		color: $color-theme-text-default;
	}

	p {
		margin: 0;
		padding: 0;
		color: $color-theme-alt-text-alternative;
		font-size: 4.4vw;
		line-height: 1.25em;
		font-family: $theme-font-regular;
	}

	li {
		font-family: $theme-font-regular;
		font-size: 4.4vw;
		line-height: 9.33333vw;
		color: $color-theme-text-default;
	}

	button {
		outline: none;
		padding: 0;
	}

	header {
		position: relative;
		z-index: 1000;
	}

	.sticky-fixed {
		left: 0;
		margin-left: 0;
		margin-right: 0;
		position: fixed;
		top: 8rem;
		width: 100%;
		z-index: 900;
	}

	.sticky-bar {
		text-align: center;
	}

	.cke_panel_listItem,
	.cke_combo_button {
		// always display .show-for-sr in cke dropdown
		.show-for-sr {
			position: static !important;
			height: auto;
			width: auto;
			overflow: visible;
			clip: auto;
		}
	}

	/* max width des composantes */

	.main > div.content-container .media-component,
	section .media-component {
		&.media-video,
		&.media-image {
			max-width: none;
		}
	}

	.max-width {
		max-width: $component-max-width;
		margin-left: auto;
		margin-right: auto;
	}

	.full-width {
		max-width: none !important;
	}

	/* Au besoin, gérer cas par cas le max width des contenus des composantes pleine largeur */

	.media-text-with-bg-image .max-width {
		display: table;
		height: 100%;
		width: 100%;

		> .row {
			display: table-cell;
			vertical-align: middle;
		}
	}

	@include media-breakpoint-up(lg) {
		// add max-width padding for root components
		.main > .row.content-container.max-width,
		.main > .row.content-container > .columns > .media-component.media-image-with-text-beside,
		.main > .row.content-container.offers-component,
		.tabs-container .media-tabs,
		section > .row.max-width,
		section > .row > .columns > .media-component.media-image-with-text-beside {
			width: calc(100% - #{$component-max-width-padding}) !important;
		}
	}

	/* Screen reader-specific classes for desktop only (visible on mobile) */

	.desktop-for-sr {
		position: static !important;
		height: auto;
		width: auto;
		overflow: visible;
		clip: auto;
	}

	@include media-breakpoint-up(md) {
		h1,
		.h1 {
			font-size: 5.64rem;
			line-height: 5.64rem;
		}

		h2,
		.h2 {
			font-size: 3.12rem;

			&:after {
				bottom: -1.44rem;
				height: 0.2rem;
				width: 11.34rem;
			}
		}

		h3,
		.h3 {
			font-size: 2.16rem;
			line-height: 2.88rem;
		}

		p {
			font-size: 1.68rem;

		}

		li {
			font-size: 1.98rem;
			line-height: 4.2rem;
		}
	}

	@include media-breakpoint-up(lg) {
		/* Screen reader-specific classes for desktop only (visible on mobile) */
		.desktop-for-sr {
			clip: rect(1px, 1px, 1px, 1px);
			height: 1px;
			overflow: hidden;
			position: absolute !important;
			width: 1px;
		}

		h1,
		.h1 {
			font-size: 9.4rem;
			line-height: 9.4rem;
		}

		h2,
		.h2 {
			font-size: 4rem;

			&:after {
				bottom: -1rem;
				height: 0.2rem;
				width: 8rem;
			}
		}

		h3,
		.h3 {
			font-size: 1.8rem;
			line-height: 2.4rem;
		}

		p {
			font-size: 1.4rem;

		}

		li {
			font-size: 1.4rem;
			line-height: 2.1rem;
		}

		#portalBar {
			height: 4rem;
			padding: 0 1rem;
			margin-top: -4rem;

			button {
				margin-left: -2.5rem;
				width: 2.5rem;
				text-indent: -10rem;
			}
		}

		#portalBar {
			display: block;
		}
	}

	@include media-breakpoint-up(lg) {
		section > .row.max-width,
		section > .row.max-width.content-container.social-grid {
			width: calc(100% - #{$component-max-width-padding}) !important;

			@media only screen and (max-width: 1330px) {
				width: calc(100%) !important;
			}
		}
	}

	.section-page {
		> h2 {
			padding-top: 2rem;

			@include media-breakpoint-down(sm) {
				margin-bottom: 6.6666666667vw;
			}
		}
	}

	.area-galerie {
		margin-bottom: 0;
	}

	section .media-component.media-image {
		position: relative;
	}
}

main.theme-bg-portail {
	fill: #fff;
}

main.theme-bg-casino {
    background-color: #091A38;
    color: #fff;
    fill: #fff;
}