body {
	&.theme-bg-casino {
		.carousel-standard.carousel.carousel.carousel-full-width {
			.owl-dots {
				span {
					border-color: #9a9a9a;
				}
				.owl-dot.active {
					span {
						background: #9a9a9a;
					}
				}
			}

			.desktop-content {
				.row .columns {
					.btn {
						&:hover {
							background-color: transparent;
							border-color: #fff;
							color: #fff;
						}
					}
				}
				h3 {
					@include media-breakpoint-only(md) {
						margin-top: 1rem;
					}
				}
			}
			.theme-bg-blanc .desktop-content .row .columns {
				.btn:hover {
					border-color: black;
				}
			}
			.theme-bg-noir .desktop-content .row .columns {
				.btn:hover {
					border-color: white;
					color: white;
				}
			}
			div.mobile-content {
				.media-component.media-text {
					.content-inner {
						background-color: #091a38;

						div.image-title {
							margin: auto 4%;
							h2 {
								.under-title-text {
									font-size: 3.5vw;
									line-height: 4.6vw;
								}
							}
						}
						.rich-text {
							max-width: 50%;
							margin: auto 4%;
						}
					}
				}
			}
		}

		.mainCarrouselAcueil {
			@include media-breakpoint-up(md) {
				margin-bottom: 2rem;
			}
		}
	}
}
