.multi-formats {
	&.vertical-align {
		> .columns {
			vertical-align: middle;
		}
	}
}

.multi-formats .media-text:first-child {
	.content-inner {
		width: 100%;
	}

	.text-sizer {
		height: 0px;
		&.squared-text {
			//padding-top: 100%;
			height: auto;
		}
	}
}

.multi-formats {
	.media-component .content {
		overflow: visible;
	}
	.columns {
		.media-text:first-child {
			.content {
				.content-inner .squared-text-content {
					top: 50%;
					transform: translate(0, -50%);
				}
			}
		}
	}
}

.multi-formats {
	.media-image {
		.content-img {
			.content-text {
				div {
					text-align: inherit;
				}
			}
		}
	}
}

.multi-formats .content-inner {
	&.squared-text-content {
		position: absolute;
	}
	width: 100%;
}

.theme-bg-portail {
	.multi-formats {
		padding: 0;

		@include media-breakpoint-up(lg) {
			padding-top: 2rem;
		}

		@include media-breakpoint-up(lg) {
			max-width: 1100px;
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}
}
