body.theme-bg-sports {
	background-color: $color-n1;
	color: #fff;

	main.theme-bg-sports .btn,
	main.theme-bg-sports section.transparent .btn,
	main.theme-bg-sports section-page.transparent .btn {
		background-color: transparent;
		font-family: $theme-font-sport;
		color: $color-w1;
		border-color: $color-r1;
		text-decoration: none;
		text-transform: none;
		padding: 16px;

		&:hover {
			background-color: $color-r1;
			border-color: $color-r1;
			color: $color-w1;
		}

		&.opaque {
			background-color: $color-r1;
			border-color: $color-r1;
			color: $color-w1;

			&:hover {
				background-color: transparent;
				color: $color-w1;
				border-color: $color-r1;
			}
		}
	}

	footer {
		background-color: $color-n1;
	}
}

main.theme-bg-portail .carousel-promotions .media-image .content-text div.over-title-text + .under-title-text {
	font-family: 'graphik_medium', Arial, Helvetica, sans-serif;
	/*$theme-font-medium;*/
	border-width: 2px;
	border-style: solid;
	border-radius: 8px;
	transition: all 0.2s;
	display: inline-block;
}

main.theme-bg-portail p, main.theme-bg-portail section.transparent p, main.theme-bg-portail section-page.transparent p{
	color: #fff;
}

@media (max-width: 767.98px) {
	main.theme-bg-portail .carousel-promotions .media-image .content-text div.over-title-text + .under-title-text {
		padding: 1.6vw 4vw;
	}
}
@media (min-width: 768px) {
	main.theme-bg-portail .carousel-promotions .media-image .content-text div.over-title-text + .under-title-text {
		padding: 1.33333vw 2.93333vw;
	}
}
@media (min-width: 992px) {
	main.theme-bg-portail .carousel-promotions .media-image .content-text div.over-title-text + .under-title-text {
		padding: 9px 16px;
	}
}
@media (min-width: 1440px) {
	main.theme-bg-portail .carousel-promotions .media-image .content-text div.over-title-text + .under-title-text {
		font-size: 14px;
		padding: 12px 22px;
	}
}

main.theme-bg-portail
	.carousel-promotions
	.media-image
	.content-text.text-color16
	div.over-title-text
	+ .under-title-text {
	color: #000000;
	border-color: #ffffff;
	background-color: #ffffff;
	display: inline-block;
}
main.theme-bg-portail
	.carousel-promotions
	.media-image
	.content-text.text-color16
	div.over-title-text
	+ .under-title-text:hover {
	background-color: transparent;
	color: #ffffff;
}

main.theme-bg-ced,
main.theme-bg-portail {

	.btn {
		text-transform: uppercase;
	}
}
