// debut commun

.icon-list {

  ul{
    margin-top: 5.3333333333vw;

    @include media-breakpoint-up(md) {
      margin-top: 2.4rem;
    }
    
    @include media-breakpoint-up(lg) {
      margin-top: 3rem;
    }

    li:not(.columns) { // when not vertically stacked
      display: inline-block;
      width: 26.6666666667vw;
      width: 100%;
      vertical-align: top;

      @include media-breakpoint-up(md) {
        width: 12rem;
      }
      
    }
  }

  .media-icon:not(.media-icon-left) {
    padding: 0 5%;
    margin-top: 0vw;

    @include media-breakpoint-up(md) {
      margin-top: 1.2rem;
    }
    
    @include media-breakpoint-up(lg) {
      margin-top: 2rem;
    }
  }

  > h3, > .h3, &.row.collapse > h3.columns, &.row.collapse > .h3.columns {
    padding-left: 2.6666666667vw;
    padding-right: 5.3333333333vw;

    @include media-breakpoint-up(md) {
      padding-left: 1.2rem;
      padding-right: 1.2rem;
    }
    
    @include media-breakpoint-up(lg) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  > h3.light, > .h3.light {
    font-family: $theme-font-light;
  }

}

// fin commun

.icon-list {
  ul{
    li:not(.columns) { // when not vertically stacked
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 9rem;
      }
      
      @include media-breakpoint-up(lg) {
        width: 12rem;
      }
    }
  }
}