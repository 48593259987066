

/***************************************************************
**
**   COLOR SWATCHES (FROM STYLE GUIDE)
**
***************************************************************/


// alphas
$color-b1a: rgba(0,178,255, 0.85);
$color-b2a: rgba(0,36,255, 0.85);
$color-b3a: rgba(0,29,207, 0.85);
$color-b4a: rgba(0,0,171, 0.85);
$color-b5a: rgba(0,0,87, 0.85);
$color-n1a: rgba(0, 0, 0, 0.85);
$color-w1a: rgba(255, 255, 255, 0.85);
$color-g1a: rgba(244, 244, 255, 0.85);
$color-g2a: rgba(28, 28, 28, 0.85);
$color-boverlay: rgba(0, 84, 255, 0.25);
$color-bleu-pale-a: rgba(9, 88, 146, 0.85);
$color-bleu-fonce-a: rgba(0, 64, 125, 0.85);

// pour couleurs derrière texte
$bg-color1: $color-bleu-pale;
$bg-color2: $color-bleu-fonce;

$bg-color1a: $color-bleu-pale-a;
$bg-color2a: $color-bleu-fonce-a;

$bg-color1-title: $color-w1;
$bg-color2-title: $color-w1;

$bg-color1-subtitles: $color-w1;
$bg-color2-subtitles: $color-w1;

$list-bg-colors: $bg-color1, $bg-color2;

$list-bg-colors-alpha: $bg-color1a, $bg-color2a;

$list-bg-colors-title: $bg-color1-title, $bg-color2-title;

$list-bg-colors-subtitles: $bg-color1-subtitles, $bg-color2-subtitles;

/***************************************************************
**
**   SECTION COLORS (SHOULD NEVER BE REFERENCED DIRECTLY, USE 'section-colors' MIXIN INSTEAD)
**
***************************************************************/

// foregrounds (text, border, etc.)
$color-foreground1: $color-blanc;
$color-foreground2: $color-noir;
$color-foreground6: $color-r1;  //rouge

// backgrounds
$color-background1: $color-bleu-pale;
$color-background2: $color-bleu-fonce;
$color-background6: $blanc-casse-poker;


$color-background-catfish: #e2edf3;

// INITIALIZE SECTION LISTS USED IN 'section-colors' MIXIN
$list-color-section-fg: $color-foreground1, $color-foreground2, $color-foreground6;

$list-color-section-bg: $color-background1, $color-background2, $color-background6;

$color-background-defaut: transparent;

/***************************************************************
**
**   THEME COLORS COULEURS DE FOND (SHOULD NEVER BE REFERENCED DIRECTLY, USE 'theme-colors' MIXIN INSTEAD)
**
***************************************************************/

// NOTE: tous les thèmes ont BG transparent.

// theme1: Blanc
$color-theme1-classname: "blanc";
$color-theme1-fg: $color-n1;
$color-theme1-fg-secondary: $color-w1;
$color-theme1-bg: $color-w1; /*$color-background-defaut;*/
$color-theme1-bg-secondary: $color-bleu-elctr;
$color-theme1-text: $color-n1;
$color-theme1-link: $color-bleu-fonce;
$color-theme1-link-hover: $color-bleu-elctr;
$color-theme1-font: $theme-font-regular;
$color-theme1-font-light: $theme-font-light;
$color-theme1-font-medium: $theme-font-medium;
$color-theme1-font-bold: $theme-font-bold;
$color-theme1-font-black: $theme-font-black;


// theme3: Portail
$color-theme3-classname: "portail";
$color-theme3-fg: $color-w1;
$color-theme3-fg-secondary: $color-w1;
$color-theme3-bg: $color-background-defaut;
$color-theme3-bg-secondary: $color-bleu-elctr;
$color-theme3-text: $color-w1;
$color-theme3-link: $color-w1;
$color-theme3-link-hover: $color-w1;
$color-theme3-font: $theme-font-regular;
$color-theme3-font-light: $theme-font-light;
$color-theme3-font-medium: $theme-font-medium;
$color-theme3-font-bold: $theme-font-bold;
$color-theme3-font-black: $theme-font-black;

// theme4: Noir
$color-theme4-classname: "noir";
$color-theme4-fg: $color-w1;
$color-theme4-fg-secondary: $color-w1;
$color-theme4-bg: $color-n1; /*$color-background-defaut;*/
$color-theme4-bg-secondary: $color-bleu-elctr;
$color-theme4-text: $color-w1;
$color-theme4-link: $color-w1;
$color-theme4-link-hover: $color-w1;
$color-theme4-font: $theme-font-regular;
$color-theme4-font-light: $theme-font-light;
$color-theme4-font-medium: $theme-font-medium;
$color-theme4-font-bold: $theme-font-bold;
$color-theme4-font-black: $theme-font-black;

// theme5: Sport
$color-theme5-classname: "sports";
$color-theme5-fg: $color-w1;
$color-theme5-fg-secondary: $color-w1;
$color-theme5-bg: $color-n1; /*$color-background-defaut;*/
$color-theme5-bg-secondary: $color-bleu-elctr;
$color-theme5-text: $color-w1;
$color-theme5-link: $color-w1;
$color-theme5-link-hover: $color-bleu-pale;
$color-theme5-font: $theme-font-sport;
$color-theme5-font-light: $theme-font-sport;
$color-theme5-font-medium: $theme-font-sport;
$color-theme5-font-bold: $theme-font-sport-bold;
$color-theme5-font-black: $theme-font-sport-black;


// theme6: Poker
$color-theme6-classname: "poker";
$color-theme6-fg: $color-n1;
$color-theme6-fg-secondary: $color-w1;
$color-theme6-bg: $blanc-casse-poker; /*$color-background-defaut;*/
$color-theme6-bg-secondary: $blanc-casse-poker;
$color-theme6-text: $color-n1;
$color-theme6-link: $color-n1;
$color-theme6-link-hover: $color-n1;
$color-theme6-font: 'graphik_regular', Arial, Helvetica, sans-serif;
$color-theme6-font-light: 'graphik_light', Arial, Helvetica, sans-serif;
$color-theme6-font-medium: 'graphik_medium', Arial, Helvetica, sans-serif;
$color-theme6-font-bold: 'graphik_bold', Arial, Helvetica, sans-serif;
$color-theme6-font-black: 'graphik_black', Arial, Helvetica, sans-serif;

// theme7: CASINO
$color-theme7-classname: "casino";
$color-theme7-fg: $color-w1;
$color-theme7-fg-secondary: $color-w1;
$color-theme7-bg: $bleu-marin-casino;
$color-theme7-bg-secondary: $color-n1;
$color-theme7-text: $color-w1;
$color-theme7-link: $color-n1;
$color-theme7-link-hover: $color-n1;
$color-theme7-font: 'graphik_regular', Arial, Helvetica, sans-serif;
$color-theme7-font-light: 'graphik_light', Arial, Helvetica, sans-serif;
$color-theme7-font-medium: 'graphik_medium', Arial, Helvetica, sans-serif;
$color-theme7-font-bold: 'graphik_bold', Arial, Helvetica, sans-serif;
$color-theme7-font-black: 'graphik_black', Arial, Helvetica, sans-serif;

// theme8: CASINO EN DIRECT
$color-theme8-classname: "ced";
$color-theme8-fg: $color-w1;
$color-theme8-fg-secondary: $color-w1;
$color-theme8-bg: $bleu-marin-casino;
$color-theme8-bg-secondary: $color-n1;
$color-theme8-text: $color-w1;
$color-theme8-link: $color-n1;
$color-theme8-link-hover: $color-n1;
$color-theme8-font: 'graphik_regular', Arial, Helvetica, sans-serif;
$color-theme8-font-light: 'graphik_light', Arial, Helvetica, sans-serif;
$color-theme8-font-medium: 'graphik_medium', Arial, Helvetica, sans-serif;
$color-theme8-font-bold: 'graphik_bold', Arial, Helvetica, sans-serif;
$color-theme8-font-black: 'graphik_black', Arial, Helvetica, sans-serif;

// INITIALIZE THEME LISTS USED IN MIXIN
$list-color-theme-fg: $color-theme1-fg, $color-theme3-fg,  $color-theme4-fg, $color-theme5-fg, $color-theme6-fg, $color-theme7-fg, $color-theme8-fg;

$list-color-theme-fg-secondary: $color-theme1-fg-secondary, $color-theme3-fg-secondary, $color-theme4-fg-secondary, $color-theme5-fg-secondary, $color-theme6-fg-secondary, $color-theme7-fg-secondary, $color-theme8-fg-secondary;

$list-color-theme-bg: $color-theme1-bg, $color-theme3-bg, $color-theme4-bg, $color-theme5-bg, $color-theme6-bg, $color-theme7-bg, $color-theme8-bg;

$list-color-theme-bg-secondary: $color-theme1-bg-secondary, $color-theme3-bg-secondary, $color-theme4-bg-secondary, $color-theme5-bg-secondary, $color-theme6-bg-secondary, $color-theme7-bg-secondary, $color-theme8-bg-secondary;

$list-theme-classname: $color-theme1-classname,  $color-theme3-classname, $color-theme4-classname, $color-theme5-classname, $color-theme6-classname, $color-theme7-classname, $color-theme8-classname;

$list-color-theme-text: $color-theme1-text, $color-theme3-text, $color-theme4-text, $color-theme5-text, $color-theme6-text, $color-theme7-text, $color-theme8-text;

$list-color-theme-link: $color-theme1-link, $color-theme3-link,  $color-theme4-link, $color-theme5-link, $color-theme6-link, $color-theme7-link, $color-theme8-link;

$list-color-theme-link-hover: $color-theme1-link-hover, $color-theme3-link-hover, $color-theme4-link-hover, $color-theme5-link-hover, $color-theme6-link-hover, $color-theme7-link-hover, $color-theme8-link-hover;

$list-color-theme-font: $color-theme1-font, $color-theme3-font, $color-theme4-font, $color-theme5-font, $color-theme6-font, $color-theme7-font, $color-theme8-font;

$list-color-theme-font-light: $color-theme1-font-light, $color-theme3-font-light, $color-theme4-font-light, $color-theme5-font-light, $color-theme6-font-light, $color-theme7-font-light, $color-theme8-font-light;

$list-color-theme-font-medium: $color-theme1-font-medium, $color-theme3-font-medium, $color-theme3-font-medium, $color-theme4-font-medium, $color-theme5-font-medium, $color-theme6-font-medium, $color-theme7-font-medium, $color-theme8-font-medium;

$list-color-theme-font-bold: $color-theme1-font-bold, $color-theme3-font-bold, $color-theme4-font-bold, $color-theme5-font-bold, $color-theme6-font-bold, $color-theme7-font-bold, $color-theme8-font-bold;

$list-color-theme-font-black: $color-theme1-font-black, $color-theme3-font-black, $color-theme4-font-black, $color-theme5-font-black, $color-theme6-font-black, $color-theme7-font-black, $color-theme8-font-black;