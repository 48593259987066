body {
	&.theme-bg-poker {
		main.theme-bg-poker {
			.tile .tile-inner .bg-img {
				margin: 0;
				width: 100%;
				//height: calc(100% - 20px);
				height: 100%;
			}

			.format43.tile {
				@include media-breakpoint-down(lg) {
					margin-bottom: 2rem;
				}

				&[data-width='4'][data-height='3'] {
					.tile-sizer:after {
						padding-top: 56%;
					}
				}
				.tile-static {
					&.ta-center {
						.content {
							.tile-inner {
								.tile-text-block {
									text-align: center;
								}
							}
						}
					}

					.content {
						.tile-inner {
							.tile-text-block {
								padding-top: 1.5vw;
								@include media-breakpoint-up(xl) {
									padding-top: 15px;
								}

								.btn-wrap {
									position: absolute;
									width: 100%;
									bottom: 0;
									left: 0;

									span.btn {
										position: relative;
										font-family: $font-graphik-bold;
										margin-top: 0;
										background: white;
										padding: 2.4vw 8.4vw;
										font-size: 2vw;

										&:hover {
											color: black;
										}
										.btexte {
											position: relative;
											z-index: 1;
										}
										.bgwa {
											position: absolute;
											width: 100%;
											height: 100%;
											top: 0;
											left: 0;
											background: white;
											border-radius: 6px;
											z-index: 0;
										}
										
									}
								}

								h3 {
									font-family: 'graphik_bold', Arial, Helvetica, sans-serif;
									font-size: 4vw;
									line-height: 1.2;
									padding-left: 10px;
									padding-right: 10px;
								}
								.under-title-text {
									font-size: 3vw;
									font-family: $font-graphik-medium;
									padding-left: 10px;
									padding-right: 10px;
								}

								@include media-breakpoint-up(md) {
									h3 {
										font-size: 4vw;
									}
									.under-title-text {
										font-size: 3vw;
									}
								}

								@include media-breakpoint-up(lg) {
									.btn-wrap {
										span.btn {
											padding: 1.2vw 4vw;
											font-size: 1vw;
										}
									}
									h3 {
										font-size: 2vw;
									}
									.under-title-text {
										font-size: 1.5vw;
									}
								}

								@include media-breakpoint-up(xl) {
									.btn-wrap {

										span.btn {
											padding: 1.2vw 4vw;
											font-size: 1vw;
										}
									}
									h3 {
										font-size: 2vw;
									}
									.under-title-text {
										font-size: 1.5vw;
									}
								}
							}
						}
					}
				}
			}
		}

		.tile-static {
			.tile-inner {
				> .tile-text-block {
					width: 100%;
					max-width: none;
					top: 50%;
					height: 100%;
					height: 50%;
		
					@include media-breakpoint-up(xl) {
						margin-left: 6px;
					}
		
					// @include media-breakpoint-up(xxl) {
					// 	width: calc(100% - 20px);
					// }
				}
			}

			.container-progressif {
				padding-left: 10px;
				z-index: 2;

				@include media-breakpoint-down(lg) {
					bottom: 0;
					top: auto !important; // to override the value in the dialog
				}
				
				@include media-breakpoint-down(xs) {
					top: 80%;
				}

				.jackpot-number {
					background: none;
					font-family: graphik_black,Arial,Helvetica,sans-serif;
					line-height: 2;
				}
			}
			@include media-breakpoint-down(xs) {
				.content {
					height: 340px;
				}
			}
		}
	}
}
