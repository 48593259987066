// debut commun
section.cedMas {
  .splitGauche {
    font-weight: 500;
    font-size: 12px;
  }
  a.buttonJouer:hover {
    background-color: #006648;
    color: white;
  }
}
section,
.section-page {
    position: relative;

  @include media-breakpoint-up(md) {
    padding-bottom: 7.2rem;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 5rem;
  }

  &.bg-img {
    background-size: cover;
  }

  &.media-bg-with-content {
    padding-bottom: 0;
  }

  &.no-top-margin {
    margin-top: 0;
  }

  > h2,
  > .h2 {
    + * {
      padding-top: 10.6666666667vw;
      margin-top: 0;

      @include media-breakpoint-up(md) {
        padding-top: 5rem;
      }
    }

    + .note {
      padding-top: 0;
      margin-top: 1.25em;
      font-size: 4.4vw;
      line-height: 1.25em;

      @include media-breakpoint-up(md) {
        font-size: 1.4rem;
      }
    }
  }

  > .note {
    color: $color-theme-text-alternative;
    margin: 1.25em auto;
  }

  > .note-bas-section p {
    color: $color-theme-text-alternative;

    font-size: 4vw;

    @include media-breakpoint-up(md) {
      font-size: 1.3rem;
    }
  }

  > .btn,
  > .note-bas-section {
    margin-top: 16vw;
    margin-bottom: 0vw;

    @include media-breakpoint-up(md) {
      margin-top: 7.2rem;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 5rem;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  > .btn + .note-bas-section {
    margin-top: 8vw;

    @include media-breakpoint-up(md) {
      margin-top: 3.6rem;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 2.5rem;
    }
  }

  > h2,
  > .note {
    padding-left: 2.6666666667vw;
    padding-right: 2.6666666667vw;

    @include media-breakpoint-up(md) {
      padding-left: 2.4rem;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 2rem;
    }

    @include media-breakpoint-up(md) {
      padding-right: 2.4rem;
    }

    @include media-breakpoint-up(lg) {
      padding-right: 2rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  section,
  .section-page {
    > .note {
      width: 50%;
    }
  }
}

// fin commun
section,
.section-page {
  text-align: center;
  > h2,
  > .h2 {
    padding: 0;
    text-transform: none;
    font-size: 4.6666666667vw;
    display: block;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(md) {
      font-size: 2.16rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 2.5rem;
    }

    &:not(span) {
      &.ta-left {
        width: calc(100% - 15px);

        @include media-breakpoint-down(sm) {
          padding-left: 2.66667vw;
          width: auto;
        }

        @include media-breakpoint-only(md) {
          padding-left: 2.66667vw;
          width: auto;
        }
      }
    }

    &.ta-right:first-child {
      margin-top: 8vw;

      @include media-breakpoint-up(md) {
        margin-top: 3.6rem;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 6.5rem;
      }

      @include media-breakpoint-down(sm) {
        margin-right: 0;
        padding-right: 2.66667vw;
      }

      @include media-breakpoint-only(md) {
        margin-right: 0;
        padding-right: 2.66667vw;
      }
    }

    span.ta-left {
      display: inline-block !important;
    }

    + * {
      padding-top: 0;
    }

    + .note {
      margin-top: 10.6666666667vw;
      font-size: 3.7333333333vw;

      @include media-breakpoint-up(md) {
        font-size: 1.4rem;
        margin-top: 4rem;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 4.8rem;
      }
    }
  }

  > .note {
    font-size: 3.7333333333vw;

    @include media-breakpoint-up(md) {
      font-size: 1.4rem;
    }

    p {
      font-size: 3.7333333333vw;

      @include media-breakpoint-up(md) {
        font-size: 1.4rem;
      }
    }
  }

  > .btn,
  > .note-bas-section {
    margin-top: 1.3333333333vw;

    @include media-breakpoint-up(md) {
      margin-top: 0.6rem;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  > .note-bas-section {
    p {
      font-size: 3.4666666667vw;

      @include media-breakpoint-up(md) {
        font-size: 1.32rem;
      }

      @include media-breakpoint-up(lg) {
        font-size: 1.2rem;
      }

      a {
        margin-top: 1.3333333333vw;

        @include media-breakpoint-up(md) {
          margin-top: 0.6rem;
        }

        @include media-breakpoint-up(lg) {
          margin-top: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: 0;

    main.theme-bg-sports & {
      padding-bottom: 0;
    }

    &:last-child {
      main.theme-bg-sports & {
        padding-left: 0;
      }

      @media (min-width: 360px) {
        .content-text {
          position: relative;
          padding-bottom: 0;
          padding-top: 0;
          width: 100%;
        }
      }
      @media (min-width: 767px) {
        .content-text {
          top: 50%;
          position: absolute;

          transform: translateY(-50%);

          padding-bottom: 0;
          padding-top: 0;
          padding-left: 33vw;
          width: 100%;

          .under-title-text {
            font-size: 3.8vw;
            margin-left: 3vw;
          }

          .over-title-text {
            margin-left: 3vw;
          }

          main.theme-bg-sports & {
            padding-left: 0;
            top: 0;

            transform: translateY(0);

            .over-title-text {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding-bottom: 0;
  }
}

// @include page-theme-colors {

// 	> h2,
//     > .h2 {

//     	span {
//             font-family: $color-theme-font-bold;
//         }

//         &.light,
//         span.light {
//             font-family: $color-theme-font-light;
//         }

//     }

//     @include media-breakpoint-down(sm) {

//     	&:last-child {
//     		.content-text {
//     			.under-title-text {
//                     font-family:$color-theme-font;

//                 }
//     		}
//     	}
//     }
// }

.section-page {
  overflow-x: hidden;
  overflow-y: auto;
}

.bouton-mas {
  display: flex;
  justify-content: flex-end;
  padding: 28px 31px 38px;
  background-color: #01815b;
  > a {
    padding: 11px 30px;
    border-radius: 25px;
    font-size: 18px;
    background: white;
    color: black;
    font-family: graphik_medium, Arial, Helvetica, sans-serif;
  }
}
.section-page.MoJAcceuilSectionBas {
  border-bottom: 5px solid #fff;
}

.section-page.sectionRouge {
  color: #e41f1f;
}
