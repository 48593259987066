
.media-bg-with-content,
.media-bg-with-content-mobile {
	@include media-breakpoint-up(lg) {
		margin-bottom: 2rem;
	}

	.media-text {
		.image-title h2 {
			color: #fff;
		}
		
		.h3 {
			color: #fff;
		}

		.under-title-text {
			color: #fff;
		}
	}
	
	.container-progressif .jackpot-number {
		background: none;
		font-family: graphik_black,Arial,Helvetica,sans-serif;
		line-height: 2;
	}
}

.media-bg-with-content-mobile {
	background-color: #000;

	.media-text {
		padding: 0;

		.content {
			height: 100%;

			.squared-text-content {
				padding: 0 7vw 20vw;

				.image-title {
					img {
						float: right;
						width: 11vw;
						margin-top: -2vw;
					}
				}
			}

			.container-progressif {
				position: absolute;

				.jackpot-number,
				.currency {
					font-size: 7vw;
				}
			}
		}
	}
}