body {
	&.theme-bg-poker {
		.btn[data-target='#lightbox-telecharger']:hover {
			color: black;
		}

		#lightbox-telecharger {
			.modal-content {
				padding: 20px 20px 0 20px;

				img {
					width: 50%;
				}
			}

			.modal-dialog {
				background-color: #f0f0f0;
				border-radius: 8px;
				border-style: initial;
				max-width: 1100px;
			}

			.modal-header {
				border: 1px;
				border-color: #000;
				border-style: none none solid;
				margin-bottom: 40px;
				padding: 0 0 30px;

				button {
					display: none;
				}

				.modal-title {
					color: #000;
					font-family: 'graphik_medium';
					font-size: 3rem;
				}
			}

			.modal-body {
				padding: 0;

				p {
					font-size: 1.6rem;
					line-height: 1.4;
				}
			}

			.modal-footer {
				
				.btn {
					border-color: #000;
					border-radius: 4px;
					font-size: 1.6rem;
					margin-bottom: 2rem;

					&:hover {
						background: transparent;
						color: black;
					}
				}
			}
		}
	}
}
