// Couleurs

$ej-color-gray:   #8B8B8B;
$ej-color-gray-light:   #ccc;
$ej-grey-dark  : #545c5f;
$ej-color-yellow: #f6c812;
$ej-color-blue: #073159;
$ej-color-blue-light:#00407d;
$ej-color-turquoise: #97d0c4;
$ej-color-white: #fff;
$ej-color-black: #000;
$ej-error  : #ff0000;
$ej-color-grey : #424242;

$color-blanc: #FFFFFF;
$color-noir: #000000;
$color-bleu-pied: #00407D;

//couleur pied + photo tags
$color-bleu-pale: #095892;

//couleur du carousel gagnants
$color-bleu-fonce: $color-bleu-pied;
$color-bleu-menu: $color-bleu-pied;
$color-vert: #007A87;

//pour boutons lotomax/649
$color-rouge: #C80432;

$color-rouge-pseudo: #D9082F;
$color-vert-pseudo: #32BC11;


$rouge-poker : #FF2A00;

//pour boutons lotomax/649
$color-bleu-pied-border: rgba(51, 109, 166, 1);
$color-bleu-elctr: #0061D4;

// Couleurs generales
$color-b1: #00b2ff;
$color-b2: #0024ff;
$color-b3: #001dcf;
$color-b4: #0000ab;
$color-b5: #000057;

$color-n1: #000000;
$color-w1: #FFFFFF;
$color-g1: #F4F4FF;
$color-g2: #1C1C1C;

$color-r1: #D90B0B;
$color-gris: #919191;

$color-note: #b3cde1;
$color-bleu-marin: #073159;

$blanc-casse-poker : #F6F6F6;

$bleu-marin-casino : #091A38;

$font-graphik-medium: graphik_medium, Arial, Helvetica, sans-serif;
$font-graphik-regular: graphik_regular, Arial, Helvetica, sans-serif;
$font-graphik-thin: graphik_thin, Arial, Helvetica, sans-serif;
$font-graphik-black: graphik_black, Arial, Helvetica, sans-serif;
$font-graphik-bold: graphik_bold, Arial, Helvetica, sans-serif;
$font-graphik-semibold: graphik_semibold, Arial, Helvetica, sans-serif;

$lato-font-light: latolight, sans-serif;

$lato-font-regular: latoregular, Arial, Helvetica, sans-serif;

$lato-font-medium: latomedium, Arial, Helvetica, sans-serif;

$lato-font-bold: latoblack, Arial, Helvetica, sans-serif;

$lato-font: $lato-font-regular;

$theme-font-light: latolight, Arial, Helvetica, sans-serif;


$theme-font-regular: latoregular, Arial, Helvetica, sans-serif;

$theme-font-medium: latomedium, Arial, Helvetica, sans-serif;

$theme-font-bold: latobold, Arial, Helvetica, sans-serif;

$theme-font-black: latoblack, Arial, Helvetica, sans-serif;

$theme-font-heavy: latoheavy, Arial, Helvetica, sans-serif;

$theme-font: $theme-font-regular;

$theme-font-sport: "Montserrat Regular", Arial, Helvetica, sans-serif;

$theme-font-sport-bold: "Montserrat Bold", Arial, Helvetica, sans-serif;

$theme-font-sport-black: "Montserrat Black", Arial, Helvetica, sans-serif;

$oswald-font-light: oswaldlight, sans-serif;

$oswald-font-regular: oswaldregular, Arial, Helvetica, sans-serif;

$oswald-font-medium: oswaldmedium, Arial, Helvetica, sans-serif;

$oswald-font-bold: oswaldbold, Arial, Helvetica, sans-serif;

$oswald-font: $theme-font-regular;


//FOOTER
$footer-border-color: #333333 !default;
$m-footer-active-button: #1b1b1b !default;
$m-footer-link-list-bg: #111111 !default;
$m-footer-link-list-bg-sports: #111111 !default;
$footer-bg-color: #000 !default;
$footer-border-color-sport: rgb(28, 28, 28) !default;
$footer-bg-color-sports: #1b1b1b !default;