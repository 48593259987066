
// Overwrite foundation rem-base
$tablet-ratio: 0.6;
$rem-base: 10px;

// footer

//== Vendor prefix variables
$vendor-prefixes: -webkit- -o- -ms- -moz-;
$vendor-webkit-moz: -webkit- -moz-;
$vendor-no-ms: -webkit- -o- -moz-;


$component-max-width: 1280px;
$component-max-width-padding: 50px;

$wide-ratio: (100% * 9 / 16);
$scare-ratio: 100%;

