body {
	&.theme-bg-casino {
		.media-bg-with-content {
			.heading-content {
				width: 100%;

				.row {
					.columns {
						&.right {
							.media-text {
								right: 0;
								width: 100%;
							}
						}

						.media-text {
							width: 100%;
							height: auto;
						}
					}
				}
			}
		}
	}
}
