.grid {
    .top-container {
        padding: 6vw 0;
    }
    .title-wrapper {
        margin-bottom: 2.4vw;
        text-align: center;
        position: relative;

        h2 {
            font-family: $theme-font-light;
            line-height: 1;
            position: relative;

            span {
                display: block;
            }
            .bold {
                font-family: $theme-font-black;
            }
            &:after {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}

.subcontainer {
    transition-property: width, height;
    transition-duration: 0.3s;
}

.column,
.columns {
    padding-left: 0;
    padding-right: 0;
}


@include media-breakpoint-up(md) {
    .grid {
        .top-container {
            padding: 4.5rem 0;
        }

        .title-wrapper {
            margin-bottom: 1.8rem;
        }
    }
}

.grid {

    >.row {
        padding-left: 0;
        padding-right: 0;
    }
}

.theme-bg-sports {
    .grid {
        .container-fluid {
            max-width: 1280px;
        }

        .container-absolute {
            padding-left: 0;
            padding-right: 0;
        }
    }
}