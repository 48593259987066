// debut commun

.tabs-container{
  overflow: hidden;
}

.tabs-container.row.content-container {
  background-repeat: repeat;
  background-size: auto;
}

.media-tabs {
    text-align: left;
    position: relative;

    &.sticky-fixed {
        position:fixed;
        margin-left: 0;
        top: 13.6vw;
        left: 0;
        right: 0;
    }
    .link-lists {
        z-index: 2;
        width: 100%;
        height: 0;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        margin-left: 0;
		transition: max-height opacity 3s;
		
		@include media-breakpoint-up(md) {
			display: flex;
		}
    }
    .link-lists > a {
        display: block;
        font-family: $theme-font-regular;
        font-size: 1.3rem;
        line-height: 1.75;
        position: relative;
        padding: 0.66667vw 0;
        margin: 0 3.6vw 2vw;
		color: $color-theme-text-default;
		
        &:after {
          content: "";
          position: relative;
          bottom: -0.53333vw;
          left: 0;
          width: 0;
          height: 0.2rem;
          background-color: $color-theme-text-default;
          transition: width 0.1s, background-color 0.2s;
		}
		
        &.selected:after, &:hover:after {
            width: 100%;
            transition: width 0.3s, background-color 0.2s;
        }
    }

    button.mobile-tab {
        display: block;
        width: 100%;
        text-align: left;

        font-size: 5.33333vw;
        padding: 0.4em;

        background-color: #444;
        border: 0;
        position: relative;
        min-height: 1.92em;

        &:before {
            content: attr(data-caption);
        }

        &:after {
            content: "";
            position: absolute;
            right: 1.5em;
            top: 50%;
            width: 4.66667vw;
            height: 4.66667vw;
            margin-top: -2.93333vw;
            border-bottom: 0.8vw solid #fff;
            border-right: 0.8vw solid #fff;
            transform: rotate(45deg);
            transition: transform 0.3s, top 0.3s;
        }
    }
}

.show-mobile-tabs {
    .media-tabs {
        .link-lists {
            position: absolute;
            top: 100%;
            background-color: #1b1b1b;
            padding: 1em;
            height: auto;
            max-height: 100vh;
            opacity: 1;
            overflow: visible;
        }
        button.mobile-tab {
            &:after {
                transform: rotate(225deg);
                top: 65%;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .media-tabs {
        .link-lists {
            height: auto;
            max-height: none;
            opacity: 1;
            margin-top: 5rem;
            margin-left: 1.5rem;
            &.ta-center {
                margin-left:0;
            }
            padding-bottom: 10rem;
        }
        .link-lists > a {
            display: inline-block;
            line-height: 1;
            height: auto;
            padding: 0.66667vw 0;
            margin: 0 2vw 2vw;
            overflow: visible;
        }

        button.mobile-tab {
            display: none;
        }
    }
}
@include media-breakpoint-up(lg) {

    .media-tabs {
        margin-left: -1.5rem;
        .link-lists {
            margin-left: 3rem;
            &.ta-center {
                margin-left:0;
            }
        }
        .link-lists > a {
            font-size: 1.8rem;
            margin: 0 1.5rem;
			padding: 0.5rem 0;
			
            &:after {
                bottom: -0.8rem;
            }
        }
    }
}
.tabs-content-wrapper {
    .temp-spacer {
        min-height: 40vh;
    }
}

// fin commun

.media-tabs {
  .link-lists {
    > a {
      font-size: 2.16rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .media-tabs {
    .link-lists {
      margin-top: 7.2rem;
      margin-left: 0;
      padding-bottom: 7.2rem;
      > a {
        font-size: 1.68rem;
      }
      &.ta-center {
        margin-left:0;
      }
    }
  }

  h2 + div .media-tabs .link-lists {
    margin-top: 3.6rem;
  }

}
@include media-breakpoint-up(lg) {

  .media-tabs {
    margin-left: 0;
    .link-lists {
      margin-top: 5rem;
      margin-left: 0;
      padding-bottom: 5rem;
      > a {
        font-size: 1.8rem;
      }
      &.ta-center {
        margin-left:0;
      }
    }
  }

  h2 + div .media-tabs .link-lists {
    margin-top: 2.5rem;
  }

}