body {
	&.theme-bg-poker {
		.grid.styleAccueil {
			.tile-static {

				.under-title-text {
					font-size: 1.4rem;
				}
			}
		}

		.grid .tile .tile-static .tile-text-block {
			color: #fff;
		}
	}
}
