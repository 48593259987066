body.theme-bg-poker {
    .mobile-menu-bar {
        background-color: #F6F6F6;
    }

    .menu {
        background-color: #F6F6F6;

        .logo {
            margin-left: 1rem;
            margin-right: 6rem;
            position: relative;

            @include media-breakpoint-up(lg) {
                margin-left: 0;
                top: -1.7rem;
                width: 10rem;
            }
            
            img {
                height: auto;
                width: 100%;
            }
        }

        .menu-item {
            background-color: #F6F6F6;
        }

        .menu-item__btn {
            border-color: #000;
            color: #000;

            &::after {
                border-color: #000;
            }
        }

        .menu-item.active {
            .menu-item__btn {
                border-color: black;
            }	
        }

        .menu-item:hover {
            .menu-item__btn {
                border-color: black;
            }
        }

        .enteteSectionComponent #listLiens li a span.lienText {
            color: #000;
        }

        .submenu-wrapper {
            background-color: #F6F6F6;
            border-color: #000;

            a {
                color: #000;

                &:hover {
                    color: rgba(255, 255, 255, 1) !important;
                    background-color: rgba(0, 0, 0, 1) !important;
                }

                &::after {
                    background-color: #000;
                }
            }

            li.selected {
                a {
                    background-color: #000;
                    color: #fff;
                }
            }
        }

        .menu-container { 
            background-color: #F6F6F6;
            color: #fff;
        }
    }
}