body:not(.magnolia-editor) {
    main:not(.theme-bg-sports) {
        .catfish-mobile,
        .catfish-tablet,
        .catfish-desktop {
            background-color: $color-background-catfish;
            position: fixed;
            bottom: 0;
            z-index: 3000;
            width: 100%;
            display: none;
        }

        .catfish-close {
            width: 13px;
            height: 13px;

            span {
                position: absolute;
                text-indent: -9000px;
            }

            img {
                position: absolute;
                width: 13px;
                height: 13px;
                top: 50%;
                transform: translateY(-50%);
                margin-left: -21px;

                .catfish-mobile & {
                    width: 23px;
                    height: 23px;
                    top: 6%;
                    transform: none;
                    margin-left: 12px;
                }
            }
        }

        .catfish-mobile {
            .catfish-close {
                width: 44px;
                height: 44px;
                position: absolute;
                display: block;

                img {
                    height: 15px;
				    margin-left: 0px;
				    top: 6%;
				    left: 6%;
				    transform: none;
				    width: 15px;
				    position: absolute;
                }
            }
        }
        .catfish-tablet {
            .catfish-close {
                width: 44px;
                height: 44px;
                display: inline-block;
            	transform: translateY(-50%);
                margin-left: -41px;
            	top: 50%;
                position: absolute;

                img {

	                width: 13px;
	                height: 13px;
	                margin-left: 0;
				    left: 40%;
				    position: absolute;
				    top: 50%;
				    transform: translateY(-50%);
                }
            }
        }
    }
}
