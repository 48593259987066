body {
	&.theme-bg-casino {
		.media-text .rich-text .btn {
			font-family: $lato-font-bold;
			padding: 3% 14%;
			margin-top: 10px;

			@include media-breakpoint-up(lg) {
				padding: 15px 80px;
			}
		}

		.media-text .rich-text .btn {
			border-radius: 60px;
			font-family: inherit;

			// @include media-breakpoint-up(md) {
			// 	padding: 0.71428571rem 2.85714286rem;
			// }
		}

		.image-title {
			h2 {
				font-size: 3.39vw;
				line-height: 1;
				margin-bottom: 0;
			}
		}

		.media-bg-with-content {
			.media-text {
				h3,
				.h3 {
					font-family: $font-graphik-regular;
					font-size: 2rem;
					line-height: 2.5rem;
				}
			}
			@include media-breakpoint-only(md) {
				.media-text {
					.image-title {
						h2,
						.h2 {
							margin-top: 0;
						}

						h3,
						.h3 {
							font-size: 1.56rem;
							line-height: 2rem;
						}
					}
					.rich-text {
						margin-top: 1rem;
					}
				}
			}
		}
		.bloc-image-texte {
			.media-text {
				h3,
				.h3 {
					font-family: $font-graphik-regular;
					font-size: 2rem;
					line-height: 1.3;
				}
			}
			@include media-breakpoint-only(md) {
				.media-text {
					.image-title {
						h2,
						.h2 {
							font-size: 4.5vw;
							line-height: 4.5vw;
							margin-top: 0;
						}
						+ .h3 {
							font-size: 1.85vw;
							line-height: 2.2vw;
						}
						h2 + h3,
						h2 + .rich-text {
							margin-top: 1rem;
						}
						h3,
						.h3 {
							font-size: 1.1rem;
							line-height: 1.4rem;
						}
					}
					.rich-text {
						margin-top: 1rem;
					}
				}
			}
		}

		@include media-breakpoint-down(sm) {
			.media-bg-with-content {
				.media-text {
					.image-title {
						h2,
						.h2 {
							font-size: 7vw;
							line-height: 7vw;
							margin-top: 0;
						}

						+ .rich-text {
							margin-top: 0;
						}
					}
				}
			}
		}

		.deux-textes {
			.media-text {
				// .image-title {
				// 	h2 {
				// 		font-size: 6.6vw;
				// 		line-height: 7vw;
				// 	}
					
				// }

				h3 {
					@include media-breakpoint-down(sm) {
						display: none;
					}
				}
			}
		}
	}
}

.carousel-promotions {
	&[data-responsive-items-small='1'] {
		&[data-responsive-items-medium='4'] {
			&[data-responsive-items-large='4'] {
				&[data-responsive-items-xlarge='4'] {
					@include media-breakpoint-down(sm) {
						.media-image .content-text div.over-title-text + .under-title-text {
							font-family: graphik_regular;
							padding: 3vw 12vw;
							bottom: 10%;
							font-size: 3.6vw;
							left: 5vw;
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-down(sm) {
	body {
		&.theme-bg-casino {
			.media-text .rich-text {
				margin-top: 0;
				.btn {
					padding: 3% 14%;
				}
			}

			.bloc-image-texte {
				.media-text {
					.image-title {
						h2,
						.h2 {
							font-size: 6vw;
							line-height: 6vw;
							margin-top: 3vw;
						}
						h3 {
							display: none;
						}
					}
				}
			}
		}
	}
}
