body {
	&.theme-bg-poker {
		section {
			&.section-page {
				& > h2 {
					text-align: center;

					@include media-breakpoint-up(md) {
						padding-left: 10%;
						text-align: left;
					}

					span.titleContent {
						background: #f6f6f6;
						display: inline-block;
						line-height: 1.16;
						padding-left: 3rem;
						padding-right: 3rem;
						position: relative;
						z-indeX: 1;
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
