/* CSS pour pallier au problème d’interférences entre une app VueJS et le plugin jquery Lazyload : cacher les sections avec backgound images lazyloaded jusqu’à ce que l’app Vue soit activé.  */
.vueAppRoot {
	.lazyload.bg-img {
		display: none;
	}
}
body {
	&.theme-bg-ced {
		section.section-page {
			.row {

				&.espaceSection {
					margin-bottom: 10px;
				}

				@include media-breakpoint-up(md) {
					&.lobby3.grid-5 .col {
						margin-bottom: 0;

						// Enlève les marges sur tablette
						@include media-breakpoint-down(lg) {
							&.col-12.col-sm-5.col-md-5.col-lg-5 {
								padding-right: 0;
							}

							&.col.col-12.col-sm-7.col-md-7.col-lg-7 {
								padding-left: 0;
								padding-right: 0.4%;
							}
						}
					}
				}
			}

			.row.min {
				max-width: 1280px;
			}

			// Couleurs par thèmes (Vlada)
			// http://intranetvpcap.loto-quebec.com/_APPROBATION_WEB/produits-projets-2021/02-2021-offshore-guide-styles-graphiques/start.html#id=tgle30&p=6_0_couleurs

			// blackjack, roulette, baccarat, poker, JeuxEnDirect, Des
			&.section-verte,&.section-bleu,&.section-bleu-marin,&.section-rouge,&.section-bleu-ciel,&.section-violet {
				& > h2,
				& > h3 {
					color: white;				// Couleurs des titres de section
				}
			}
			// Black jack
			&.section-verte {
				.table:not(.isTablePremiereOuverte)[data-tableid] {
					border: 2px solid #0F6D56;	// Couleurs des titres et des lins sur le fond blanc, contour et bouton éteint
				}
			}
			// Roulette
			&.section-bleu {
				.table:not(.isTablePremiereOuverte)[data-tableid] {
					border: 2px solid #194EA1;	// Couleurs des titres et des lins sur le fond blanc, contour et bouton éteint
				}
			}
			// JeuxEnDirect
			&.section-bleu-ciel {
				.table:not(.isTablePremiereOuverte)[data-tableid] {
					border: 2px solid #194EA1;	// Couleurs des titres et des lins sur le fond blanc, contour et bouton éteint
				}
			}
			// Baccara
			&.section-rouge {
				.table:not(.isTablePremiereOuverte)[data-tableid] {
					border: 2px solid #bc122e;  // Couleurs des titres et des lins sur le fond blanc, contour et bouton éteint
				}
			}
			// Poker
			&.section-bleu-marin {
				.table:not(.isTablePremiereOuverte)[data-tableid] {
					border: 2px solid #3135A6;	// Couleurs des titres et des lins sur le fond blanc, contour et bouton éteint
				}
			}
			// Dés
			&.section-violet {
				.table:not(.isTablePremiereOuverte)[data-tableid] {
					border: 2px solid #A51C5D;	// Couleurs des titres et des lins sur le fond blanc, contour et bouton éteint
				}
			}
			&.section-sansTheme {
				& > h2,
				& > h3 {
					letter-spacing: 0;
				}
			}

			& > h2,
			& > h3 {
				letter-spacing: 4px;
				margin-top: 0;
				margin-bottom: 0;
				text-transform: uppercase;
				font-size: 30px;
				font-family: $font-graphik-semibold;

				span {
					text-transform: uppercase;
					font-size: 30px;
					font-family: $font-graphik-thin;
					display: inline;
					margin-left: 8px;
				}

				font-size: 4.5vw;
				padding-top: 4.3vw;
				padding-bottom: 3.5vw;
				span {
					font-size: 4.5vw;
				}

				@include media-breakpoint-up(md) {
					font-size: 2.5vw;
					padding-top: 2.3vw;
					padding-bottom: 1.5vw;
					span {
						font-size: 2.5vw;
					}
				}

				@include media-breakpoint-up(xl) {
					padding-top: 35px;
					padding-bottom: 25px;
					font-size: 32px;
					span {
						font-size: 32px;
					}
				}
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	.main.theme-bg-ced {
		section.section-page > h2,
		section.section-page > h3 {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}

body.EN {
	&.theme-bg-ced {
		section.section-page {
			& > h2,
			& > h3 {
				font-family: $font-graphik-semibold;
				color: white;
				span {
					font-family: $font-graphik-semibold;
				}
			}

			// Couleurs par thèmes (Vlada)
			// http://intranetvpcap.loto-quebec.com/_APPROBATION_WEB/produits-projets-2021/02-2021-offshore-guide-styles-graphiques/start.html#id=tgle30&p=6_0_couleurs

			&.section-verte, &.section-bleu, &.section-bleu-marin, &.section-rouge, &.section-bleu-ciel, &.section-violet{
				& > h2,
				& > h3 {
					span {
						color: white;		// Couleurs des titres de sections
					}
				}
			}
		}
	}
}


body.EN {
	&.theme-bg-ced {
		section.section-sansTheme.section-page {
			& > h2,
			& > h3 {
				font-family: $font-graphik-semibold;
				color: white;
				span {
					font-family: $font-graphik-semibold;
				}
			}
		}
	}
}
