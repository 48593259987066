.multi-formats {
    .lots-progressifs {
        .lot-progressif {
            &.media-component.media-image {
                margin: 0;

                .tile-sizer:after {
                    padding-top: 49%;
                }

                &.image-scale {
                    .bg-img {
                        opacity: 0.2;
                    }

                    &:hover .bg-img {
                        opacity: 1;

                        transform: scale(1.05);

                        transform: rotationZ(0.01);
                    }
                }

                .content-img {
                    height: 43vw;
                    width: 50vw;
                    float: left;
                }

                .tile-inner {
                    background: transparent;

                    .bg-img {
                        background-position: top;
                    }

                    .btn {
                        cursor: pointer;
                        z-index: 10;
                        position: absolute;
                        font-size: 3.46667vw;
                        padding: 3.06667vw 5.2vw 3.33333vw;
                        margin-bottom: 0;
                        bottom: -5px;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: $color-rouge;
                        border-color: $color-rouge;

                        &:hover {
                            background-color: transparent;
                            color: $color-blanc;
                            border-color: $color-blanc;
                        }
                    }

                    .content-text {
                        width: 100%;
                        height: 100%;
                        left: 0;
                        margin-right: 0;
                        top: 0;
                        padding: 10px 0;

                        .date-heure {
                            position: absolute;
                            width: 100%;
                            font-size: 1.6vw;
                            text-transform: uppercase;
                            margin-top: -14px;
                            text-align: center;
                            transform: translateX(-50%);
                            left: 50%;
                        }
                    }

                    .lot-valeur-num {
                        display: inline;
                        font: 4vw / 33px $theme-font-bold;
                        zoom: 1;
                        background-image: url("../img/portail/bg-lots-progressifs.svg");
                        background-repeat: no-repeat;
                        padding: 5px;
                        background-position-y: center;
                    }

                    .lot-valeur-num-espace {
                        display: inline;
                        font-size: 8vw;
                    }

                    .dollarsign {
                        display: inline;
                        font-size: 4vw;
                        position: relative;
                        vertical-align: text-top;
                        top: 3px;
                        right: 5px;

                        &.fr {
                            right: 0;
                        }
                    }

                    .amount,
                    .error {
                        line-height: 1em;
                        font-family: $theme-font-bold;
                        font-size: 3.6vw;
                        position: absolute;
                        bottom: 50%;
                        transform: translate(-50%, 81%);
                        text-align: center;
                        left: 50%;
                    }

                    .error {
                        width: 100%;
                        font-family: $theme-font-black;
                        font-size: 4vw;
                        line-height: 4.9vw;
                        padding: 0 2.7vw;
                    }

                    .approx {
                        display: block;
                        font-size: 0.9vw;
                        text-align: right;
                        margin-top: -3px;

                        &.fr {
                            position: relative;
                            margin-left: -20px;
                        }
                    }

                    .subtitle {
                        font-family: latobold, Arial, Helvetica, sans-serif;
                        font-size: 6.4vw;
                        margin-top: 0.3em;

                        @include media-breakpoint-up(md) {
                            font-size: 2.1rem;
                        }
                        
                        @include media-breakpoint-up(lg) {
                            font-size: 2rem;
                        }
                    }

                    .text {
                        font-family: $theme-font-regular;

                        font-size: 4.8vw;

                        margin-top: 1em;

                        @include media-breakpoint-up(md) {
                            font-size: 1.6rem;
                        }
                    }

                    img {
                        margin-top: 1.7vw;
                        width: auto;
                        height: 37%;
                    }
                }
            }

            .content-img.with-text {
                .bg-img:after {
                    display: none;
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
.multi-formats {
        .lots-progressifs {
            .lot-progressif {
                &.media-component.media-image {
                    margin-bottom: 2%;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .content-img {
                        height: 100%;
                        width: 100%;
                        float: none;
                    }

                    .tile-inner {
                        .btn {
                            font-size: 1.33333vw;

                            // padding: 8px 15px 12px;
                            padding: 0.6rem 1.5rem 0.7rem;
                            bottom: -6px;

                            @include media-breakpoint-up(lg) {
                                font-size: 1.06667vw;
                                margin-top: 0.4vw;
                                padding-left: 2.5rem;
                                padding-right: 2.5rem;
                            }

                            @include media-breakpoint-up(xxl) {
                                font-size: 1.4rem;
                            }
                        }

                        .content-text {
                            .date-heure {
                                text-align: left;
                                font-size: 7px;
                                margin-left: 12px;
                                margin-top: -13px;

                                @include media-breakpoint-up(lg) {
                                    font-size: 8px;
                                }
                            }

                            .lot-valeur-num {
                                font: 21px / 48px $theme-font-bold;
                                padding: 6px;
                                margin-left: -1px;

                                @include media-breakpoint-up(lg) {
                                    font: 22px / 48px $theme-font-bold;
                                    padding: 7px;
                                }

                                @include media-breakpoint-up(xxl) {
                                    font: 31px / 48px $theme-font-bold;
                                    padding: 9px;
                                    margin-left: -2px;
                                }
                            }

                            .lot-valeur-num-espace {
                                font-size: 36px;

                                @include media-breakpoint-up(lg) {
                                    font-size: 48px;
                                }
                            }

                            .dollarsign {
                                font-size: 18px;
                                top: 9px;

                                @include media-breakpoint-up(lg) {
                                    font-size: 26px;
                                    top: 8px;

                                    &.fr {
                                        top: 5px;
                                        font-size: 20px;
                                    }
                                }

                                &.fr {
                                    top: 5px;
                                    right: 0;
                                }
                            }

                            .amount,
                            .error {
                                font-size: 2rem;
                                position: absolute;
                                text-align: center;
                                width: 100%;
                                transform: translate(-50%, 73%);

                                @include media-breakpoint-up(xxl) {
                                    transform: translate(-50%, 83%);
                                }
                            }

                            .error {
                                transform: translate(-50%, 68%);
                                font-size: 2vw;
                                line-height: 2.5vw;
                                padding: 0 16px;

                                @include media-breakpoint-up(lg) {
                                    font-size: 1.7rem;
                                    line-height: 1;
                                }
                            }

                            .approx {
                                display: inline;
                                font-size: 8px;
                                margin-left: 5px;

                                &.fr {
                                    bottom: -8px;
                                    padding-left: 9px;
                                    margin-left: -20px;

                                    @include media-breakpoint-up(lg) {
                                        bottom: -9px;
                                    }
                                }
                            }

                            img {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
   }
}

.media-component {
	&.media-text {
		width: 100%;
	}
	.media-progressif {
	    .content-text {
	            width: 100%;
	            height: 100%;
	            left: 0;
	            margin-right: 0;
	            top: 0;
	     }
	     .lot-valeur-num {
	            display: inline;
	            font: 3.5vw $theme-font-bold;
	            zoom: 1;
	            background-image: url("../img/portail/bg-lots-progressifs.svg");
	            background-repeat: no-repeat;
	            background-position-y: center;
	      }
	      .lot-valeur-num-espace {
	            display: inline;
	            font-size: 8vw;
	      }
	      .dollarsign {
	            display: inline;
	            font-size: 3vw;
	            position: relative;
	            vertical-align: text-top;
	
	            &.fr {
	                right: 0;
	            }
	       }
	
	       .amount,
	       .error {
	            line-height: 1em;
	            font-family: $theme-font-bold;
	            font-size: 2vw;
	            position: absolute;
	            text-align: center;
	            padding-top: 1em
	       }
	
	       .error {
	            width: 100%;
	            font-family: $theme-font-black;
	            font-size: 3vw;
	            line-height: 4.9vw;
	            padding: 0 2.7vw;
	       }
	
	       .approx {
	            display: block;
	            font-size: 0.9vw;
	            text-align: right;
	            margin-top: -3px;
	
	            &.fr {
	                position: relative;
	                margin-left: -20px;
	            }
	        }
	
	        .subtitle {
                font-family: latobold, Arial, Helvetica, sans-serif;
                font-size: 6.4vw;
                margin-top: 0.3em;

                @include media-breakpoint-up(md) {
                    font-size: 2.1rem;
                }
                
                @include media-breakpoint-up(lg) {
                    font-size: 2rem;
                }
            }

            .text {
                font-family: $theme-font-regular;

                font-size: 4.8vw;

                margin-top: 1em;

                @include media-breakpoint-up(md) {
                    font-size: 1.6rem;
                }
            }
	
	        img {
	            margin-top: 1.7vw;
	            width: auto;
	            height: 37%;
	        }
	}
}


@include media-breakpoint-up(md) {
.media-component {
	&.media-text {
		width: 100%;
	}
	.media-progressif {
	    .content-text {
	            width: 100%;
	            height: 100%;
	            left: 0;
	            margin-right: 0;
	            top: 0;
	            /*padding: 10px 0;*/
	     }
	     .lot-valeur-num {
				font: 21px / 48px $theme-font-bold;
                padding: 6px;
                margin-left: -1px;

                @include media-breakpoint-up(lg) {
                    font: 22px / 48px $theme-font-bold;
                    padding: 7px;
                }

                @include media-breakpoint-up(xxl) {
                    font: 31px / 48px $theme-font-bold;
                    padding: 9px;
                    margin-left: -2px;
                }
	      }
	      .lot-valeur-num-espace {
	            font-size: 36px;
                @include media-breakpoint-up(lg) {
                     font-size: 48px;
                }
	      }
	      .dollarsign {
	            font-size: 18px;
                top: 9px;

                @include media-breakpoint-up(lg) {
                    font-size: 26px;
                    top: 8px;

                    &.fr {
                        top: 5px;
                        font-size: 20px;
                    }
                }

                &.fr {
                    top: 5px;
                    right: 0;
                }
	       }
	
	       .amount,
	       .error {
                font-size: 2rem;
                position: absolute;
                text-align: center;
                width: 100%;
                /*transform: translate(-50%, 73%);*/

                @include media-breakpoint-up(xxl) {
                    /*transform: translate(-50%, 83%);*/
                padding-top: 1em
                }
	       }
	
	       .error {
                /*transform: translate(-50%, 68%);*/
                font-size: 2vw;
                line-height: 2.5vw;
                padding: 0 1px;

                @include media-breakpoint-up(lg) {
                    font-size: 1.2rem;
                    line-height: 1.3rem;
                }
	       }
	
	       .approx {
	            display: inline;
                font-size: 8px;
                margin-left: 5px;

                &.fr {
                    bottom: -8px;
                    padding-left: 9px;
                    margin-left: -20px;

                    @include media-breakpoint-up(lg) {
                        bottom: -9px;
                    }
                }
	        }
	
	        .subtitle {	            
	        }
	
	        .text {
	        }
	
	        img {
	           margin-top: 0;
	        }
	}
  }
}
