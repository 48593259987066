.social-share {

  position: relative;

  &-tooltip {
    $share-tooltip-bg: #181818;

    transition: all 0.1s;
    transform: scale(0.75, 0.75);
    transform-origin: 50% 150%;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: 2;
    bottom: 12.2666666667vw;
    width: 40vw;
    margin-left: -20vw;
    left: -9.3333333333vw;
    padding-left: 5.3333333333vw;
    padding-right: 5.3333333333vw;
    padding-top: 4vw;
    padding-bottom: 2.6666666667vw;
    line-height: 1.2em;
    font-size: 2.4vw;
    text-align: center;
    background-color: #181818;

        
    @include media-breakpoint-up(md) {
        bottom: 5.4rem;
    }

    @include media-breakpoint-up(lg) {
        bottom: 4.6rem;
    }

    @include media-breakpoint-up(md) {
        width: 15rem;
    }

    @include media-breakpoint-up(lg) {
        width: 15rem;
    }

    @include media-breakpoint-up(md) {
        margin-left: -7.5rem;
    }

    @include media-breakpoint-up(lg) {
        margin-left: -7.5rem;
    }

    @include media-breakpoint-up(md) {
        left: 2.7rem;
    }

    @include media-breakpoint-up(lg) {
        left: 2.5rem;
    }

    @include media-breakpoint-up(md) {
        padding-left: 1.8rem;
    }

    @include media-breakpoint-up(lg) {
        padding-left: 2rem;
    }

    @include media-breakpoint-up(md) {
        padding-right: 1.8rem;
    }

    @include media-breakpoint-up(lg) {
        padding-right: 2rem;
    }

    @include media-breakpoint-up(md) {
        padding-top: 1.5rem;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 1.5rem;
    }

    @include media-breakpoint-up(md) {
        padding-bottom: 0.9rem;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 1rem;
    }

    @include media-breakpoint-up(md) {
        font-size: 1.08rem;
    }

    @include media-breakpoint-up(lg) {
        font-size: 1.2rem;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      border: solid transparent;
      border-width: 2.6666666667vw;
      bottom: -5.3333333333vw;
      right: 2.6666666667vw;

      border-top-color: $share-tooltip-bg;

            
      @include media-breakpoint-up(md) {
          border-width: 0.9rem;
      }

      @include media-breakpoint-up(lg) {
          border-width: 1rem;
      }

      @include media-breakpoint-up(md) {
          bottom: -1.8rem;
      }

      @include media-breakpoint-up(lg) {
          bottom: -2rem;
      }

      @include media-breakpoint-up(md) {
          right: 6.9rem;
      }

      @include media-breakpoint-up(lg) {
          right: 6.5rem;
      }

    }

    &.active {
      transform: scale(1, 1);
      opacity: 1;
      visibility: visible;
    }

    p {
      color: $color-fg;
    }

    a {
      display: inline-block !important;
      padding-top: 2.6666666667vw;
      padding-bottom: 2.6666666667vw;

            
      @include media-breakpoint-up(md) {
          padding-top: 0.9rem;
      }

      @include media-breakpoint-up(lg) {
          padding-top: 1rem;
      }

      @include media-breakpoint-up(md) {
          padding-bottom: 0.9rem;
      }

      @include media-breakpoint-up(lg) {
          padding-bottom: 1rem;
      }

      svg {
        margin-left: 2.6666666667vw;
        margin-right: 2.6666666667vw;

        @include media-breakpoint-up(md) {
            margin-left: 0.9rem;
        }

        @include media-breakpoint-up(lg) {
            margin-left: 1rem;
        }

        @include media-breakpoint-up(md) {
            margin-right: 0.9rem;
        }

        @include media-breakpoint-up(lg) {
            margin-right: 1rem;
        }
      }
    }

  }

}

.social-share .social-share-tooltip a svg {
  width: 6.4vw !important;
  height: 5.6vw !important;
}

@include media-breakpoint-up(md) {
  .social-share .social-share-tooltip a svg {
    width: 2.88rem !important;
    height: 2.52rem !important;
  }
}
@include media-breakpoint-up(lg) {
  .social-share .social-share-tooltip a svg {
    width: 2.6rem !important;
    height: 2.2rem !important;
  }
}