// debut commun
@mixin offers-component-text-small {
    .content-text {
        display: block;
        float: left;
        width: 66.667%;
        position: static;
        left: 0;
        right: 0;
        padding: 0 2.6666666667vw;

        * {
            line-height: 1.25em;
        }

        .over-title-text {
            text-transform: uppercase;
            font-size: 3.4666666667vw;

            @include media-breakpoint-up(md) {
                font-size: 1.3rem;
            }
        }

        .h3 {
            font-family: $theme-font-bold;
            font-size: 4.8vw;
            padding-bottom: 0.2em;
            line-height: 1.15em;

            @include media-breakpoint-up(md) {
                font-size: 1.68rem;
            }
            
            @include media-breakpoint-up(lg) {
                font-size: 1.8rem;
            }
        }

        .under-title-text {
            font-size: 4.4vw;
            line-height: 1.25em;

            @include media-breakpoint-up(md) {
                font-size: 1.4rem;
            }

        }

        .note {
            font-family: $theme-font-regular;

            font-size: 4.4vw;
            color: #8C8C8C;

            @include media-breakpoint-up(md) {
                font-size: 1.4rem;
            }
        }
    }
}

@mixin offers-component-text-medium-up {
    .content-text {
        float: none;
        width: 100%;
        padding: 0;
        margin: 0;
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
    }
}

.offers-component-wrapper {
	> h2 {
		color: #fff;
	}
}

.offers-component {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 360px) and (max-width: 768px) {
        .area-offres{
            margin: 10px 0 0 0;
        }
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: 4vw;
    }

    &.row > .columns {
        padding-left: 2.6666666667vw;
        padding-right: 2.6666666667vw;

                
        @include media-breakpoint-up(md) {
            padding-left: 1.2rem;
        }
        
        @include media-breakpoint-up(lg) {
            padding-left: 2rem;
        }
        
        @include media-breakpoint-up(md) {
            padding-right: 1.2rem;
        }
        
        @include media-breakpoint-up(lg) {
            padding-right: 2rem;
        }
    }

    .alt-text {
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        overflow: hidden;
        position: absolute !important;
        width: 1px;
    }

    .media-component,
    .media-image {
        display: block;
        @media (min-width: 360px) {
            flex: 0 0 100%;
            max-width: 100%;  
        }

        .content-img {
			position: relative;
            display: block;
            float: left;
            width: 33.333%;
        }
    }

    @include offers-component-text-small;

    &.promo-stack-small {
        .content-text,
        .content-img {
            display: block;
            float: none;
            width: 100%;
        }
    }

    @include media-breakpoint-down(sm) {
        &:not(.promo-stack-small) {
            .content-text {
                text-align: left;
            }
        }
    }

    .promo-tag {
        position: absolute;
        display: table;
        box-sizing: content-box;
        width: auto;
        max-width: 43.3333333333vw;
        min-width: 20vw;
        min-height: 22.9333333333vw;
        text-transform: uppercase;
        text-align: center;
        right: 5.3333333333vw;
        top: 0vw;
        padding: 2.6666666667vw;

                
        @include media-breakpoint-up(md) {
            max-width: 16.5rem;
        }
        
        @include media-breakpoint-up(lg) {
            max-width: 22.5rem;
        }
        
        @include media-breakpoint-up(md) {
            min-width: 7.5rem;
        }
        
        @include media-breakpoint-up(lg) {
            min-width: 7.5rem;
        }
        
        @include media-breakpoint-up(md) {
            min-height: 7.2rem;
        }
        
        @include media-breakpoint-up(lg) {
            min-height: 8rem;
        }
        
        @include media-breakpoint-up(md) {
            right: 2.4rem;
        }
        
        @include media-breakpoint-up(lg) {
            right: 4rem;
        }
        
        @include media-breakpoint-up(md) {
            top: -1.2rem;
        }
        
        @include media-breakpoint-up(lg) {
            top: -2rem;
        }
        
        @include media-breakpoint-up(md) {
            padding: 1.2rem;
        }
        
        @include media-breakpoint-up(lg) {
            padding: 2rem;
        }

        .promo-tag-content {
            display: table-cell;
            vertical-align: middle;

            span {
                display: block;
            }

            .surtitle {
                font-size: 4.8vw;

                @include media-breakpoint-up(md) {
                    font-size: 1.7rem;
                }
                
                @include media-breakpoint-up(lg) {
                    font-size: 1.8rem;
                }
            }

            .title {
                font-size: 10.6666666667vw;

                font-family: $theme-font-black;
                line-height: 1.1em;

                @include media-breakpoint-up(md) {
                    font-size: 3.6rem;
                }
                
                @include media-breakpoint-up(lg) {
                    font-size: 4rem;
                }
            }

            .subtitle {
                font-size: 3.7333333333vw;

                font-family: $theme-font-bold;

                @include media-breakpoint-up(md) {
                    font-size: 1.4rem;
                }
            }

            sup {
                top: -0.4em;
                font-size: 0.6em;
            }
        }
    }

    a .promo-tag {
        right: 2.6666666667vw;


        @include media-breakpoint-up(md) {
            right: 1.2rem;
        }
        
        @include media-breakpoint-up(lg) {
            right: 2rem;
        }
    }
}

@include media-breakpoint-up(md) {
    .offers-component {
        .media-component,
        .media-image {

            .content-img {
                float: none;
                width: 100%;
            }
        }

        @include offers-component-text-medium-up;
    }
}

@include media-breakpoint-up(lg) {
    .offers-component {
        .media-component,
        .media-image {
            margin-bottom: 0;
        }
    }
}

// inside menu
.menu {
    .menu-item {
        .offers-component {
            display: none;
        }
    }
}

@include media-breakpoint-up(lg) {
    .menu {
        .menu-item {
            .offers-component {
                .tile-inner .bg-img {
                    margin: 0 !important;
                    width: 100% !important;
                    height: 100% !important;
                }

                display: block;
                position: absolute;
                top: 8rem;

                right: 1.5rem;
                visibility: hidden;

                overflow: hidden;
                text-align: right;
                margin-top: 0;

                > .columns {
                    width: 25%;
                }

                &[data-nbitems="1"] {
                    > .columns:nth-child(1n+2) {
                        display: none !important;
                    }
                }

                &[data-nbitems="2"] {
                    > .columns:nth-child(1n+3) {
                        display: none !important;
                    }
                }

                &[data-nbitems="3"] {
                    > .columns:nth-child(1n+4) {
                        display: none !important;
                    }
                }
            }
        }
    }

    .magnolia-editor {
        .menu.menu-editor {
            .menu-item {
                .offers-component {
                    display: block;
                    position: static;
                    top: auto;
                    right: auto;
                    visibility: visible;

                    > .columns {
                        width: 100%;
                    }
                }
            }
        }
    }
}

// fin commun

.offers-component {
    .content-text {
        .h3 {
			color: #fff;
            font-family: $theme-font-regular;

            font-size: 3.3333333333vw;

            @include media-breakpoint-up(md) {
                font-size: 1.5rem;
            }
            
            @include media-breakpoint-up(lg) {
                font-size: 1.7rem;
            }
        }
    }
}

.offers-component {
    &.on-en-parle {
        margin-bottom: 0;
        padding-bottom: 22px;
    }

    .content-text {
        .over-title-text {
            color: #fff;
            font-family: $theme-font-light;
            text-transform: uppercase;

            font-size: 3.4666666667vw;

            @include media-breakpoint-down(sm) {
                margin-left: 3vw;
                width: fit-content;
            }

            @include media-breakpoint-up(md) {
                font-size: 1.3rem;
            }
        }

        h3,
        .h3 {
            font-size: 4.8vw;

            @include media-breakpoint-down(sm) {
                width:fit-content;
            }    

            @include media-breakpoint-up(md) {
                font-size: 1.7rem;
            }
            
            @include media-breakpoint-up(lg) {
                font-size: 1.8rem;
            }
        }

        .under-title-text,
        .note {
            font-family: $theme-font-light;

            font-size: 3.2vw;

            @include media-breakpoint-up(md) {
                font-size: 1.5rem;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .offers-component {
        &.on-en-parle {
            > .columns {
                &:last-child {
                    display: block;
                }
            }
        }
    }
}

// @include page-body-theme-colors {
// 	.content-text {
//  	    h3,
//         .h3 {
//             color: $color-theme-fg;
//         }

//         .note {
//             color: $color-theme-fg;
//         }
//     }
// }