body {
	&.theme-bg-casino {
		section {
			&.section-page {

				& > h2 {
                    text-align: center;
					font-family: 'graphik_regular', Arial, Helvetica, sans-serif;
					font-size: 3.21428571rem;
					margin: 4.28571429rem auto 3.21428571rem auto;
					@include media-breakpoint-only(md) {
						font-size: 2rem;
						margin: 3rem auto 2rem auto;
					}
					@include media-breakpoint-down(sm) {
						margin-left: auto;
						margin-right: auto;
						font-size: 1.3rem;
						margin: 1.5rem auto 1.3rem auto;
					}
                }
            }
        }
        @include media-breakpoint-down(sm) {
			section {
				margin-top: 0;
			}
			.section-page {
				overflow: hidden;
			}
		}
	}
}
