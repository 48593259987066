
.theme-bg-poker {
	background-color: #f6f6f6;
	background-image: none;

	@import "components/carousel-promotions";
	@import "components/subcontainer";
	
	.btn {
		color: #000;
		text-decoration: none;
		text-transform: uppercase;
	}

	a {
		color: #000;
	}

	.media-tabs .link-lists>a {
		color: #000;
	}

	@import "components/footer";
	@import "components/media-bg_text";
	@import "components/offers";
}

// CASINO: Main avec menu-ferme
.main.container-fluid-casino.ferme {
  @media screen and (min-width:992px) {
    width: calc(100% - 55px);
  }
  width: 100%;
}
.main.container-fluid-casino.ouvert {
  @media screen and (min-width:992px) {
    width: calc(100% - 240px);
  }
  width: 100%;
}

.main.container-fluid-casino-ate.ferme {
  @media screen and (min-width: 1100px) {
    width: calc(100% - 55px);
  }
  @media screen and (min-width:1718px) {
    width: calc(100% - 240px);
  }
  width: 100%;
}
.main.container-fluid-casino-ate.ouvert {
  @media screen and (min-width:1100px) {
    width: calc(100% - 240px);
  }
  width: 100%;
}

/* En ordre ALPHABETIQUE */
@import "components/accordeon";
@import "components/bloc.image.texte";
@import "components/bloc.deux.textes";
@import "components/carousel";
@import "components/columns";
@import "components/grid-tile";
@import "components/lexique";
@import "components/lightbox.telecharger";
@import "components/media.tabs";
@import "components/media-text";
@import "components/menu";
@import "components/multi-formats";
@import "components/pseudo";
@import "components/section";
@import "components/tableau";
@import "components/tile-static";