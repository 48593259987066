.carousel-standard.carousel.carousel.carousel-full-width {
	.owl-dots {
		span {
			border-color: black;
		}
		.owl-dot.active {
			span {
				background: black;
			}
		}
	}
	.theme-bg-noir .row .columns .btn:hover {
		border-color: white;
		color: white;
	}
	.theme-bg-blanc .row .columns .btn:hover {
		border-color: black;
	}
	.desktop-content {
		.row .columns {
			.btn:hover {
				background-color: transparent;
			}
		}
		h3 {
			@include media-breakpoint-only(md) {
				margin-top: 1rem;
			}
		}
	}
	div.mobile-content {
		.media-component.media-text {
			background-color: #091a38;
			.content-inner {
				div.image-title,
				.rich-text {
					width: 50%;
					margin: 0 1em;	
				}
				.rich-text {
					display: flex;
					justify-content: center;
				}
			}
		}
	}	
}
@include media-breakpoint-down(sm) {
	.row.for-carousel-standard {
		.carousel.carousel-standard {
			.tile {
				.tile-inner {
					.media-text-with-bg-image {
						.mobile-content {
							background-color: black;
						}
					}
				}
			}
		}
	}
}

// Règle spéciale pour fix un bug sur le carrousel où on peut voir une ligne de pixel de l'élément prochain.
.row.for-carousel-standard .carousel.carousel-standard .tile .tile-inner .media-text-with-bg-image .mobile-content {
	@include media-breakpoint-down(sm) {
		width: 100%;
	}
}
