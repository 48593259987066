// debut commun

.media-image + .media-icon {
    height: auto;
}

.media-icon {

    width: 100%;
    display: table;

    .title {
      font-size: 3.7333333333vw;

        line-height: 1em;
        font-family: $theme-font-regular;
        margin-top: 1em;

        @include media-breakpoint-up(md) {
          font-size: 1.6rem;
        }
        
    }

    + .media-image {
        height: auto;
    }

    .icon-wrapper {
      height: 16vw;

      @include media-breakpoint-up(md) {
        height: 6rem;
      }
      svg {
        max-width: 16vw;

        @include media-breakpoint-up(md) {
          max-width: 6rem;
        }
      }
    }



    img {
        display: block;
        margin: 0 auto;
    }

    .content {
        margin-top: 3.33333vw;            //TODO Intégration Magnolia : mettre à 0 ic uniquement
        margin-bottom: 3.33333vw;
        vertical-align: middle;
    }

    .text-wrapper {
        text-align: center;
        position: relative;
    }

    .rich-text, &.rich-text {
      font-family: $theme-font-bold;
      font-size: 4.2666666667vw;
      line-height: 1.2em;
      margin-top: 0.5em;
      @include media-breakpoint-up(md) {
        font-size: 1.92rem;
      }
      
      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
      }

    }

}

@include media-breakpoint-up(md) {
    .media-icon {
        .content {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

@include media-breakpoint-up(lg) {
    .media-icon {

        padding: 0 10%;
    }

    .media-text ~ .media-icon {
        display: inline-block;
        padding: 0;
        width: auto;
    }
}

// fin commun

.media-icon {
	padding: 0 10% 0 12%;
  .icon-wrapper {
  	max-width: 21.3333333333vw;
  height: 21.3333333333vw;

      
    @include media-breakpoint-up(md) {
        max-width: 7.8rem;
    }

    @include media-breakpoint-up(lg) {
        max-width: 6rem;
    }

    @include media-breakpoint-up(md) {
        height: 7.8rem;
    }

    @include media-breakpoint-up(lg) {
        height: 8rem;
    }

    svg {
      max-width: 21.3333333333vw;

      @include media-breakpoint-up(md) {
        max-width: 7.8rem;
      }
      
      @include media-breakpoint-up(lg) {
        max-width: 6rem;
      }
    }
  }
}
