.tile,
.carousel {
    transition-property: width, height;
    transition-duration: 0.3s;

    &[data-width="1"] {
        &[data-height="1"] .tile-sizer:after {
            padding-top: 100%;
        }

        &[data-height="2"] .tile-sizer:after {
            padding-top: 200%;
        }

        &[data-height="3"] .tile-sizer:after {
            padding-top: 300%;
        }

        &[data-height="4"] .tile-sizer:after {
            padding-top: 300%;
        }
    }

    &[data-width="15"] {
        &[data-height="15"] .tile-sizer:after {
            padding-top: 100%;
        }

        &[data-height="2"] .tile-sizer:after {
            padding-top: 125%;
        }

        &[data-height="3"] .tile-sizer:after {
            padding-top: 150%;
        }

        &[data-height="4"] .tile-sizer:after {
            padding-top: 175%;
        }
    }

    &[data-width="2"] {
        &[data-height="1"] .tile-sizer:after {
            padding-top: 50%;
        }

        &[data-height="2"] .tile-sizer:after {
            padding-top: 100%;
        }

        &[data-height="3"] .tile-sizer:after {
            padding-top: 150%;
        }

        &[data-height="4"] .tile-sizer:after {
            padding-top: 200%;
        }
    }

    &[data-width="3"] {
        &[data-height="1"] .tile-sizer:after {
            padding-top: 25%;
        }

        &[data-height="2"] .tile-sizer:after {
            padding-top: 50%;
        }

        &[data-height="3"] .tile-sizer:after {
            padding-top: 100%;
        }
    }

    &[data-width="4"] {
        &[data-height="1"] .tile-sizer:after {
            padding-top: 25%;
        }

        &[data-height="2"] .tile-sizer:after {
            padding-top: 50%;
        }

        &[data-height="3"] .tile-sizer:after {
            padding-top: 75%;
        }

        &[data-height="4"] .tile-sizer:after {
            padding-top: 100%;
        }
    }

    &[data-width="6"] {
        &[data-height="1"] .tile-sizer:after {
            padding-top: 16.6667%;
        }

        &[data-height="2"] .tile-sizer:after {
            padding-top: 33.3333%;
        }

        &[data-height="3"] .tile-sizer:after {
            padding-top: 50%;
        }

        &[data-height="4"] .tile-sizer:after {
            padding-top: 66.6667%;
        }

        &[data-height="5"] .tile-sizer:after {
            padding-top: 83.3333%;
        }

        &[data-height="6"] .tile-sizer:after {
            padding-top: 100%;
        }
    }
}

.tile,
.media-component {
    .content {
        margin: 0;
        overflow: hidden;
        position: relative;
        transition: opacity 0.3s ease-in-out;
        width: 100%;
    }

    .bg-img {
        opacity: 0;

        transition: opacity 0.3s ease-in-out;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: transparent;

        &.lazyloaded {
            height: 100%;

            opacity: 1;
        }
    }

    .tile-inner {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0.66667vw;
        background-color: $color-tile-bg;
        background-image: url(/.resources/ej-template-theme/img/ui/loader.gif);
        background-repeat: no-repeat;
        background-position: 50% 25%;
        background-size: 32px 32px;
    }
}

.media-component {
    .content {
        flex-grow: 1;
    }
}

.tile-sizer:after {
    content: "";
    display: block;
    width: 100%;
}

@include media-breakpoint-up(md) {
    .tile,
    .media-component {
        .tile-inner {
            background-position: center center;
            margin: 0.5rem;
        }
    }
}

.tile,
.media-component {
    .tile-inner {
        margin: 0;

        .tile-text-block {
            //, .carousel-text-block {
            position: absolute;
            z-index: 2;
            position: relative;
            top: 200px;

            h3,
            .h3 {
                line-height: 1em;
                margin: 0 0 0.3em;
                width: 100%;
            }

            .over-title-text + h3,
            .over-title-text + .h3 {
                margin-top: 0.25em;
            }

            .over-title-text,
            .under-title-text,
            .valeur,
            .note {
                display: block;
                line-height: 1.2em;
                width: 100%;
                font-size: 4.4vw;
                @include media-breakpoint-up(md) {
                    font-size: 1.44rem;
                }
            }

            .over-title-text {
                text-transform: uppercase;
            }

            .theme-bg-sports & {
                h3,
                .h3 {
                    font-size: 4.8vw;

                    @include media-breakpoint-up(md) {
                        font-size: 2.88rem;
                    }

                    @include media-breakpoint-up(md) {
                        font-size: 3.4rem;
                    }
                }

                .over-title-text {
                    font-size: 2.8vw;

                    @include media-breakpoint-up(md) {
                        font-size: 1.3rem;
                    }
                }

                .under-title-text {
                    font-size: 3.47vw;

                    @include media-breakpoint-up(md) {
                        font-size: 1.9rem;
                    }

                    @include media-breakpoint-up(md) {
                        font-size: 2rem;
                    }
               
                }
            }
        }
    }
}

// tile gutter
.tile {
    $tile-gutter: 12px;

    .content.ta-center {
        text-align: center;
    }

    .tile-inner {
        background-color: transparent;

        .reserv-wrapper {
            margin: $tile-gutter / 2;
        }

        .bg-img {
            margin: $tile-gutter / 2;
            width: calc(100% - #{$tile-gutter});
            height: calc(100% - #{$tile-gutter});
        }
    }
}

@include media-breakpoint-up(xxl) {
    // tile gutter
    .tile {
        $tile-gutter: 10px;

        .tile-inner {
            // background-color: transparent;
            .tile-text-block,
            .reserv-wrapper {
                margin: $tile-gutter / 2;
            }

            .bg-img {
                margin: $tile-gutter / 2;
                width: calc(100% - #{$tile-gutter});
                height: calc(100% - #{$tile-gutter});
            }
        }
    }
}

@include media-breakpoint-up(xxxl) {
    // tile gutter
    .tile {
        $tile-gutter: 20px;

        .tile-inner {
            // background-color: transparent;
            .tile-text-block,
            .reserv-wrapper {
                margin: $tile-gutter / 2;
            }

            .bg-img {
                margin: $tile-gutter / 2;
                width: calc(100% - #{$tile-gutter});
                height: calc(100% - #{$tile-gutter});
            }
        }
    }
}

// @include page-theme-colors {
//     .grid {
//         .tile {
//             .tile-static {
//                 .tile-text-block {
//                     h3,
//                     .under-title-text {
//                         color: $color-w1;
//                     }

//                     color: $color-g1;

//                     &.transparent {
//                         position: absolute;
//                         bottom: 0;
//                         left: 0;
//                         margin-right: 0.2rem;
//                         max-width: none;

//                         h3 {
//                             color: $color-w1;
//                             text-transform: uppercase;
//                             font-size: 3.33333vw;

//                             margin-bottom: 0;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }


main.theme-bg-sports {
    .media-image-with-text-below {
        h3 {
            font-family: $lato-font;
            font-weight: normal;
            
            @include media-breakpoint-up(lg) {
                font-size: 2.2rem;
                line-height: 1.33;
            }

            @include media-breakpoint-up(lg) {
                font-size: 1.8rem;
            }
        }
    }
    
    .tile {        
        .tile-inner {
			background-color: #000000;
			color: #fff;

            .tile-text-block {
                h3 {
                    line-height: 1.2;
                }
            }

            .bg-img {
                margin: 0;
                width: 100%;
            }
        }

        &.format42 {
            background-color: #000000;
            min-height: 210px;

            .tile-text-block {
                bottom: 0;
                display: flex;
                flex-direction: column;
                height: auto;
                justify-content: flex-start;

                @include media-breakpoint-up(md) {
                    height: 33%;
                }
            }
        }

        &.format22 {
            .tile-text-block {
                padding-top: 4vw;

                h3 {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    main.theme-bg-sports {
        .tile {
            .tile-inner {
                background-color: transparent;
            }

            // &.format42 {
            //     .tile-text-block {
            //         top: calc(100% - 16.7vw);
            //     }
            // }

            &.format22 {
                .tile-text-block {
                    padding-top: 3vw;

                    h3 {
                        margin-bottom: 0.3em;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    // tile gutter

    main.theme-bg-sports {
        .tile {
            $tile-gutter: 18px;

            .tile-inner {
                .bg-img {
                    height: 100%;
                }
            }

            &.format42 {
                background-color: transparent;

                .tile-text-block {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@include media-breakpoint-up(xxl) {
    // tile gutter
    main.theme-bg-sports {
        .tile {
            $tile-gutter: 18px;

            .tile-inner {
                .tile-text-block,
                .reserv-wrapper {
                    margin: $tile-gutter / 2;
                }

                .bg-img {
                    height: calc(100% - #{$tile-gutter});
                }
            }
        }
    }
}
