.offer-block,
.related-articles-block {
	background-color: rgba(0,0,0,.8);
	padding-top: 7.33333vw;
	padding-bottom: 7.33333vw;
	margin-top: 0;
	margin-bottom: 0;
	.title-wrapper {
		.columns {
			text-align: center;
		}
	}

	.row {
		margin: 0 auto;
		padding-right: 5.06667vw;
		padding-left: 5.06667vw;
		text-align: center;
	}
	.columns {
		float: none !important;
		display: inline-block;
		text-align: initial;
		vertical-align: top;
	}


	h2 {
		display: inline-block;
		position: relative;
		text-align: center;
		color: $color-theme-text-default;
		font-family: $theme-font-light;
		line-height: 1 !important;
		margin-bottom: 7.33333vw;
		padding: 0 !important;
	}

	h3 {
		font-size: 2rem;
	}
	.over-title-text {
		color: $color-theme-text-default;
		font-family: $theme-font;
		font-size: 1.3rem;
		font-weight: normal;
		line-height: 1;
		margin-bottom: 0.6rem;
		text-transform: uppercase;
	}

	.under-title-text {
		color: $color-theme-text-default;
		font-family: $theme-font;
		font-size: 1.1rem;
		font-weight: normal;
		line-height: 1;
		margin-top: 1rem;
	}
	.media-component {
		text-align: left;
	}

	.media-component {
		text-align: left;
	}
}

.offer-block {
	background-color: rgba(20,20,20,.9);
}

.related-articles-block {
	.content-text {
		margin-top: 1.5rem;
	}
}

@include media-breakpoint-down(sm) {
	.offer-block,
	.related-articles-block {
		.tile-inner {
			margin-left: 0;
			margin-right: 0;
		}
		.content-img {
			float: left;
			margin-bottom: 1rem;
			width: 33%;
		}
		.content-text {
			float: left;
			margin-bottom: 1rem;
			padding-left: 1rem;
			width: 66%;
		}
	}
}

@include media-breakpoint-only(md) {
	.content-container.offer-block .tile-sizer:after {
		padding-top: 100%;
	}
}

@include media-breakpoint-only(md) { //	temporary
	.offer-block,
	.related-articles-block {
		.tile-inner {
			margin-left: 0;
			margin-right: 0;
		}
		.content-img {
			float: left;
			margin-bottom: 1rem;
			width: 33%;
		}
		.content-text {
			float: left;
			margin-bottom: 1rem;
			padding-left: 1rem;
			width: 66%;
		}
	}
}

@include media-breakpoint-up(lg) {
	.offer-block,
	.related-articles-block {
		padding-top: 7rem;
		padding-bottom: 7.5rem;

		h2 {
			line-height: 1 !important;
			margin-bottom: 7rem;
			padding: 0 !important;
		}
		.content-text {
			margin-top: 1.5rem;
		}
		h3 {
			font-size: 2rem;
		}
	}

	.offer-block {
		.columns,
		.column {
			padding-left: 0.46875rem;
			padding-right: 0.46875rem;
		}

		.media-component {
			text-align: center;
		}
	}
	//OK
	.related-articles-block {
		.columns,
		.column {
			padding-left: 2rem !important;
			padding-right: 2rem !important;

			&.large-2 {
				width: 19.5%;
			}
		}
	}
}
