// debut commun
// @include page-theme-colors {
//     .media-image {
//         display: block;
//         position: relative;

//         .content-text {
//             z-index: 2;
//             font-size: 4.4vw;
//             line-height: 6.4vw;

                        
//             @include media-breakpoint-up(md) {
//                 font-size: 1.98rem;
//             }
            
//             @include media-breakpoint-up(lg) {
//                 font-size: 1.4rem;
//             }
            
//             @include media-breakpoint-up(md) {
//                 line-height: 2.88rem;
//             }
            
//             @include media-breakpoint-up(lg) {
//                 line-height: 2.1rem;
//             }
//         }

//         &.with-rollover-logo {
//             .img-logo {
//                 opacity: 0;
//             }
//         }

//         .content-text-inner > div {
//             font-family: "graphik_bold", Arial, Helvetica, sans-serif; /*$theme-font-bold;*/

//             &.under-title-text {
//                 font-family: $color-theme-font-medium;

//                 font-size: 3.7333333333vw;

//                 line-height: 1.2em;

//                 @include media-breakpoint-up(md) {
//                     font-size: 1.5rem;
//                 }
                
//                 @include media-breakpoint-up(lg) {
//                     font-size: 0.9rem;
//                 }
//             }

//             &.over-title-text {
//                 font-family: $color-theme-font-medium; /*$oswald-font-medium;*/
//                 font-size: 4.8vw;
//                 line-height: 5.3333333333vw;
//                 padding: 0.8vw;
                
//                 @include media-breakpoint-up(md) {
//                     font-size: 3vw;
//                     line-height: 3.6vw;
//                     padding: 0;
//                 }
                
//                 @include media-breakpoint-up(lg) {
//                     font-size: 2.1rem;
//                     line-height: 2.5rem;
//                 }

//                 + .under-title-text {
//                     font-size: 3.2vw;

//                     @include media-breakpoint-up(md) {
//                         font-size: 1.2rem;
//                     }
                    
//                     @include media-breakpoint-up(lg) {
//                         font-size: 1.1rem;
//                     }
//                 }
//             }
//         }

//         .tile-inner .alt-text {
//             position: relative;

//             &:after {
//                 content: '';
//                 position: absolute;
// 				top: 0;
// 				right: 0;
// 				bottom: 0;
// 				left: 0;
//             }
//         }
//     }
// }

@include media-breakpoint-down(sm) {
    .mobile-gallery {
        white-space: nowrap;
        overflow-x: visible;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
}

@include media-breakpoint-up(lg) {
    .media-image {
        //.bg-img .img-logo {
        .img-logo {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: rgba(0,0,0,0.5) none no-repeat center center; //TODO variable

			.img-logo-inner {
				background-repeat: no-repeat;
				max-width: 40%;
				max-height: 30%;
				transform: translateX(-50%) translateY(-50%);
				top: 50%;
				left: 50%;
				position: absolute;
				background-size: contain;
			}
		}
		&.with-rollover-logo {

			//.bg-img .img-logo {
            .img-logo {
                opacity: 0;
            }

            //&:hover .bg-img .img-logo,
            &:hover .img-logo,
            &:hover .content-text {
                opacity: 1;
            }
        }

        &.with-rollover-text {
            .content-text,
            .content-img.with-text .bg-img:after {
                opacity: 0;

                transition: opacity 0.3s;
            }

            &:hover .content-text,
            &:hover .content-img.with-text .bg-img:after {
                opacity: 1;
            }
        }

        &.content-always-visible {
            //.bg-img .img-logo {
            .img-logo {
                background-color: transparent;

                opacity: 1;
            }

            .content-text {
                opacity: 1;
            }
        }
    }
}

// fin commun

// overwride media-image text behavior in multi-formats and carousel promotions
.multi-formats {
    .media-image {
        .content-img {
            .bg-img:after {
                opacity: 0 !important;
            }
        }

        .content-text {
            top: 60%;
            left: 5%;
            right: 0;
            font-size: 4.4vw;
            right: auto;
            @include media-breakpoint-up(md) {
                font-size: 1.98rem;
            }
            
            @include media-breakpoint-up(lg) {
                font-size: 1.8rem;
            }


            &.text-color16 {
                color: $color-noir;
            }

            .under-title-text {
                font-size: 4.6666666667vw;

                text-transform: uppercase;

                @include media-breakpoint-up(md) {
                    font-size: 1.2rem;
                }
                
                @include media-breakpoint-up(xlg) {
                    font-size: 1.5rem;
                }
            }

            .over-title-text {
                font-family: "graphik_medium", Arial, Helvetica, sans-serif;

                font-size: 4.6666666667vw;
                margin-top: 1.3333333333vw;
                text-transform: uppercase;
                line-height: 1.2em;
                padding: 0;

                                
                @include media-breakpoint-up(md) {
                    font-size: 1.8rem;
                }
                
                @include media-breakpoint-up(lg) {
                    font-size: 3.6rem;
                }
                
                @include media-breakpoint-up(md) {
                    margin-top: 0.6rem;
                }
                
                @include media-breakpoint-up(lg) {
                    margin-top: 0.4rem;
                }
                
                @include media-breakpoint-up(lg) {
                    font-size: 2.44140625vw;
                }
                
                @include media-breakpoint-up(xxl) {
                    font-size: 3.2rem;
                }
                
             
            }

            div.over-title-text + .under-title-text {
                font-size: 4.4vw;
                margin-top: 2vw;

                                
                @include media-breakpoint-up(md) {
                    font-size: 1.14rem;
                }
                
                @include media-breakpoint-up(lg) {
                    font-size: 1.3rem;
                }
                
                @include media-breakpoint-up(md) {
                    margin-top: 0.48rem;
                }
                
                @include media-breakpoint-up(lg) {
                    margin-top: 0.7rem;
                }
                
                @include media-breakpoint-up(lg) {
                    font-size: 0.9333333333vw;
                    margin-top: 0.4vw;
                }
            }
        }
    }
}

// @include components-theme-colors {

//     .carousel-promotions {
//         .media-image {
//             .content-text {
//                 .under-title-text {
//                     color: $color-theme-fg;
//                 }

//                 // simulation bouton
//                 div.over-title-text + .under-title-text {
//                     font-family: $color-theme-font-medium; /*$theme-font-medium;*/
//                     border: 2px solid;

//                     border-radius: 8px;

//                     transition: all 0.2s;

//                     display: inline-block;

//                     @include media-breakpoint-down(sm) {
//                         padding: 1.6vw 4vw;
//                     }

//                     @include media-breakpoint-up(md) {
//                         padding: 1.33333vw 2.93333vw;
//                     }

//                     @include media-breakpoint-up(lg) {
//                         padding: 9px 16px;
//                     }

//                     @include media-breakpoint-up(xxl) {
//                         font-size: 14px;
//                         padding: 12px 22px;
//                     }

                   
//                 }

//                 &.text-color15 {
//                     // noir
//                     .under-title-text,
//                     .over-title-text {
//                         color: $color-noir;
//                     }

//                     div.over-title-text + .under-title-text {
//                         color: $color-noir;
//                         border-color: $color-blanc;
//                         background-color: $color-blanc;
//                         display: inline-block;

//                         &:hover {
//                             color: $color-blanc;
//                             background-color: transparent;
//                         }
//                     }
//                 }

//                 &.text-color16 {
//                     // blanc
//                     .under-title-text,
//                     .over-title-text {
//                         color: $color-blanc;
//                     }

//                     div.over-title-text + .under-title-text {
//                         color: $color-noir;
//                         border-color: $color-blanc;
//                         background-color: $color-blanc;
//                         display: inline-block;

//                         &:hover {
//                             background-color: transparent;
//                             color: $color-blanc;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }


.main > div.content-container .media-component,
section .content-container.multi-formats .media-component {
    &.media-image {
        margin: 0;
    }
}

@include media-breakpoint-down(sm) {
    section .content-container.multi-formats .media-component.media-image {
        margin: 0.57143rem 0rem;
    }
}

main.theme-bg-sports {
    div.media-image-with-text-below {
        div.with-text {
            .tile-inner {
                .content-text {
                    top: 0;

                    .over-title-text {
						color: #fff;
                        width: auto;
                        display: inline-block;
                        padding: 1.3rem 3.5rem;
                        background-color: black;
                        z-index: 999;
                        font-size: 3.8666666667vw;
                        padding-top: 2.1333333333vw;
                        padding-bottom: 2.1333333333vw;
                        padding-left: 6vw;
                        padding-right: 6vw;
                        font-family: "graphik_bold", Arial, Helvetica, sans-serif; /*'Montserrat Bold';*/

                                                
                        @include media-breakpoint-up(md) {
                            font-size: 1.8rem;
                        }
                        
                        @include media-breakpoint-up(lg) {
                            font-size: 2.5rem;
                        }
                        
                        @include media-breakpoint-up(md) {
                            padding-top: 1.2rem;
                        }
                        
                        @include media-breakpoint-up(lg) {
                            padding-top: 1.6rem;
                        }
                        
                        @include media-breakpoint-up(md) {
                            padding-bottom: 1.2rem;
                        }
                        
                        @include media-breakpoint-up(lg) {
                            padding-bottom: 1.6rem;
                        }
                        
                        @include media-breakpoint-up(md) {
                            padding-left: 3.12rem;
                        }
                        
                        @include media-breakpoint-up(lg) {
                            padding-left: 4.4rem;
                        }
                        
                        @include media-breakpoint-up(md) {
                            padding-right: 3.12rem;
                        }
                        
                        @include media-breakpoint-up(lg) {
                            padding-right: 4.4rem;
                        }

                        
                    }
                }
            }
        }
    }
}
