// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: (
  (
    0: 0,
    1: (
      $spacer * 0.5
    ),
    2: (
      $spacer * 1
    ),
    3: (
      $spacer * 1.5
    ),
    4: (
      $spacer * 2
    ),
    5: (
      $spacer * 2.5
    ),
    8:(
      $spacer * 4
    )
  )
);


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
//
// Usage
// https://getbootstrap.com/docs/4.3/layout/overview/#responsive-breakpoints

$grid-breakpoints: (
  xxs: 0,
  xs: 360px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1920px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1280px
);