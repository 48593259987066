.youtube-container {
  // Variables Baraka pour Overlay Youtube
  --brk-button-opacity-disabled: 50%;
  --brk-button-primary-border-radius: 0.25174545454545455em;
  --brk-button-primary-border-width: 0px;
  --brk-button-primary-font-family: Arial;
  --brk-button-primary-font-weight: 700;
  --brk-button-primary-height: 2.7692em;
  --brk-button-primary-icon-gap: 0.6153em;
  --brk-button-primary-icon-size: 1.2307em;
  --brk-button-primary-inverted-background-color-default: #ffffff;
  --brk-button-primary-inverted-background-color-focus: #2e49d1;
  --brk-button-primary-inverted-background-color-hover: #2e49d1;
  --brk-button-primary-inverted-border-color-default: transparent;
  --brk-button-primary-inverted-border-color-focus: transparent;
  --brk-button-primary-inverted-border-color-hover: transparent;
  --brk-button-primary-inverted-font-color-default: #253aa7;
  --brk-button-primary-inverted-font-color-focus: #ffffff;
  --brk-button-primary-inverted-font-color-hover: #ffffff;
  --brk-button-primary-inverted-icon-fill-default: #253aa7;
  --brk-button-primary-inverted-icon-fill-focus: #ffffff;
  --brk-button-primary-inverted-icon-fill-hover: #ffffff;
  --brk-button-primary-letter-spacing: 2%;
  --brk-button-primary-lg-font-size: 24px;
  --brk-button-primary-line-height: 110%;
  --brk-button-primary-md-font-size: 20px;
  --brk-button-primary-padding-horizontal: 1.2307em;
  --brk-button-primary-sm-font-size: 16px;
  --brk-button-primary-standard-background-color-default: #192872;
  --brk-button-primary-standard-background-color-focus: #2e49d1;
  --brk-button-primary-standard-background-color-hover: #2e49d1;
  --brk-button-primary-standard-border-color-default: transparent;
  --brk-button-primary-standard-border-color-focus: transparent;
  --brk-button-primary-standard-border-color-hover: transparent;
  --brk-button-primary-standard-font-color-default: #ffffff;
  --brk-button-primary-standard-font-color-focus: #ffffff;
  --brk-button-primary-standard-font-color-hover: #ffffff;
  --brk-button-primary-standard-icon-fill-default: #ffffff;
  --brk-button-primary-standard-icon-fill-focus: #ffffff;
  --brk-button-primary-standard-icon-fill-hover: #ffffff;
  --brk-button-primary-text-decoration-default: none;
  --brk-button-primary-text-decoration-focus: none;
  --brk-button-primary-text-decoration-hover: none;
  --brk-button-primary-text-transform: none;
  --brk-button-secondary-border-radius: 0.25174545454545455em;
  --brk-button-secondary-border-width: 1px;
  --brk-button-secondary-font-family: Arial;
  --brk-button-secondary-font-weight: 700;
  --brk-button-secondary-height: 2.7692em;
  --brk-button-secondary-icon-gap: 0.6153em;
  --brk-button-secondary-icon-size: 1.2307em;
  --brk-button-secondary-inverted-background-color-default: transparent;
  --brk-button-secondary-inverted-background-color-focus: #ffffff;
  --brk-button-secondary-inverted-background-color-hover: #ffffff;
  --brk-button-secondary-inverted-border-color-default: #ffffff;
  --brk-button-secondary-inverted-border-color-focus: #ffffff;
  --brk-button-secondary-inverted-border-color-hover: #ffffff;
  --brk-button-secondary-inverted-font-color-default: #ffffff;
  --brk-button-secondary-inverted-font-color-focus: #090f2a;
  --brk-button-secondary-inverted-font-color-hover: #090f2a;
  --brk-button-secondary-inverted-icon-fill-default: #ffffff;
  --brk-button-secondary-inverted-icon-fill-focus: #090f2a;
  --brk-button-secondary-inverted-icon-fill-hover: #090f2a;
  --brk-button-secondary-letter-spacing: 2%;
  --brk-button-secondary-lg-font-size: 24px;
  --brk-button-secondary-line-height: 110%;
  --brk-button-secondary-md-font-size: 20px;
  --brk-button-secondary-padding-horizontal: 1.2307em;
  --brk-button-secondary-sm-font-size: 16px;
  --brk-button-secondary-standard-background-color-default: transparent;
  --brk-button-secondary-standard-background-color-focus: #090f2a;
  --brk-button-secondary-standard-background-color-hover: #090f2a;
  --brk-button-secondary-standard-border-color-default: #090f2a;
  --brk-button-secondary-standard-border-color-focus: #090f2a;
  --brk-button-secondary-standard-border-color-hover: #090f2a;
  --brk-button-secondary-standard-font-color-default: #090f2a;
  --brk-button-secondary-standard-font-color-focus: #ffffff;
  --brk-button-secondary-standard-font-color-hover: #ffffff;
  --brk-button-secondary-standard-icon-fill-default: #090f2a;
  --brk-button-secondary-standard-icon-fill-focus: #ffffff;
  --brk-button-secondary-standard-icon-fill-hover: #ffffff;
  --brk-button-secondary-text-decoration-default: none;
  --brk-button-secondary-text-decoration-focus: none;
  --brk-button-secondary-text-decoration-hover: none;
  --brk-button-secondary-text-transform: none;
  --brk-dec-border-radius-2xl: 24px;
  --brk-dec-border-radius-2xs: 2px;
  --brk-dec-border-radius-3xl: 28px;
  --brk-dec-border-radius-4xl: 32px;
  --brk-dec-border-radius-lg: 16px;
  --brk-dec-border-radius-md: 12px;
  --brk-dec-border-radius-none: 0px;
  --brk-dec-border-radius-sm: 8px;
  --brk-dec-border-radius-xl: 20px;
  --brk-dec-border-radius-xs: 4px;
  --brk-dec-border-width-lg: 4px;
  --brk-dec-border-width-md: 2px;
  --brk-dec-border-width-none: 0px;
  --brk-dec-border-width-sm: 1px;
  --brk-dec-border-width-xs: 1px;
  --brk-dec-box-shadow-inverted-default: 0px 0px 0px 0px #1a1a1a;
  --brk-dec-box-shadow-inverted-focus: 0px 0px 0px 0px #1a1a1a;
  --brk-dec-box-shadow-inverted-hover: 0px 2px 8px 0px #1a1a1a;
  --brk-dec-box-shadow-standard-default: 0px 0px 0px 0px #e6e6e6;
  --brk-dec-box-shadow-standard-focus: 0px 0px 0px 0px #e6e6e6;
  --brk-dec-box-shadow-standard-hover: 0px 2px 8px 0px #e6e6e6;
  --brk-dec-color-accent-dark: #4b0057;
  --brk-dec-color-accent-light: #FDF3D1;
  --brk-dec-color-accent-medium: #C92CA7;
  --brk-dec-color-accent-warm: #e21879;
  --brk-dec-color-main-dark-28: #192872;
  --brk-dec-color-main-darker: #090f2a;
  --brk-dec-color-main-extradark: #121d54;
  --brk-dec-color-main-extradark-25: #101a49;
  --brk-dec-color-main-extradark-51: #00034c;
  --brk-dec-color-main-extralight: #abb6ed;
  --brk-dec-color-main-lighter: #d5dbf6;
  --brk-dec-color-main-lightest-25: #f5f6fd;
  --brk-dec-color-main-lightest-50: #eaedfa;
  --brk-dec-color-main-medium: #2e49d1;
  --brk-dec-color-main-medium-75: #273eb2;
  --brk-dec-color-main-normal: #586dda;
  --brk-dec-color-main-semidark: #253aa7;
  --brk-dec-color-main-semidark-43: #1912a4;
  --brk-dec-color-main-semidark-50: #203392;
  --brk-dec-color-neutral-darker: #1a1a1a;
  --brk-dec-color-neutral-darkest: #000000;
  --brk-dec-color-neutral-extradark: #333333;
  --brk-dec-color-neutral-extradark-25: #2d2d2d;
  --brk-dec-color-neutral-extralight: #CCCCCC;
  --brk-dec-color-neutral-lighter: #e6e6e6;
  --brk-dec-color-neutral-lightest: #ffffff;
  --brk-dec-color-neutral-lightest-25: #f9f9f9;
  --brk-dec-color-neutral-lightest-50: #f2f2f2;
  --brk-dec-color-neutral-medium: #808080;
  --brk-dec-color-neutral-medium-75: #6c6c6c;
  --brk-dec-color-neutral-none: transparent;
  --brk-dec-color-neutral-normal: #999999;
  --brk-dec-color-neutral-semidark-75: #535353;
  --brk-dec-color-semantic-error-dark: #bf0000;
  --brk-dec-color-semantic-error-light: #ff5959;
  --brk-dec-color-semantic-success-dark: #148527;
  --brk-dec-color-semantic-success-light: #1fd33d;
  --brk-dec-color-semantic-warning-dark: #fdc50f;
  --brk-dec-color-semantic-warning-light: #fff9e6;
  --brk-dec-font-family-alt: Arial;
  --brk-dec-font-family-main: Graphik;
  --brk-dec-font-size-2xl: 32px;
  --brk-dec-font-size-3xl: 36px;
  --brk-dec-font-size-4xl: 40px;
  --brk-dec-font-size-lg: 24px;
  --brk-dec-font-size-md: 20px;
  --brk-dec-font-size-sm: 16px;
  --brk-dec-font-size-xl: 28px;
  --brk-dec-font-size-xs: 14px;
  --brk-dec-font-weight-black: 900;
  --brk-dec-font-weight-bold: 700;
  --brk-dec-font-weight-normal: 400;
  --brk-dec-letter-spacing-lg: 4%;
  --brk-dec-letter-spacing-md: 2%;
  --brk-dec-letter-spacing-sm: 0%;
  --brk-dec-letter-spacing-xs: -2%;
  --brk-dec-line-height-lg: 140%;
  --brk-dec-line-height-md: 130%;
  --brk-dec-line-height-sm: 120%;
  --brk-dec-line-height-xl: 150%;
  --brk-dec-line-height-xs: 110%;
  --brk-dec-opacity-all: 100%;
  --brk-dec-opacity-md: 50%;
  --brk-dec-opacity-none: 0%;
  --brk-dec-size-2xl: 48px;
  --brk-dec-size-2xs: 8px;
  --brk-dec-size-3xl: 64px;
  --brk-dec-size-3xs: 4px;
  --brk-dec-size-lg: 28px;
  --brk-dec-size-md: 24px;
  --brk-dec-size-none: 0px;
  --brk-dec-size-sm: 20px;
  --brk-dec-size-xl: 32px;
  --brk-dec-size-xs: 16px;
  --brk-dec-space-2xl: 48px;
  --brk-dec-space-2xs: 8px;
  --brk-dec-space-3xl: 64px;
  --brk-dec-space-3xs: 4px;
  --brk-dec-space-lg: 28px;
  --brk-dec-space-md: 24px;
  --brk-dec-space-none: 0px;
  --brk-dec-space-sm: 20px;
  --brk-dec-space-xl: 32px;
  --brk-dec-space-xs: 16px;
  --brk-interactive-inverted-outline-color-focus: #abb6ed;
  --brk-interactive-outline-offset: 2px;
  --brk-interactive-outline-width: 2px;
  --brk-interactive-standard-outline-color-focus: #203392;
  --brk-modal-window-border-radius: 4px;
  --brk-modal-window-border-width: 0px;
  --brk-modal-window-inverted-background-color: #090f2a;
  --brk-modal-window-inverted-border-color: transparent;
  --brk-modal-window-inverted-overlay-color: #00023380;
  --brk-modal-window-large-box-lg-max-height: 50vh;
  --brk-modal-window-large-box-lg-padding: 40px;
  --brk-modal-window-large-box-lg-width: 856px;
  --brk-modal-window-large-box-md-max-height: 80vh;
  --brk-modal-window-large-box-md-padding: 40px;
  --brk-modal-window-large-box-md-width: 744px;
  --brk-modal-window-large-box-sm-max-height: 90vh;
  --brk-modal-window-large-box-sm-padding: 24px;
  --brk-modal-window-large-box-sm-padding-x: 32px;
  --brk-modal-window-large-box-sm-width: 90vh;
  --brk-modal-window-medium-box-lg-max-height: 50vh;
  --brk-modal-window-medium-box-lg-padding: 32px;
  --brk-modal-window-medium-box-lg-width: 640px;
  --brk-modal-window-medium-box-md-max-height: 80vh;
  --brk-modal-window-medium-box-md-padding: 32px;
  --brk-modal-window-medium-box-md-width: 560px;
  --brk-modal-window-medium-box-sm-max-height: 90vh;
  --brk-modal-window-medium-box-sm-padding: 24px;
  --brk-modal-window-medium-box-sm-padding-x: 32px;
  --brk-modal-window-medium-box-sm-width: 90vh;
  --brk-modal-window-small-box-lg-max-height: 50vh;
  --brk-modal-window-small-box-lg-padding: 24px;
  --brk-modal-window-small-box-lg-padding-x: 32px;
  --brk-modal-window-small-box-lg-width: 424px;
  --brk-modal-window-small-box-md-max-height: 80vh;
  --brk-modal-window-small-box-md-padding: 24px;
  --brk-modal-window-small-box-md-padding-x: 32px;
  --brk-modal-window-small-box-md-width: 368px;
  --brk-modal-window-small-box-sm-max-height: 90vh;
  --brk-modal-window-small-box-sm-padding: 24px;
  --brk-modal-window-small-box-sm-padding-x: 32px;
  --brk-modal-window-small-box-sm-width: 70vh;
  --brk-modal-window-standard-background-color: #ffffff;
  --brk-modal-window-standard-border-color: transparent;
  --brk-modal-window-standard-overlay-color: #00023380;
  --brk-text-body-base-font-family: Arial;
  --brk-text-body-base-font-size: 16px;
  --brk-text-body-base-font-weight: 400;
  --brk-text-body-base-letter-spacing: 0%;
  --brk-text-body-base-line-height: 150%;
  --brk-text-body-base-margin-bottom: 1.5em;
  --brk-text-body-base-margin-top: 1em;
  --brk-text-body-base-paragraph-spacing: 1em;
  --brk-text-body-base-strong-font-weight: 700;
  --brk-text-body-base-text-decoration: none;
  --brk-text-body-base-text-transform: none;
  --brk-text-heading-1-font-family: Graphik;
  --brk-text-heading-1-font-weight: 700;
  --brk-text-heading-1-letter-spacing: 0%;
  --brk-text-heading-1-line-height: 130%;
  --brk-text-heading-1-paragraph-spacing: 1em;
  --brk-text-heading-1-strong-font-weight: 700;
  --brk-text-heading-1-text-decoration: none;
  --brk-text-heading-1-text-transform: none;
  --brk-text-heading-2-font-family: Graphik;
  --brk-text-heading-2-font-weight: 700;
  --brk-text-heading-2-letter-spacing: 0%;
  --brk-text-heading-2-line-height: 130%;
  --brk-text-heading-2-paragraph-spacing: 1em;
  --brk-text-heading-2-strong-font-weight: 700;
  --brk-text-heading-2-text-decoration: none;
  --brk-text-heading-2-text-transform: none;
  --brk-text-heading-3-font-family: Graphik;
  --brk-text-heading-3-font-size: 18px;
  --brk-text-heading-3-font-weight: 700;
  --brk-text-heading-3-letter-spacing: 0%;
  --brk-text-heading-3-line-height: 130%;
  --brk-text-heading-3-paragraph-spacing: 1em;
  --brk-text-heading-3-strong-font-weight: 700;
  --brk-text-heading-3-text-decoration: none;
  --brk-text-heading-3-text-transform: none;
  --brk-text-inverted-body-base-font-color: #ffffff;
  --brk-text-inverted-caption-font-color: #ffffff;
  --brk-text-inverted-display-1-font-color: #ffffff;
  --brk-text-inverted-display-2-font-color: #ffffff;
  --brk-text-inverted-display-3-font-color: #ffffff;
  --brk-text-inverted-heading-1-font-color: #ffffff;
  --brk-text-inverted-heading-2-font-color: #ffffff;
  --brk-text-inverted-heading-3-font-color: #ffffff;
  --brk-text-inverted-heading-4-font-color: #ffffff;
  --brk-text-link-inverted-body-font-color-default: #abb6ed;
  --brk-text-link-inverted-body-font-color-focus: #abb6ed;
  --brk-text-link-inverted-body-font-color-hover: #abb6ed;
  --brk-text-link-inverted-body-font-color-visited: #00034c;
  --brk-text-link-standard-body-font-color-default: #273eb2;
  --brk-text-link-standard-body-font-color-focus: #273eb2;
  --brk-text-link-standard-body-font-color-hover: #273eb2;
  --brk-text-link-standard-body-font-color-visited: #00034c;
  --brk-text-link-text-decoration-default: underline;
  --brk-text-link-text-decoration-hover: none;
  --brk-text-ratio-lg: 1.025;
  --brk-text-ratio-md: 1.125;
  --brk-text-ratio-sm: 1.25;
  --brk-text-standard-body-base-font-color: #090f2a;
  --brk-text-standard-caption-font-color: #090f2a;
  --brk-text-standard-display-1-font-color: #273eb2;
  --brk-text-standard-display-2-font-color: #273eb2;
  --brk-text-standard-display-3-font-color: #273eb2;
  --brk-text-standard-heading-1-font-color: #090f2a;
  --brk-text-standard-heading-2-font-color: #090f2a;
  --brk-text-standard-heading-3-font-color: #090f2a;
  --brk-text-standard-heading-4-font-color: #090f2a;
}
