body {
	&.theme-bg-poker {
		.table-vertical {
			line-height: 1.5;
		}

		.tableau {

			a.btn.hoverColorBlack {
				&:hover {
					color: black;
				}
			}

			table tbody td {
				border: none;
			}
		}
	}
}
