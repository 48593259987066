@import "misc-commun.scss";
@import "portail.scss";


//DEFAULT VALUES
$color-fg: $color-blanc;
$color-bg: $color-bleu-fonce;
$color-section-bg: $color-bleu-pale;

//THEME DEFAULT FOR PORTAIL WEBSITE
$color-theme-text-default: $color-fg;
$color-theme-background: $color-n1;

//MENU BACKGROUND
$color-menubg: $color-bleu-menu;

//FOOTER
$footer-bg-color: $color-bleu-pied;
$footer-border-color: $color-bleu-pied-border;
$footer-border-color-sport: rgb(28, 28, 28);
$m-footer-link-list-bg: $color-blanc;

//GROSLOTS
$color-lottomax-montant: #135FA7;
$color-lottomax-lots1million: #3b8632;
$color-649-montant: #e6292a;
$color-649-million-garanti: $color-649-montant;
$color-bouton-groupe: #506578;

// $gros-lot-montant-font: eausans_black,Verdana,Geneva,Tahoma,"Helvetica Neue",Helvetica,"Trebuchet MS","Lucida Grande","Lucida Sans Unicode","Lucida Sans",Arial,sans-serif;
// $gros-lot-details-font: function_probook,Helvetica,Arial,sans-serif;
// $gros-lot-details-font-strong: function_promedium,Helvetica,Arial,sans-serif;
$gros-lot-montant-font: latoblack,
Helvetica,
Arial,
sans-serif;
$gros-lot-details-font: latoregular,
Helvetica,
Arial,
sans-serif;
$gros-lot-details-font-strong: latoheavy,
Helvetica,
Arial,
sans-serif;