
/* En ordre ALPHABETIQUE */

.theme-bg-ced {
    @import "components/carousel";
    @import "components/footer";
    @import "components/media-bg-with-content";
	@import "components/rangee";
	
	main {
		background: #091A38;
	}
}

@import "components/section";