

h3 {
    font-family: $lato-font-medium;

    font-size: 4.4vw;

    line-height: 3rem;
    width: 100%;

    @include media-breakpoint-up(md) {
        font-size: 2rem;
    }
    
    @include media-breakpoint-up(lg) {
        font-size: 3.3rem;
    }
}

.media-text {
    padding-top: 10.6666666667vw;
    padding-bottom: 10.6666666667vw;
    text-align: left;
        
    @include media-breakpoint-up(md) {
        padding-left: 2.1rem;
        padding-right: 2.1rem;
    }
    
    @include media-breakpoint-up(lg) {
        padding-left: 3.5rem;
        padding-right: 3.5rem;
    }
    
    @include media-breakpoint-up(md) {
        padding-top: 4.8rem;
        padding-bottom: 4.8rem;
    }
    
    @include media-breakpoint-up(lg) {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    &.no-vertical-padding {
        padding-top: 0;
        padding-bottom: 0;
    }

    .image-title {
        img {
            max-height: 38vw;

            max-width: 46.6666666667vw;

            @include media-breakpoint-up(md) {
                max-width: 18rem;
            }
            
            @include media-breakpoint-up(lg) {
                max-width: 20rem;
            }

            + h2 {
                margin-top: 8vw;
            }
        }

        + .h3 {
            margin-top: 4.66667vw;
        }
    }

    h2 {
        display: block;

        + h3,
        + .rich-text {
            margin-top: 12.4vw;
        }
    }

    .under-title-text {
        line-height: 1.25em;
        display: block;
        font-family: $theme-font-bold;

        margin-bottom: 1.3333333333vw;

        @include media-breakpoint-up(md) {
            margin-bottom: 0.6rem;
        }
        
        @include media-breakpoint-up(lg) {
            margin-bottom: 0.5rem;
        }
    }

    h3 {
        //font-family: $theme-font-medium;
    }

    .rich-text,
    &.rich-text {
        margin-top: 2vw;

        @include media-breakpoint-up(md) {
            margin-top: 1rem;
        }

        p {
            margin-bottom: 2.6666666667vw;
            font-size: 4.8vw;
            line-height: 1.25em;

            @include media-breakpoint-up(md) {
                margin-bottom: 1.2rem;
            }
            
            @include media-breakpoint-up(lg) {
                margin-bottom: 1rem;
            }

            &:last-child,
            &:only-child {
                margin-bottom: 0;
            }

        }

        a {
            white-space: nowrap;
        }

        img {
            height: auto !important;
        }

        .no-wrap {
            white-space: nowrap;
        }

        ol,
        ul {
            padding-left: 1.25em;

            li {
                font-size: 4.8vw;
                line-height: 1.5em;
                @include media-breakpoint-up(md) {
                    font-size: 1.6rem;
                }

            }
        }

        ol {
            li {
                list-style-type: decimal;
            }
        }

        ul {
            li {
                list-style-type: disc;
            }
        }

        .valeur {
            font-family: $theme-font-medium;

            font-size: 4.8vw;

            @include media-breakpoint-up(md) {
                font-size: 1.6rem;
            }
        }

        &.cke_editable {
            // always display .show-for-sr in ckeditor
            .show-for-sr {
				position: static !important;
				height: auto;
				width: auto;
				overflow: visible;
				clip: auto;
				
                &:before {
                    content: "[sr:";
                }

                &:after {
                    content: "]";
                }
            }
        }
    }

    .link-list {
        li {
            &:first-child a:not(.btn) {
                margin-top: 8.13333vw;
            }

            a:not(.btn) {
                margin-top: 1.6vw;
                display: inline-block;
                position: relative;
            }
        }
    }

    .keyword-list {
        margin: 8.13333vw 0 0;

        li {
            display: inline-block;
            padding-left: 6.26667vw;
            position: relative;

            &:before {
                position: absolute;
                content: "";
                top: 3.6vw;
                left: 2.13333vw;
                width: 0.45em;
                height: 0.45em;

                transform: rotate(45);

                background-color: $color-theme-text-alternative;
            }

            &:first-child {
                padding-left: 0;

                &:before {
                    background-color: transparent;
                }
            }
        }
    }

    .ta-left {
        h2:after {
            left: 0;
            right: auto;
        }
    }

    .ta-right {
        h2:after {
            right: 0;
            left: auto;
        }

        .rich-text,
        &.rich-text {
            ol,
            ul {
                padding-left: 0;

                li {
                    list-style-position: inside;
                }
            }
        }
    }

    .ta-center {
        h2:after {
            right: 0;
            left: 0;
            margin: 0 auto;
        }

        .rich-text,
        &.rich-text {
            ol,
            ul {
                padding-left: 0;

                li {
                    list-style-position: inside;
                }
            }
        }
    }

    .tile-inner {
        background: transparent none;
        margin: 0;
    }

    &.vertical-align {
        .tile-inner {
            top: 50%;

            transform: translateY(-50%);
        }
    }
}

@include media-breakpoint-up(md) {
    .media-text {
        padding-left: 3.96rem;
    	padding-right: 3.96rem;

        .image-title {
            img {
                max-height: none;

                + h2 {
                    margin-top: 3.6rem;
                }
            }

            + .h3 {
                margin-top: 2.1rem;
            }

            + .rich-text {
                margin-top: 1.5rem;
            }
        }

        h2 {
            + h3,
            + .rich-text {
                margin-top: 2.1rem;
            }
        }

        .link-list {
            li {
                &:first-child a:not(.btn) {
                    margin-top: 3.66rem;
                }

                a:not(.btn) {
                    margin-top: 0.72rem;
                }
            }
        }

        .keyword-list {
            margin: 3.66rem 0 0;

            li {
                padding-left: 2.82rem;

                &:before {
                    top: 2.16vw;
                    left: 1.28vw;
                    width: 0.45em;
                    height: 0.45em;

                    transform: rotate(45);
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .media-text {
        padding-left: 10%;
        padding-right: 10%;

        .image-title {
            img {
                + h2 {
                    margin-top: 2.5rem;
                }
            }

            + h3 {
                margin-top: 1.5rem;
            }

            + .rich-text {
                margin-top: 2.5rem;
            }
        }

        h2 {
            + h3,
            + .rich-text {
                margin-top: 2rem;
            }
        }

        .link-list {
            li {
                &:first-child a:not(.btn) {
                    margin-top: 2.6rem;
                }

                a:not(.btn) {
                    margin-top: 0.5rem;
                }
            }
        }

        .keyword-list {
            margin: 2.6rem 0 0;

            li {
                padding-left: 2rem;

                &:before {
                    top: 0.7rem;
                    left: 0.7rem;
                    width: 0.6rem;
                    height: 0.6rem;
                }
            }
        }
    }
}

.bloc-texte .media-text,
.main > .row.content-container:not(.multi-formats) > .columns > .media-text,
section > .row:not(.multi-formats) > .columns > .media-text {
    padding-left: 2.6666666667vw;
    padding-right: 2.6666666667vw;

    @include media-breakpoint-up(lg) {
        padding-left: 0;
        padding-right: 0;
    }
}

// fin commun

.theme-1 .media-text .rich-text p,
.theme-1 .media-text .rich-text ul,
.theme-1 .media-text .rich-text ol,
.theme-1 .media-text .rich-text li,
.theme-bg-bleu-marin .media-text .rich-text p,
.theme-bg-bleu-marin .media-text .rich-text ul,
.theme-bg-bleu-marin .media-text .rich-text ol,
.theme-bg-bleu-marin .media-text .rich-text li,
.theme-4 .media-text .rich-text p,
.theme-4 .media-text .rich-text ul,
.theme-4 .media-text .rich-text ol,
.theme-4 .media-text .rich-text li,
.theme-bg-bourgogne .media-text .rich-text p,
.theme-bg-bourgogne .media-text .rich-text ul,
.theme-bg-bourgogne .media-text .rich-text ol,
.theme-bg-bourgogne .media-text .rich-text li,
.theme-6 .media-text .rich-text p,
.theme-6 .media-text .rich-text ul,
.theme-6 .media-text .rich-text ol,
.theme-6 .media-text .rich-text li,
.theme-bg-turquoise .media-text .rich-text p,
.theme-bg-turquoise .media-text .rich-text ul,
.theme-bg-turquoise .media-text .rich-text ol,
.theme-bg-turquoise .media-text .rich-text li {
    color: $color-blanc;
}

.theme-5 .media-text .rich-text p,
.theme-5 .media-text .rich-text ul,
.theme-5 .media-text .rich-text ol,
.theme-5 .media-text .rich-text li,
.theme-bg-blanc .media-text .rich-text p,
.theme-bg-blanc .media-text .rich-text ul,
.theme-bg-blanc .media-text .rich-text ol,
.theme-bg-blanc .media-text .rich-text li {
    color: $color-noir;
}

// @include page-theme-colors {
//     .media-text {
//         .image-title {
//             h2 {
//                 font-family: $color-theme-font-bold;
                
//                 &.light {
//                 	font-family: $color-theme-font-light;	
//                 }
//             }
//         }
//     }
// }


.media-text {
    .theme-bg-sports & {
        padding-bottom: 5.3333333333vw;
        padding-top: 4vw;
                
        @include media-breakpoint-up(md) {
            padding-bottom: 2.4rem;
        }
        
        @include media-breakpoint-up(lg) {
            padding-bottom: 3.5rem;
        }
        
        @include media-breakpoint-up(md) {
            padding-top: 1.8rem;
        }
        
        @include media-breakpoint-up(lg) {
            padding-top: 1.5rem;
        }
    }

    .image-title {
        h2 {
            margin-top: 10.6666666667vw;
            font-size: 6.6666666667vw;
            line-height: 7.7333333333vw;

            @include media-breakpoint-down(sm) {
                text-transform: none;
            }

                        
            @include media-breakpoint-up(md) {
                margin-top: 4.8rem;
            }
            
            @include media-breakpoint-up(lg) {
                margin-top: 4rem;
            }
            
            @include media-breakpoint-up(md) {
                font-size: 1.5rem;
            }
            
            @include media-breakpoint-up(lg) {
                font-size: 3.3rem;
            }
            
            @include media-breakpoint-up(md) {
                line-height: 3.48rem;
            }
            
            @include media-breakpoint-up(lg) {
                line-height: 4.2rem;
            }
  


            &:after {
                display: none;

                            
                @include media-breakpoint-up(md) {
                    bottom: -4vw;
                    width: 10vw;
                }
                
                @media (min-width: 768px) and (min-width: 768px) {
                    bottom: -1.8rem;
                }
                
                @media (min-width: 768px) and (min-width: 992px) {
                    bottom: -1.5rem;
                }
            }

            span.under-title-text {
                text-transform: none;
            }

            .theme-bg-sports & {
                text-transform: none;
                margin-top: 0vw;
                font-size: 4vw;
                line-height: 4.4vw;

                                
                @include media-breakpoint-up(md) {
                    margin-top: 0;
                }
                
                @include media-breakpoint-up(lg) {
                    margin-top: 0;
                }
                
                @include media-breakpoint-up(md) {
                    font-size: 1.8rem;
                }
                
                @include media-breakpoint-up(lg) {
                    font-size: 2rem;
                }
                
                @include media-breakpoint-up(md) {
                    line-height: 1.98rem;
                }
                
                @include media-breakpoint-up(lg) {
                    line-height: 2.4rem;
                }
            }
        }

        ~ .h3,
        ~ .rich-text {
            @include media-breakpoint-down(sm) {
                margin: 5px 0 0;
            }
        }
    }

    .rich-text,
    &.rich-text {
        p {
            font-size: 3.7333333333vw;

            @include media-breakpoint-up(md) {
                font-size: 1.44rem;
            }
            
            @include media-breakpoint-up(lg) {
                font-size: 2rem;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .theme-bg-sports {
        .media-text {
            padding-left: 4%;
            padding-right: 4%;
        }
    }
}

// @include div-theme-colors {
//     background-color: $color-theme-bg;
//     color: $color-theme-fg;
//     fill: $color-theme-fg-secondary;

//     a:not(.btn) {
//         color: $color-theme-link;
//     }

//     p {
//         color: $color-theme-text;
//     }

//     h3,
//     .h3,
//     .titre2 {
//         color: $color-theme-text;
//     }

//     h2,
//     h3.h2 {
//         color: $color-theme-fg;

//         &:after {
//             color: $color-theme-fg;
//         }
//     }

//     .btn {
//         background-color: $color-theme-fg;
//         color: $color-theme-fg;
//         border-color: $color-theme-fg;
//         text-decoration: none;
//         margin-bottom: 0;
//         border-style: solid;
//         box-sizing: border-box;

//         &:hover {
//             background-color: $color-theme-fg;
//             border-color: $color-theme-fg;
//             color: $color-theme-bg;
//         }

//         &.opaque {
//             border-style: solid;
//             box-sizing: border-box;
//             background-color: $color-theme-bg-secondary;
//             color: $color-theme-fg-secondary;
//             border-color: $color-theme-bg-secondary;
//             text-decoration: none;

//             &:hover {
//                 background-color: transparent;
//                 border-color: $color-theme-fg;
//                 color: $color-theme-fg;
//             }
//         }
//     }

//     .media-icon {
//         .icon-wrapper {
//             svg {
//                 use {
//                     fill: $color-g1;
//                 }
//             }
//         }
//     }

//     .media-text {
//         .image-title {
//             h2 {
//                 color: $color-theme-fg;

//                 .under-title-text {
//                     color: $color-theme-fg;
//                     font-family: $color-theme-font-light;
//                     background: none;
//                 }

//                 &:after {
//                     background-color: $color-theme-fg;
//                 }
//             }
//         }

//         h3,
//         .h3 {
//             color: $color-theme-fg;
//             font-family: $theme-font-medium;
//             text-transform: uppercase;
//         }

//         .rich-text {
//             p,
//             ul li,
//             ol li {
//                 //color: $color-theme-fg;
//                 color: $color-theme-text;
//             }

//             a:not(.btn) {
//                 color: $color-theme-link;
//                 text-decoration: underline;

//                 &:hover {
//                     color: $color-theme-link-hover;
//                 }
//             }
//         }
//     }
// }



.media-image-with-text-below {
    .media-text {
        h2 {
            margin-top: 0px;
        }
    }
}

div.theme-bg-noir {
	.btn.opaque {
		color: #fff;
		text-decoration: none;
	}

	.media-text .image-title h2 {
		color: #fff;
	}
}

main.theme-bg-casino,
main.theme-bg-ced {
	.media-text .image-title h2 {
		font-family: "graphik_bold",Arial,Helvetica,sans-serif;
	}
}