//THEME DEFAULT
$color-theme-text-default: #fff;
$color-theme-text-alternative: #8C8C8C;
$color-theme-background: #000;

//THEME ALTERNATIF
$color-theme-alt-text-default: #000;
$color-theme-alt-text-alternative: #454545;
$color-theme-alt-background: #fff;
$color-theme-background-opacity-light: rgba(0,0,0,.85);
$color-theme-background-opacity-verylight: rgba(0,0,0,.50);

//FORM ELEMENTS
$color-btn-default: $color-theme-text-default;
$color-btn-default-bg: $color-theme-background;

//TEXT-BOX ELEMENTS
$color-tile-bg: transparent !default;

//FORM ELEMENTS
$color-input-default: $color-theme-alt-text-default;

//MENU BACKGROUND WHEN SCROLLING OR IN OTHER PAGES
$color-menubg: #000 !default;
$color-menubg-onscroll: #000 !default;
$color-menubg-otherpages: rgba(0,0,0,.85) !default;

//CAROUSEL ITEMS
$color-carousel-dots: #9a9a9a !default;
$color-carousel-dots-active: #0061d4 !default;

//FOUNDATION
$column-gutter: 0px !default;

//FOOTER
$footer-border-color: #333333 !default;
$m-footer-active-button: #1b1b1b;
$m-footer-link-list-bg: #111111;
$m-footer-link-list-bg-sports: #111111;
$footer-bg-color: #000;
$footer-bg-color-sports: #1b1b1b;

// JACKPOTS BAR
$color-jackpots-bg: #111111;
