.offers-component-wrapper {
	> h2 {
		font-family: 'graphik_regular', Arial, Helvetica, sans-serif;
		font-size: 1.3rem;
		margin: 1.5rem auto 1.3rem auto;
		
		@include media-breakpoint-up(md) {
			font-size: 3.21428571rem;
    		margin: 4.28571429rem auto 3.21428571rem auto;
		}
	}
}