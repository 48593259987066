
// Colors map ajouts //////////////////////
$dark-moj : #15232C !default;
$light-moj: #e3e3e3 !default;
$red-moj  : #FF0D2D !default;

$theme-colors: (
  "dark-moj" : $dark-moj,
  "light-moj": $light-moj,
  "red-moj"  : $red-moj
);

// Coins arrondis /////////////////////////
$enable-rounded : false;

// Responsive font sizes //////////////////
$paragraph-margin-bottom:   0.5rem;

// YIQ color contrast function ////////////
$yiq-text-dark:             $dark-moj;
$yiq-text-light:            $light-moj;

// Carrousel //////////////////////////////
$carousel-control-opacity:      .66;
$carousel-control-icon-width:   33px;
$carousel-indicator-height:     5px;

///////////////////////////////////////////
///// Global SASS  ////////////////////////
/////////////////////////////////////////// 
@import url('https://fonts.googleapis.com/css?family=Graduate&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Rubik:500&display=swap');


//Modal
// $close-color: $white !default;
// $close-text-shadow:  0 0px 0 $white !default;
  
//Font management

//Mixins
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media screen and (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media screen and (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@mixin horizontal-flip-div{
  transform:scaleX(-1);
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}