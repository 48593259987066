// debut commun

.media-bg-with-content {
	position: relative;
	background: transparent none repeat center center;

	min-height: 66.6666666667vw;

	&.cover,
	&.cover.row.content-container {
		background: transparent none no-repeat center center;
		background-size: cover;

		.yt-ambiance-video {
			background-size: cover;
		}
	}
	.media-video {
		margin: 0 auto;

		.yt-ambiance-video .yt-play-pause {
			z-index: 5;
		}
	}
	.yt-ambiance-video {
		//display: none;
		.cta-cover-link {
			display: none;
		}
	}

	.heading-content {
		clip: rect(1px, 1px, 1px, 1px);
		height: 1px;
		overflow: hidden;
		position: absolute;
		width: 1px;

		.media-text .image-title + .h3 {
			margin-top: 6.6666666667vw;

			@include media-breakpoint-up(md) {
				margin-top: 3rem;
			}

			@include media-breakpoint-up(lg) {
				margin-top: 2rem;
			}
		}

		h2 {
			font-size: 8vw;

			@include media-breakpoint-up(md) {
				font-size: 3.6rem;
			}

			@include media-breakpoint-up(lg) {
				font-size: 5rem;
			}
		}

		.media-text {
			padding: 2.6666666667vw;

			@include media-breakpoint-up(md) {
				padding: 3rem;
			}

			@include media-breakpoint-up(lg) {
				padding: 5rem;
			}
		}
	}

	.btn-play-pause {
		display: none;
		position: absolute;
		z-index: 2;
		top: 15px;
		left: 15px;
		width: 32px;
		line-height: 32px;
		border-radius: 50%;
		border: 0 none;
		background-color: rgba(0, 0, 0, 0.5);
		text-indent: -1000px;
		transition: all 0.3s;
		opacity: 0;

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			height: 15px;
			width: 5px;
			z-index: 2;
			text-indent: 0;
			background-color: $color-w1;
			transform: translate(-140%, -50%);
			transition: top left border-width width height 0.3s;
		}
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			height: 15px;
			width: 5px;
			z-index: 2;
			text-indent: 0;
			border: 0 none;
			background-color: $color-w1;
			transform: translate(40%, -50%);
			transition: top left border-width width height 0.3s;
		}
		&.paused:before {
			height: 0;
			width: 0;
			opacity: 0;
		}
		&.paused:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			height: 0;
			border: transparent solid 9px;
			z-index: 2;
			text-indent: 0;
			border-left-color: $color-w1;
			border-left-width: 12px;
			background-color: transparent;
			transform: translate(-20%, -50%);
		}
	}
	.mobile-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		max-width: 1280px;
		margin-left: auto;
		margin-right: auto;
		vertical-align: middle;
		padding-top: 10.6666666667vw;
		padding-bottom: 10.6666666667vw;
	}
}

@include media-breakpoint-up(md) {
	.media-bg-with-content {
		overflow: hidden;
		width: 100%;
		height: 400px;
		min-height: 0;
		display: block;
		&.large {
			height: 600px;
		}

		.heading-content {
			position: static;
			clip: auto;
			height: auto;
			overflow: visible;

			max-width: 1280px;
			margin-left: auto;
			margin-right: auto;
			width: 100%;

			.media-component {
				opacity: 0;
			}

			// if there's only 1 media-component sibling, keep opacity to 1
			.media-component:first-child:nth-last-child(1) {
				opacity: 1;
			}
		}

		.heading-content {
			text-align: left;
			height: 100%;
			.row {
				height: 100%;
				.columns {
					position: relative;
					.media-text {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
		}
		.media-video {
			display: block;
			width: 100%;
			position: absolute;
		}

		.yt-ambiance-video {
			display: block;
		}

		&:hover .btn-play-pause {
			opacity: 0.5;
		}

		.btn-play-pause {
			display: block;
			opacity: 0;

			&:hover,
			&:focus {
				opacity: 1;
			}
		}

		.animation-slide {
			position: absolute;
		}
	}
	.magnolia-editor .media-bg-with-content {
		.animation-slide {
			opacity: 1;
		}
		.heading-content {
			.media-component {
				opacity: 1;
			}
			.row .columns .media-text {
				position: static;
				top: auto;
				transform: none;
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.media-bg-with-content {
		height: 500px;
		&.large {
			height: 720px;
		}
	}
}
@include media-breakpoint-up(xxxl) {
	.media-bg-with-content {
		height: 600px;
		&.large {
			height: 820px;
		}
	}
}

.magnolia-editor {
	.media-bg-with-content {
		height: auto;

		.heading-content {
			top: 2em;
			//transform: translateX(-50%);

			& > * {
				position: static;
				opacity: 1;
				padding-bottom: 1em;
				border-bottom: 1px dashed rgba(200, 200, 200, 0.5);
				margin-bottom: 1em;
			}
		}

		.mobile-content {
			clear: both;
			position: relative;
			display: block;
			width: 360px;
			margin: 2em auto;
			padding: 3.5em 2em 2em;
			border: 1px dashed rgba(200, 200, 200, 0.5);

			&:before {
				content: 'Mobile text';
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				background: rgba(200, 200, 200, 0.2);
				width: 100%;
			}
		}
	}
}
html[lang='fr'] .magnolia-editor .media-bg-with-content .mobile-content:before {
	content: 'Texte pour mobile';
}

// fin commun

.media-bg-with-content {
	&.cover,
	&.row.content-container.cover {
		background-position: 50% 50%;
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
	}

	.heading-content {
		.media-text {
			padding-left: 0;
		}
	}
}

// @include components-box-colors;

main {
	.deux-backgrounds {
		.columns {
			.row {
				.columns {
					padding: 0px;
					margin: 0px;
					margin-right: -0.3rem;

					.media-image-with-text-below {
						.media-text {
							.content-inner {
								h2 {
									margin-bottom: 0rem;
								}

								h3 {
									margin-top: 0rem;

									@include media-breakpoint-only(lg) {
										font-size: 1.6vw;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.youtube-container {
  position: relative;
  height: 100%;
}

.youtube-container > iframe {
  display:block;
  z-index:2;
}

.youtube-container > .video-consent-overlay {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background-color: var(--brk-dec-color-main-extradark);
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing:border-box;
  color: white;
  z-index:2;
}

.youtube-container .video-consent-overlay .bg-image {
	position: absolute;
	height: auto;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	opacity: 0.2;
	z-index: -1 !important;
}

.youtube-container .video-consent-overlay .scrollable-section {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #90a4ae #cfd8dc;
  height: fit-content;
  margin: 6px 18px !important;
  @include media-breakpoint-up(md){
    margin: 12px 18px !important;
  }
}

.youtube-container .video-consent-overlay .scrollable-section .logo {
  @include media-breakpoint-up(lg) {
    margin-left: 0px;
    margin-top: 16px;
  }
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
  height: 23px;
  width: 102px;
  
  svg {
    height: 100%;
    width: 100%;
  }
}

.video-consent-overlay-logo {
  height: 23px;
  width: 102px;
  
  svg {
    height: 100%;
    width: 100%;
  }
}

.youtube-container .video-consent-overlay .scrollable-section::-webkit-scrollbar-track {
  background: #cfd8dc;
}

.youtube-container .video-consent-overlay .scrollable-section::-webkit-scrollbar-thumb {
  background-color: #90a4ae;
  border-radius: 6px;
  border: 2px solid #cfd8dc;
}

.youtube-container .video-consent-overlay .scrollable-section::-webkit-scrollbar {
  width: 7px;
}

.youtube-container .video-consent-overlay .scrollable-section .video-consent-overlay-titre h3 {
  --ratio: var(--brk-text-ratio-sm);
  @include media-breakpoint-up(md) {
    --ratio: var(--brk-text-ratio-md);
  }
  @include media-breakpoint-up(lg) {
    --ratio: var(--brk-text-ratio-lg);
  }
  color: var(--brk-text-inverted-body-base-font-color);
  font-family: graphik_semibold, Arial, Helvetica, sans-serif;
  font-size: calc(var(--brk-text-heading-3-font-size)/var(--ratio));
  font-weight: var(--brk-text-heading-3-font-weight);
  letter-spacing: var(--brk-text-heading-3-letter-spacing);
  line-height: var(--brk-text-heading-3-line-height);
  text-align: left;
  text-decoration: var(--brk-text-heading-3-text-decoration);
  text-transform: var(--brk-text-heading-3-text-transform);
}

.youtube-container .video-consent-overlay .scrollable-section .video-consent-overlay-text p {
  --ratio: var(--brk-text-ratio-sm);
  @include media-breakpoint-up(md) {
    --ratio: var(--brk-text-ratio-md);
  }
  @include media-breakpoint-up(lg) {
    --ratio: var(--brk-text-ratio-lg);
  }
  color: var(--brk-text-inverted-body-base-font-color);
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size: calc(var(--brk-text-body-base-font-size)/var(--ratio));
  font-weight: var(--brk-text-body-base-font-weight);
  letter-spacing: var(--brk-text-body-base-letter-spacing);
  line-height: var(--brk-text-body-base-line-height);
  text-align: left;
  text-decoration: var(--brk-text-body-base-text-decoration);
  text-transform: var(--brk-text-body-base-text-transform);
}

.youtube-container .video-consent-overlay-accept-button {
  &:hover {
    background-color: var(--brk-button-primary-inverted-background-color-hover);
    border-color: var(--brk-button-primary-inverted-border-color-hover);
    color: var(--brk-button-primary-inverted-font-color-hover);
    text-decoration: var(--brk-button-primary-text-decoration-hover);
  }
  &:focus-visible {
    background-color: var(--brk-button-primary-inverted-background-color-focus);
    border-color: var(--brk-button-primary-inverted-border-color-focus);
    outline-color: var(--brk-interactive-inverted-outline-color-focus);
    text-decoration: var(--brk-button-primary-text-decoration-focus);
  }  
  margin: 6px auto 6px auto !important;
  @include media-breakpoint-up(lg) {
    margin: 12px auto 12px 18px !important;
  }
  min-height: 30px;
  position: sticky;
  align-items: center;
  background-color: var(--brk-button-primary-inverted-background-color-default);
  border: var(--brk-button-primary-border-width) solid var(--brk-button-primary-inverted-border-color-default);
  border-radius: var(--brk-button-primary-border-radius);
  box-sizing: border-box;
  color: var(--brk-button-primary-inverted-font-color-default);
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  font-family: var(--brk-button-primary-font-family);
  font-size: calc(var(--brk-text-heading-3-font-size)/var(--ratio));
  font-weight: var(--brk-button-primary-font-weight);
  height: var(--brk-button-primary-height);
  justify-content: center;
  letter-spacing: var(--brk-button-primary-letter-spacing);
  line-height: var(--brk-button-primary-line-height);
  outline: var(--brk-interactive-outline-width) solid transparent;
  outline-offset: var(--brk-interactive-outline-offset);
  padding: 0 var(--brk-button-primary-padding-horizontal);
  text-decoration: var(--brk-button-primary-text-decoration-default);
  text-transform: var(--brk-button-primary-text-transform);
}

.bouton-fonce {
  &:hover {
	  background-color: var(--brk-button-primary-standard-background-color-hover) !important;
	  border-color: var(--brk-button-primary-standard-border-color-hover) !important;
	  color: var(--brk-button-primary-standard-font-color-hover) !important;
  }
	&:focus-visible{
		background-color: var(--brk-button-primary-standard-background-color-focus) !important;
		border-color: var(--brk-button-primary-standard-border-color-focus) !important;
		outline-color: var(--brk-interactive-standard-outline-color-focus) !important;
	}
	background-color: var(--brk-button-primary-standard-background-color-default) !important;
	border: var(--brk-button-primary-border-width) solid var(--brk-button-primary-standard-border-color-default) !important;
	color: var(--brk-button-primary-standard-font-color-default) !important;
}

.bouton-clair {
	&:hover {
		background-color: var(--brk-button-primary-inverted-background-color-hover) !important;
		border-color: var(--brk-button-primary-inverted-border-color-hover) !important;
		color: var(--brk-button-primary-inverted-font-color-hover) !important;
	}
	&:focus-visible {
		background-color: var(--brk-button-primary-inverted-background-color-focus) !important;
		border-color: var(--brk-button-primary-inverted-border-color-focus) !important;
		outline-color: var(--brk-interactive-inverted-outline-color-focus) !important;
	}
	background-color: var(--brk-button-primary-inverted-background-color-default) !important;
	border: var(--brk-button-primary-border-width) solid var(--brk-button-primary-inverted-border-color-default) !important;
	color: var(--brk-button-primary-inverted-font-color-default) !important;
}

