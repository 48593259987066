// debut commun

.media-icon {

  & + .media-text {
    margin-bottom: 10vw;
    padding-top: 0vw;
    padding-bottom: 0vw;

        
    @include media-breakpoint-up(md) {
        margin-bottom: 3rem;
      }

    @include media-breakpoint-up(lg) {
        margin-bottom: 2.5rem;
      }

    @include media-breakpoint-up(md) {
        padding-top: 0;
      }

    @include media-breakpoint-up(lg) {
        padding-top: 0;
      }

    @include media-breakpoint-up(md) {
        padding-bottom: 0;
      }

    @include media-breakpoint-up(lg) {
        padding-bottom: 0;
      }

    .image-title {
      margin-top: 0vw;

      @include media-breakpoint-up(md) {
        margin-top: 1.2rem;
      }
      
      @include media-breakpoint-up(lg) {
        margin-top: 2rem;
      }
      img {
        display: none;
      }
    }
    h2, .h2 {
      font-family: $theme-font-bold;
      text-transform: none;
      font-size: 4.8vw;
      margin-top: 0vw;
            
      @include media-breakpoint-up(md) {
          font-size: 1.68rem;
      }

      @include media-breakpoint-up(lg) {
          font-size: 1.8rem;
      }

      @include media-breakpoint-up(md) {
          margin-top: 0;
      }

      @include media-breakpoint-up(lg) {
          margin-top: 0;
      }
    }
    h3, .h3, .subtitle {
      font-size: 4.8vw;
      line-height: 1.25em;
      @include media-breakpoint-up(md) {
          font-size: 1.44rem;
      }

      @include media-breakpoint-up(lg) {
          font-size: 1.6rem;
      }

    }
    .rich-text, &.rich-text {
      * {
          font-size: 4.8vw;

        @include media-breakpoint-up(md) {
            font-size: 1.44rem;
        }

        @include media-breakpoint-up(lg) {
            font-size: 1.6rem;
        }

        &:not(.btn) {
          line-height: 1.25em;
          //color: $color-theme-text-alternative;
        }
      }
      .btn {
        margin-top: 3.3333333333vw;
        @include media-breakpoint-up(md) {
          margin-top: 1.5rem;
        }

      }
    }

  }

  &.media-icon-left {
    display: block;
    float: left;
    width: auto;
    padding: 0;

    .icon-wrapper {
      height: 13.3333333333vw;
      width: 13.3333333333vw;
      box-sizing: content-box;
            
      @include media-breakpoint-up(md) {
          height: 6rem;
      }

      @include media-breakpoint-up(lg) {
          height: 6rem;
      }

      @include media-breakpoint-up(md) {
          width: 6rem;
      }

      @include media-breakpoint-up(lg) {
          width: 6rem;
      }

      svg {
        max-width: 13.3333333333vw;

        @include media-breakpoint-up(md) {
          max-width: 6rem;
        }
      }
    }

    .content {
      margin-top: 0;

      .icon-wrapper {
        padding-left: 2.6666666667vw;
        padding-right: 5.3333333333vw;

        @include media-breakpoint-up(md) {
          padding-left: 1.2rem;
          padding-right: 1.2rem;
        }
        
        @include media-breakpoint-up(lg) {
          padding-left: 2rem;
         padding-right: 2rem;
        }
      }
    }

    + .media-text {
      margin-bottom: 20vw;

      @include media-breakpoint-up(md) {
        margin-bottom: 6rem;
      }
      
      @include media-breakpoint-up(lg) {
        margin-bottom: 5rem;
      }
      .image-title, h2, .h2 {
        font-size: 4.8vw;
        font-family: $theme-font-regular;
        margin-top: 0;
        padding-top: 0;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
          font-size: 2.1rem;
        }
        
        @include media-breakpoint-up(lg) {
          font-size: 2rem;
        }
      }
      h3, .h3, .subtitle {
        font-size: 3.2vw;

        @include media-breakpoint-up(md) {
          font-size: 1.4rem;
        }

      }
    }

  }

}

// fin commun

.media-icon {
  & + .media-text {
    .image-title {
      h2 {
        &:after {}
      }

      + .h3, + .rich-text {}

      &.nounderline {
        + .h3 {
          margin-top: 1.0666666667vw;

          @include media-breakpoint-up(md) {
            margin-top: 0.5rem;
          }
        }

        + .rich-text {
          margin-top: 2.6666666667vw;

          @include media-breakpoint-up(md) {
            margin-top: 1.2rem;
          }

          @include media-breakpoint-up(lg) {
            margin-top: 1rem;
          }
        }
      }
    }

    .rich-text *, &.rich-text * {
      font-size: 3.7333333333vw;

      @include media-breakpoint-up(md) {
        font-size: 1.4rem;
      }
    }
  }


  &.media-icon-left {
    & + .media-text {
      .image-title {
        h2 {
          font-family: $theme-font-bold;
          &.light {
            font-family: $theme-font-light;
          }
        }
      }
    }
  }

}


.media-icon.large:not(.media-icon-left) {
  .icon-wrapper {
    height: 40vw;

    @include media-breakpoint-up(md) {
      height: 15rem;
    }
    
    @include media-breakpoint-up(lg) {
      height: 13.3rem;
    }

    svg {
      max-width: 40vw;

      @include media-breakpoint-up(md) {
        max-width: 15rem;
      }
      
      @include media-breakpoint-up(lg) {
        max-width: 13.3rem;
      }
    }
  }
}

.media-icon.large:not(.media-icon-left) .icon-wrapper {
  @include media-breakpoint-up(xxl) {
      height: 48vw;
  }

  @media (min-width: 1440px) and (min-width: 768px) {
      height: 18rem;
  }

  @media (min-width: 1440px) and (min-width: 992px) {
      height: 13.3rem;
  }

  svg {
    @include media-breakpoint-up(xxl) {
        max-width: 48vw;
    }

    @media (min-width: 1440px) and (min-width: 768px) {
        max-width: 18rem;
    }

    @media (min-width: 1440px) and (min-width: 992px) {
        max-width: 13.3rem;
    }
  }
}