.youtube-container{
  position: relative;
  height: 100%;
}

.youtube-container .video-consent-overlay .bg-image {
  position: absolute;
  height: auto;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.2;
  z-index: -1 !important;
}

.youtube-container > iframe{
  display:block;
}

.youtube-container > .video-consent-overlay{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background-color: var(--brk-dec-color-main-extradark);
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: white;
}


.youtube-container .video-consent-overlay .scrollable-section{
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #90a4ae #cfd8dc;
	height: fit-content;
	margin: 6px 18px;
	@include media-breakpoint-up(md){
		margin: 12px 48px;
	}
}

.youtube-container .video-consent-overlay .scrollable-section .logo{
  @include media-breakpoint-up(lg){
    margin-left: 0px;
    margin-top: 16px;
  }
  margin-left: auto;
  margin-right: auto;
	margin-top: 12px;
	margin-bottom: 12px;
  height: 23px;
  width: 102px;

  svg {
      height: 100%;
      width: 100%;
  }
}

.video-consent-overlay-logo{
  height: 23px;
  width: 102px;

  svg {
    height: 100%;
    width: 100%;
  }
}


.youtube-container .video-consent-overlay .scrollable-section::-webkit-scrollbar-track{
  background: #cfd8dc;
}

.youtube-container .video-consent-overlay .scrollable-section::-webkit-scrollbar-thumb{
  background-color: #90a4ae;
  border-radius: 6px;
  border: 2px solid #cfd8dc;
}

.youtube-container .video-consent-overlay .scrollable-section::-webkit-scrollbar{
  width: 7px;
}

.youtube-container .video-consent-overlay .scrollable-section .video-consent-overlay-titre h3{
  --ratio: var(--brk-text-ratio-sm);
  // font-size: 11px;
  @include media-breakpoint-up(md){
    --ratio: var(--brk-text-ratio-md);
    // font-size: 16px;
  }
  @include media-breakpoint-up(lg){
    --ratio: var(--brk-text-ratio-lg);
  }
  color: var(--brk-text-inverted-body-base-font-color);
  font-family: graphik_semibold, Arial, Helvetica, sans-serif;
  font-size: calc(var(--brk-text-heading-3-font-size)/var(--ratio));
  font-weight: var(--brk-text-heading-3-font-weight);
  letter-spacing: var(--brk-text-heading-3-letter-spacing);
  line-height: var(--brk-text-heading-3-line-height);
  text-align: left;
  text-decoration: var(--brk-text-heading-3-text-decoration);
  text-transform: var(--brk-text-heading-3-text-transform);
}

.youtube-container .video-consent-overlay .scrollable-section .video-consent-overlay-text p{
  --ratio: var(--brk-text-ratio-sm);
  // font-size: 11px;
  @include media-breakpoint-up(md){
    --ratio: var(--brk-text-ratio-md);
    // font-size: 16px;
  }
  @include media-breakpoint-up(lg){
    --ratio: var(--brk-text-ratio-lg);
  }
  color: var(--brk-text-inverted-body-base-font-color);
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size: calc(var(--brk-text-body-base-font-size)/var(--ratio));
  font-weight: var(--brk-text-body-base-font-weight);
  letter-spacing: var(--brk-text-body-base-letter-spacing);
  line-height: var(--brk-text-body-base-line-height);
  text-align: left;
  text-decoration: var(--brk-text-body-base-text-decoration);
  text-transform: var(--brk-text-body-base-text-transform);
}

.youtube-container .video-consent-overlay-accept-button{
  --ratio: var(--brk-text-ratio-sm);
  @include media-breakpoint-up(md){
    --ratio: var(--brk-text-ratio-md);
  }
  @include media-breakpoint-up(lg){
    --ratio: var(--brk-text-ratio-lg);
  }
	&:hover {
		background-color: var(--brk-button-primary-inverted-background-color-hover);
		border-color: var(--brk-button-primary-inverted-border-color-hover);
		color: var(--brk-button-primary-inverted-font-color-hover);
		text-decoration: var(--brk-button-primary-text-decoration-hover);
	}
	&:focus-visible {
		background-color: var(--brk-button-primary-inverted-background-color-focus);
		border-color: var(--brk-button-primary-inverted-border-color-focus);
		outline-color: var(--brk-interactive-inverted-outline-color-focus);
		text-decoration: var(--brk-button-primary-text-decoration-focus);
	}
	background-color: var(--brk-button-primary-inverted-background-color-default);
	border: var(--brk-button-primary-border-width) solid var(--brk-button-primary-inverted-border-color-default);
	border-radius: var(--brk-button-primary-border-radius);
	box-sizing: border-box;
	color: var(--brk-button-primary-inverted-font-color-default);
	
  min-height: 30px;
  position: sticky;
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  font-family: var(--brk-button-primary-font-family);
  font-size: calc(var(--brk-text-heading-3-font-size)/var(--ratio));
  font-weight: var(--brk-button-primary-font-weight);
  height: var(--brk-button-primary-height);
  justify-content: center;
  letter-spacing: var(--brk-button-primary-letter-spacing);
  line-height: var(--brk-button-primary-line-height);
  outline: var(--brk-interactive-outline-width) solid transparent;
  outline-offset: var(--brk-interactive-outline-offset);
  padding: 0 var(--brk-button-primary-padding-horizontal);
  text-decoration: var(--brk-button-primary-text-decoration-default);
  text-transform: var(--brk-button-primary-text-transform);
  margin: 0 auto 12px auto;
  @include media-breakpoint-up(lg) {
    margin: 0 auto 0 48px;
  }
}

.button-fonce {
  &:hover {
	  background-color: var(--brk-button-primary-standard-background-color-hover) !important;
	  border-color: var(--brk-button-primary-standard-border-color-hover) !important;
	  color: var(--brk-button-primary-standard-font-color-hover) !important;
  }
	&:focus-visible{
		background-color: var(--brk-button-primary-standard-background-color-focus) !important;
		border-color: var(--brk-button-primary-standard-border-color-focus) !important;
		outline-color: var(--brk-interactive-standard-outline-color-focus) !important;
	}
	background-color: var(--brk-button-primary-standard-background-color-default) !important;
	border: var(--brk-button-primary-border-width) solid var(--brk-button-primary-standard-border-color-default) !important;
	color: var(--brk-button-primary-standard-font-color-default) !important;
}

.bouton-clair {
	&:hover {
		background-color: var(--brk-button-primary-inverted-background-color-hover) !important;
		border-color: var(--brk-button-primary-inverted-border-color-hover) !important;
		color: var(--brk-button-primary-inverted-font-color-hover) !important;
	}
	&:focus-visible {
		background-color: var(--brk-button-primary-inverted-background-color-focus) !important;
		border-color: var(--brk-button-primary-inverted-border-color-focus) !important;
		outline-color: var(--brk-interactive-inverted-outline-color-focus) !important;
	}
	background-color: var(--brk-button-primary-inverted-background-color-default) !important;
	border: var(--brk-button-primary-border-width) solid var(--brk-button-primary-inverted-border-color-default) !important;
	color: var(--brk-button-primary-inverted-font-color-default) !important;
}
	