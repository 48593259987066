.tile {
    .content {
        text-align: left;

        .tile-inner {
            .tile-text-block {
                background-color: transparent;
                position: absolute;
                z-index: 2;

                .over-title-text {
                    font-size: 2.8vw;
                    line-height: 3.7333333333vw;
                    text-transform: none;

                                        
                    @include media-breakpoint-up(md) {
                        font-size: 1.32rem;
                    }
                    
                    @include media-breakpoint-up(lg) {
                        font-size: 2rem;
                    }
                    
                    @include media-breakpoint-up(md) {
                        line-height: 1.5rem;
                    }
                    
                    @include media-breakpoint-up(lg) {
                        line-height: 1.8rem;
                    }
                }

                .under-title-text {
                    font-size: 3.4666666667vw;
                    line-height: 4.4vw;

                    text-transform: none;

                                        
                    @include media-breakpoint-up(md) {
                        font-size: 1.92rem;
                    }
                    
                    @include media-breakpoint-up(lg) {
                        font-size: 1.3rem;
                    }
                    
                    @include media-breakpoint-up(md) {
                        line-height: 1.92rem;
                    }
                    
                    @include media-breakpoint-up(lg) {
                        line-height: 2.6rem;
                    }
                }

                h3 {

                    font-size: 4.8vw;
                    line-height: 1.9;
                    width: 100%;

                    @include media-breakpoint-up(md) {
                        font-size: 2.88rem;
                    }
                    
                    @include media-breakpoint-up(lg) {
                        font-size: 3.4rem;
                    }
                }

                > span.btn {
                    display: block;
                    text-align: center;
                    font-size: 1rem;
                    padding: 0;
                    margin-top: 2.6666666667vw;
                    height: 4.5333333333vw;
                    line-height: 4.5333333333vw;
                    width: 17.3333333333vw;

                                        
                    @include media-breakpoint-up(md) {
                        margin-top: 1.2rem;
                    }
                    
                    @include media-breakpoint-up(lg) {
                        margin-top: 2rem;
                    }
                    
                    @include media-breakpoint-up(md) {
                        height: 2.04rem;
                    }
                    
                    @include media-breakpoint-up(lg) {
                        height: 3.4rem;
                    }
                    
                    @include media-breakpoint-up(md) {
                        line-height: 2.04rem;
                    }
                    
                    @include media-breakpoint-up(lg) {
                        line-height: 3.4rem;
                    }
                    
                    @include media-breakpoint-up(md) {
                        width: 7.8rem;
                    }
                    
                    @include media-breakpoint-up(lg) {
                        width: 13rem;
                    }

                    &:hover {
                        background-color: $color-section-bg;
                        border-color: $color-section-bg;
                        color: $color-theme-text-default;
                    }

                    &.opaque {
                        background-color: $color-blanc;
                        border-color: $color-blanc;
                        color: $color-noir;

                        &:hover {
                            background-color: transparent;
                            border-color: $color-blanc;
                            color: $color-blanc;
                        }
                    }
                }
            }
        }
    }
}

.tile.format42 {
    .tile-inner {
        .bg-img {
            height: 67%;
        }

        .tile-text-block {
            top: initial;
        }
    }

    .tile-sizer:after {
        margin-top: 16%;
    }
}

.tile.format22 {
    .content {
        .tile-inner {
            .tile-text-block {
                top: initial;
                height: 20%;
                width: 100%;
                max-width: 100%;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .tile.format42 {
        .tile-inner {
            .bg-img {
                height: calc(100% - 6px);
            }

            .tile-text-block {
                top: 200px;
            }
        }

        .tile-sizer:after {
            margin-top: 0;
        }
    }

    .tile.format22 {
        .content {
            .tile-inner {
                .tile-text-block {
                    top: 200px;
                    height: initial;
                }
            }
        }
    }
}


// @include page-theme-colors {

// 	.tile {
//     	.content {
//         	.tile-inner {
//             	.tile-text-block {
// 	                .over-title-text {
// 	                    font-family: $color-theme-font;
	
// 	                }
	
// 	                .under-title-text {
// 	                    font-family: $theme-font-sport-bold;
// 	                }
	
// 	                h3 {
// 	                    font-family: $color-theme-font-bold;
// 	                }
//              }
//           }
//         }
//      }
	
	
// }
