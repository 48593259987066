body.theme-bg-poker .deux-textes {
	height: 100%;

	@include media-breakpoint-up(md) {

		.table-col1 {
			float: none;
			display: table-cell;
			vertical-align: top;
		}
	}

	@include media-breakpoint-up(lg) {
		margin-bottom: 2rem;
	}

	.flex-wrap {
		display: flex;
		flex-wrap: wrap;
		.flex-item {
			flex-direction: column;
		}
	}

	.txt {
		.txt-content .media-component.media-text {
			background: transparent !important;
		}
		&:last-child {
			.content {
				padding: 10px;
			}
		}
		.border-hack {
			height: 100%;
			position: absolute;
			top: 0;
			width: calc(100% - 10px);

			&.left-col {
				left: 0;

				&.dropBoxShadow {
					@include media-breakpoint-up(md) {
						box-shadow: 15px 2px 30px 2px rgba(204, 204, 204, 0.5);
					}
				}
			}

			&.right-col {
				right: 0;

				&.dropBoxShadow {
					@include media-breakpoint-up(md) {
						box-shadow: -15px 2px 30px 2px rgba(204, 204, 204, 0.5);
					}
				}
			}
		}
	}
	.txt-content {
		width: 99%;
	}
	/* wa=workaround */
	.ratio-wa {
		width: 1%;
		padding-top: 56.25%;
	}
}
