main.theme-bg-portail .media-text h2 + {
	h3,
	.rich-text {
		margin-top: 2vw;
	}
}

main.theme-bg-portail {
	.btn.opaque,
	section.transparent .btn.opaque,
	section-page.transparent .btn.opaque {
		background-color: #0061d4;
		color: #fff;
		border-color: #0061d4;
		text-decoration: none;
	}
}

main.theme-bg-portail {
	.btn.opaque:hover,
	section.transparent .btn.opaque:hover,
	section-page.transparent .btn.opaque:hover {
		background-color: transparent;
		border-color: #fff;
		color: #fff;
		text-decoration: none;
	}
}

.carousel-promotions .owl-stage-outer {
	.content .content-text,
	a .content-text,
	.tile .tile-inner .content-text,
	.tile-inner .content-text {
		@include media-breakpoint-up(xl) {
			top: 55%;
			height: 44%;
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
	main.theme-bg-portail {
		.media-text h2 + {
			h3,
			.rich-text {
				margin-top: 3vw;
			}
		}
		.media-image {
			.content-text > div.over-title-text,
			.content-text-inner > div.over-title-text {
				font-size: 1.1rem;
				line-height: 1.25rem;
				font-weight: 900;
			}
		}
		section.transparent .media-image {
			.content-text > div.over-title-text,
			.content-text-inner > div.over-title-text {
				font-size: 1.1rem;
				line-height: 1.25rem;
				font-weight: 900;
			}
		}
		section-page.transparent .media-image {
			.content-text > div.over-title-text,
			.content-text-inner > div.over-title-text {
				font-size: 1.1rem;
				line-height: 1.25rem;
				font-weight: 900;
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	main.theme-bg-portail {
		.for-carousel-standard .carousel.carousel-standard {
			.owl-dots {
				margin-top: -43.5vw;
			}

			.mobile-content {
				h2 {
					margin-bottom: 0;
				}
			}
		}
	}
	section
		.content-container.multi-formats
		.columns
		.remontee-loteries.media-component.media-image
		.tile
		.tile-inner
		.media-component
		.tirage
		.groslot-boutons-jouer
		.btn.acheter {
		bottom: 6%;
	}
	main.theme-bg-portail {
		.media-image {
			.content-text > div.over-title-text,
			.content-text-inner > div.over-title-text {
				padding-left: 0;
				font-weight: 900;
				font-size: 4.5vw;
			}
		}
		section.transparent .media-image {
			.content-text > div.over-title-text,
			.content-text-inner > div.over-title-text {
				padding-left: 0;
				font-weight: 900;
				font-size: 4.5vw;
			}
		}
		section-page.transparent .media-image {
			.content-text > div.over-title-text,
			.content-text-inner > div.over-title-text {
				padding-left: 0;
				font-weight: 900;
				font-size: 4.5vw;
			}
		}
	}
	.carousel-promotions .media-image .content-text .under-title-text {
		font-size: 3.1333333333vw !important;
	}
}
@media only screen and (max-width: 568px) {
	main.theme-bg-portail {
		.for-carousel-standard .carousel.carousel-standard {
			.owl-dots {
				margin-top: -38.5vw;
			}
		}
	}
}	
