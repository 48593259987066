.theme-bg-poker {
	.media-tabs {
        .link-lists {
            padding: 0;

            > a {
                @include media-breakpoint-down(sm) {
                    margin: 0;
                    padding-left: 3.3333333333vw;
                    padding-right: 3.3333333333vw;
                }

                @include media-breakpoint-up(sm) {
                    display: flex;
                    flex-wrap: wrap;
                    margin-right: 0;
                }

                &::after {
                    @include media-breakpoint-up(sm) {
                        bottom: 0;
                        height: 5px;
                        margin-top: 0.7rem;
                        position: relative;
                    }
                }

                .media-tabs-nom {
                    font-family: $font-graphik-medium;

                    @include media-breakpoint-up(sm) {
                        margin-left: 1rem;
                    }

                    span {
                        @include media-breakpoint-up(md) {
                            display: block;
                        }
                    }
                }
            }
        }

        // Onglets (window,mac, android, etc.)
        & > .link-lists {
            @include media-breakpoint-up(md) {
                margin-left: 1rem;
            }
        }

		button.mobile-tab {
            background-color: #fff;
            font-family: $font-graphik-semibold;
            font-size: 2.1rem;
            height: 5.2rem;
            border: 2px solid #000;

            &::after {
                right: 5%;
                border-bottom: .5333333333vw solid #000;
                border-right: .5333333333vw solid #000;
            }
        }
        
        .ligne-vertical {
            color: #000;
            content: "";
            display: block;
            position: absolute;
            z-index: 1;
            right: 14%;
            top: 0;
            bottom: 0;
            border: 2px solid;
            border-width: 0 0 0 2px;
        }
    }
    
    .show-mobile-tabs .media-tabs .link-lists {
        background-color: #fff;
        border-left: 2px solid #000;
        border-right: 2px solid #000;
        border-bottom: 2px solid #000;

        > a.selected {
            background-color: #000;

            &::after {
                display: none;
            }

            .media-tabs-nom {
                color: #fff;
            }
        }
    }
}