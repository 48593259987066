.sticky-bar {
    .tile-sizer:after {
        padding-top: 8.7rem;
    }

    .bg-img {
        height: 8.7rem !important;
    }
}

.media-text-with-bg-image {
    padding: 3rem 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .media-text + .media-text {
        padding-top: 0;
    }
}

.related-text-box {
    max-width: 106.666666667vw;
    margin-right: 26.6666666667vw;
    padding: 2.6666666667vw;
    text-align: left;
    /*background-color: $color-theme-background;*/
    position: absolute;
    bottom: 6.6666666667vw;
    left: 0;

        
    @include media-breakpoint-up(md) {
        max-width: 48rem;
    }
    
    @include media-breakpoint-up(lg) {
        max-width: 40rem;
    }
    
    @include media-breakpoint-up(md) {
        margin-right: 9rem;
    }
    
    @include media-breakpoint-up(lg) {
        margin-right: 10rem;
    }
    
    @include media-breakpoint-up(md) {
        padding: 0.9rem;
    }
    
    @include media-breakpoint-up(lg) {
        padding: 1rem;
    }
    
    @include media-breakpoint-up(md) {
        bottom: 3rem;
    }
    
    @include media-breakpoint-up(lg) {
        bottom: 5rem;
    }

    h3 {
        font-size: 4.8vw;

        font-family: 'Montserrat Bold';
        line-height: 1.2em;

        @include media-breakpoint-up(md) {
            font-size: 1.68rem;
        }
        
        @include media-breakpoint-up(lg) {
            font-size: 3.4rem;
        }
    }

    p {
        font-size: 4.4vw;

        color: $color-theme-text-default;

        @include media-breakpoint-up(md) {
            font-size: 1.44rem;
        }
    
    }
}

.theme-bg-sports {
    .tile-text-box {
        max-width: none;
    }
}

.bg-cover {
    background: none no-repeat center center;
    background-size: cover !important;
}

.text-shadow {
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.8) !important;

    .btn {
        text-shadow: 0 0 0 !important;
    }
}

.media-image-with-text-beside {
    .icon-list {
        ul {
            margin-top: 0vw;
            padding-top: 10.6666666667vw;
            padding-bottom: 10.6666666667vw;

            @include media-breakpoint-up(md) {
                margin-top: 0;
                padding-bottom: 4.8rem;
                padding-top: 4.8rem;
            }
        
        }
    }

    .media-text + .media-text {
        padding-top: 0;
    }

    @include media-breakpoint-up(lg) {
        .row {
            .columns {
                &[class*="pull"] {
                    .media-text {
                        padding-left: 0;
                    }
                }

                &:not([class*="pull"]) {
                    .media-text {
                        padding-right: 0;
                    }
                }
            }
        }
    }

    // manage no-vertical-padding with different media queries and different column sizes
    .media-text.no-vertical-padding {
        padding-top: 10.6666666667vw;
        padding-bottom: 10.6666666667vw;

        @include media-breakpoint-up(md) {
            padding-bottom: 4.8rem;
            padding-top: 4.8rem;
        }

        + .media-text {
            padding-top: 0;
        }
    }

    @include media-breakpoint-up(md) {
        .medium-4,
        .medium-6,
        .medium-8 {
            .media-text.no-vertical-padding {
                padding-top: 0;
                padding-bottom: 0;

                + .media-text {
                    padding-top: 4.6rem;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .large-4,
        .large-6,
        .large-8 {
            .media-text.no-vertical-padding {
                padding-top: 0;
                padding-bottom: 0;

                + .media-text {
                    padding-top: 5rem;
                }
            }
        }
    }
}

.related-text-box {
    width: auto;
}

.media-component {
    &.vertical-align {
        display: table;
        position: relative;
        width: 100% !important;

        .columns {
            display: inline-block;
            float: none !important;
            vertical-align: middle !important;
        }
    }
}

.media-text-with-bg-image {
    display: block;
    height: auto;
    background-size: contain;
    padding: 3rem 0;

    .content-inner {
        padding-top: 0;
    }

    .desktop-content {
        position: relative;
        top: 50%;

        transform: translateY(-50%);
    }
}

@include media-breakpoint-up(md) {
    .img-logo {
        opacity: 0;

        transition: opacity 0.25s;
    }

    .media-image-with-text-below {
        .image-title img {
            max-width: 25%;
        }
    }
}

.related-text-box,
.subcontainer > .tile-text-block {
    position: absolute;
    left: 0;
    padding: 0;
    margin: 0;
    bottom: 5.3333333333vw;
    padding-left: 4.6666666667vw;
    text-align: left;

    @include media-breakpoint-up(md) {
        bottom: 2.4rem;
    }
    
    @include media-breakpoint-up(lg) {
        bottom: 4rem;
    }
}

.theme-bg-sports {
    section {
        .row:first-child {

            .media-text-with-bg-image {
                margin-left: 0;
                width: 100px;
                height: 60px;

                @include media-breakpoint-down(sm) {
                    margin-left: 17px;
                }

               @include media-breakpoint-only(md) {
                    margin-left: 29px;
                }

               @include media-breakpoint-only(lg) {
                    margin-left: 29px;
                }
            }
        }
    }
}

.magnolia-editor {
    .carousel {
        .media-text-with-bg-image {
            .desktop-content {
                transform: translateY(0%);
            }
        }
    }
}
