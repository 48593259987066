// theme colors utility classes
.media-icon {
  .icon-wrapper {
    height: 16vw;
  margin-top: 0vw;

    
  @include media-breakpoint-up(md) {
      height: 6rem;
  }

  @include media-breakpoint-up(lg) {
      height: 6rem;
  }

  @include media-breakpoint-up(md) {
      margin-top: 0;
  }

  @include media-breakpoint-up(lg) {
      margin-top: 0.2rem;
  }

    svg {
      max-width: 16vw;

      @include media-breakpoint-up(md) {
          max-width: 6rem;
      }
    }
  }

  & + .media-text {
    margin-bottom: 0vw;
  padding-top: 0vw;
  padding-bottom: 0vw;

    .image-title {
      margin-top: 0vw;

      @include media-breakpoint-up(md) {
        margin-top: 1.2rem;
      }
      
      @include media-breakpoint-up(lg) {
        margin-top: 2rem;
      }

      img {
        display: none;
      }
    }

    h2, .h2 {
      font-size: 4.8vw;
  line-height: 1.2em;
  text-transform: uppercase;
  @include media-breakpoint-up(md) {
    font-size: 1.44rem;
  }
  
  @include media-breakpoint-up(lg) {
    font-size: 1.1rem;
  }

    }

    .rich-text, &.rich-text, .rich-text p, .rich-text a {
        font-size: 5.0666666667vw;

        @include media-breakpoint-up(md) {
          font-size: 2.4rem;
        }

      @include media-breakpoint-up(lg) {
        font-size: 1.46667vw;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 2rem;
      }

      &:not(.btn) {
        line-height: 1.2em;

        a {
          text-decoration: underline;
          line-height: 1.5;

       
        }
      }
    }

 
  }
}

.social-grid {
  .media-image-with-text-below {
    overflow: hidden;

    .media-component {
      position: relative;

      .media-icon {
        padding-left: 10%;
        margin-top: 8%;

        @include media-breakpoint-down(sm) {
          padding-left: 8%;
          margin-top: 0;
        }

        .content {

          @include media-breakpoint-down(sm) {
            margin-top: 0;
            margin-bottom: 0;
            vertical-align: top;
          }
        }

        .icon-wrapper {
          margin-top: 0;
          text-align: left;

          svg {
            max-width: 4.5rem;
            width: 60%;

            @include media-breakpoint-up(md) {
              width: 75%;
            }

            @include media-breakpoint-up(lg) {
              width: 100%;
            }

            max-width: 3rem;
          }
        }
      }

      .media-text {

        .image-title {
          display: none;
        }
      }
    }
  }
}

.social-grid {
  .media-image-with-text-beside {
    .row {
      .columns + .columns {
        @include media-breakpoint-down(sm) {
          padding-top: 4%;
          padding-bottom: 3%;
        }
      }

      .columns[class*="pull"], .columns:not([class*="pull"]) {
        .media-text {
          padding-left: 10%;
          padding-right: 15%;
          max-width: 100%;

          @include media-breakpoint-down(sm) {
            padding-left: 8%;
            padding-right: 8%;
            padding-bottom: 5%;
          }
        }
      }

      .image-title {
        margin-top: 0.6666666667vw;

        @include media-breakpoint-up(md) {
          margin-top: 0.3rem;
        }
        
        @include media-breakpoint-up(lg) {
          margin-top: 0.5rem;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .row.content-container {
    .media-component.media-image-with-text-beside {
      .columns {
        // float: left;
        // display: inline-block;
        vertical-align: top !important;

        .media-component.media-text {
          float: left;
          display: inline-block;
          vertical-align: top;
        }
      }
    }
  }
}

.row {
  &.content-container {
    &.social-grid {
      margin-top: 0;

      &:first-child {
        margin-top: 0;
      }

      .row
      &:first-child {
        .columns.max-width {
          margin-top: 0;
        }
      }

      .columns.max-width {
        margin-top: 18px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .row {
    &.content-container {
      &.social-grid {
        width: 100%;
        padding-top: 0;
        margin-top: 1.6rem;

        // margin: 0.2rem 0 0.2rem calc(#{$component-max-width-padding}px / 2) !important;

        .row {
          .columns.ta-left {
            padding-right: 1.6rem;
          }

          .columns.max-width {
            margin-top: 0;
          }
        }
      }
    }
  }
}

// body.thisisblue {
//   background-color: rgba(9, 88, 146, 1);
// }




.offers-component {
  .tile-inner {
    &.with-logo {
      &:before {
        content: '';
        background-image: linear-gradient(to right, rgba(9, 88, 146, 0), rgba(9, 88, 146, 0.5) 30%, rgba(9, 88, 146, 1) 60%);
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
      }

      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-bottom: 65px solid $color-bleu-pied;
        border-left: 65px solid transparent;
        bottom: 0;
        right: 0;
      }
    }
  }

  .triangle-logo {
        position: absolute;
    	bottom: 10px;
		right: 5px;
        width: 24px;
        height: 24px;
  }


}