body {
	&.theme-bg-casino {
		.bloc-image-texte {
			.media-component.media-text {
				a.btn {
					text-decoration: none;
					//Le font provient de resources/dev/assets/sass/portail/themes/poker/_fonts.scss
					font-family: $lato-font-bold;

					@include media-breakpoint-up(lg) {
						padding: 15px 80px;
					}

					padding: 3% 14%;
					margin-top: 10px;
				}
			}
		}
		@include media-breakpoint-down(sm) {
			.bloc-image-texte {
				.media-component.media-text {
					a.btn {
						padding: 3% 14%;
						margin-top: 10px;
					}
				}
			}
		}
	}
}
