body.theme-bg-casino{
  .media-bg-with-content {
    @include media-breakpoint-up(md) {
      .heading-content {
        max-width: 100%;
      }
    }
   @include media-breakpoint-only(md) {
  	  height:33vw;
    }
    @include media-breakpoint-down(sm) {
  	  min-height:50vw;
    }    
  }
}