@import "portail/themes/_portail";






$theme-font-bold: graphik_bold, Arial, Helvetica, sans-serif !default;
$theme-font-black: $theme-font-bold;


$wide-ratio: (100% * 9 / 16);

$color-carousel-dots: #9a9a9a !default;
$color-carousel-dots-active: #0061d4 !default;

// @mixin page-theme-colors {
//   @for $i from 1 through length($list-color-theme-fg) {
//     $color-theme-fg: nth($list-color-theme-fg, $i) !global;
//     $color-theme-fg-secondary: nth($list-color-theme-fg-secondary, $i) !global;
//     $color-theme-bg: nth($list-color-theme-bg, $i) !global;
//     $color-theme-bg-secondary: nth($list-color-theme-bg-secondary, $i) !global;
//     $theme-classname: nth($list-theme-classname, $i) !global;
//     $color-theme-text: nth($list-color-theme-text, $i) !global;
//     $color-theme-link: nth($list-color-theme-link, $i) !global;
//     $color-theme-link-hover: nth($list-color-theme-link-hover, $i) !global;
//     $color-theme-font: nth($list-color-theme-font, $i) !global;
//     $color-theme-font-light: nth($list-color-theme-font-light, $i) !global;
//     $color-theme-font-medium: nth($list-color-theme-font-medium, $i) !global;
//     $color-theme-font-bold: nth($list-color-theme-font-bold, $i) !global;
//     $color-theme-font-black: nth($list-color-theme-font-black, $i) !global;

//     main.theme-bg-#{$theme-classname}, main.theme-bg-#{$theme-classname} section.transparent, main.theme-bg-#{$theme-classname} section-page.transparent {
//       @content;
//     }
//   }
// }

%visuallyhidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}
.visually-hidden {
    @extend %visuallyhidden;
}
