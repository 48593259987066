body {
	&.theme-bg-poker {
		.bloc-image-texte {

			&.textefirst {
				.img {
					@include media-breakpoint-up(md) {
						padding-left: 10px;
					}
				}
				.txt {
					@include media-breakpoint-up(md) {
						padding-right: 10px;
					}
				}
			}

			.media-component.media-text {
				.content {
					padding-top: 15px;
					padding-bottom: 15px;
				}

				button.btn:hover {
					color: black;
				}
			}

			div:not(.deux-textes) {
				.media-text,
				.media-image {
					@include media-breakpoint-up(md) {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}

		.content-inner {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}
