.text-block {
	position: absolute;
	z-index: 2;
	bottom: 3.33333vw;
	padding: 2.66667vw 7.33333vw 2.66667vw 7.33333vw;
	overflow: hidden;
	width: auto;
	max-width: 90%;

	span {
		display: block;
		line-height: 3.73333vw;

		&.over-title-text {
			font-size: 3.46667vw;
			font-weight: normal;
			margin-bottom: 0.26667vw;
		}

		&.under-title-text {
			font-size: 3.46667vw;
			font-weight: normal;
			margin-top: 0.4vw;
		}
	}
}

.tile-static,
.tile-instagram {
	h3,
	span {
		margin-left: auto;
		margin-right: auto;

		&.btn {
			width: auto;
		}
	}
}

.ta-left.tile-static,
.ta-left.tile-instagram {
	h3,
	span {
		margin-left: 0;
		margin-right: 0;
	}
}

.ta-right.tile-static,
.ta-right.tile-instagram {
	h3,
	span {
		margin-left: 50%;
		margin-right: 0;
	}
}

@include media-breakpoint-up(md) {
	.text-block {
		bottom: 1.5rem;
    	adding: 1.2rem 1.2rem 1.2rem 1.2rem;

		span {
			line-height: 1.68rem;

			&.over-title-text {
				font-size: 1.56rem;
    			margin-bottom: 0.12rem;
			}

			&.under-title-text {
				font-size: 1.56rem;
    			margin-top: 0.18rem;
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.text-block {
		bottom: 1.4rem;
		padding: 1.4rem 4rem 1.4rem 1.4rem;

		span {
			line-height: 1.4rem;

			&.over-title-text {
				font-size: 1.3rem;
				margin-bottom: 0.2rem;
			}

			&.under-title-text {
				font-size: 1.3rem;
				margin-top: 0.3rem;
			}
		}
	}

	.tile-static,
	.tile-instagram {
		&[data-width='1'] .text-block {
			max-width: 88.4%;
		}

		&[data-width='2'] .text-block {
			max-width: 76.6%;
		}

		&[data-width='4'] .text-block {
			max-width: 29.2%;
		}

		span {
			.over-title-text {
				font-size: 1.6rem;
				margin-bottom: 0.3rem;
			}

			.under-title-text {
				font-size: 1.4rem;
				margin-top: 0.4rem;
			}
		}
	}
}

.grid {
	.tiles-container {
		.subcontainer {
			.tile {
				.tile-text-block {

                    @include media-breakpoint-up(lg) {
                        top: 50%;
						right: 0;
						//padding-left: 2rem;
						padding-top: 1.3rem;
                        max-width: none;
                    }

					@include media-breakpoint-up(xxl) {
						height: calc(50% - 18px);
						margin-left: 0;
						margin-right: 0;
                    }
                    
                    span,
						h2,
						h3 {
                            @include media-breakpoint-up(lg) {
                                width: auto;
                            }
						}
				}

				&.format22 {
					.tile-text-block {
						@include media-breakpoint-up(lg) {
							left: 0;
							width: calc(100% - 18px);
						}
					}
				}

				&.format42 {
					.tile-text-block {

                        @include media-breakpoint-up(lg) {
							width: 50%;
							right: 18px;
							left: auto;
                        }
                        
						@include media-breakpoint-up(xxl) {
							right: 0;
						}
					}
				}
			}
		}
	}
}

// @include page-theme-colors {
// 	.text-block {
// 		span {
// 			&.over-title-text {
// 				font-family: $color-theme-font;
// 			}

// 			&.under-title-text {
// 				font-family: $color-theme-font;
// 			}
// 		}
// 	}
// }

.theme-bg-sports {
	.tile-text-block {
		padding-left: 4.6666666667vw;

		@include media-breakpoint-up(md) {
			padding-left: 2.8rem;
		}
	}

	.grid .tiles-container .subcontainer .tile.format22 .tile-text-block {
		width: 100%;
	}
}

/* regression ok poker */
body {
	&:not(.theme-bg-poker):not(.theme-bg-casino) {
		@media only screen and (min-width: 1259px) {
			.tile.format22 .content .tile-inner .tile-text-block {
				top: 209px;
			}
		}

		#page-content section .grid .subcontainer .tile-text-block {
			height: 50%;
		}

		.tile[data-width='2'][data-height='2'] .tile-sizer:after {
			padding-top: 102%;
		}

		@media only screen and (max-width: 1199px) {
			.tile.format22 .content .tile-inner .tile-text-block {
				top: auto;
				bottom: 0;
			}
		}

		@media only screen and (max-width: 1023px) {
			.tile.format22 .content .tile-inner .tile-text-block {
				top: auto;
				bottom: 0;
				height: 20%;
			}

			#page-content section .grid .subcontainer .tile-text-block {
				//height: auto;
			}
		}
	}
}
