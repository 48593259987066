body:not(.theme-bg-portail) {

	.aDecouvrir,
	.explorer {
		.owl-carousel .mask {
			top: 10%;
		}
	}
	.aDecouvrir {
		.media-image .content-text div.over-title-text {
			font-family: $font-graphik-regular;
		}

		@media only screen and (min-width: 768px) {
			.media-image .content-text {
				left: 7% !important;
			}
			.media-image .content-text div.over-title-text {
				font-size: 1.7rem;
				line-height: 1.9rem;
			}
			.tile-inner .btnWrap .btn {
				font-size: 16px;
				line-height: 20px;
				padding: 11px 22px;
				margin-top: 22px;
				margin-bottom: 11px;
			}
		}

		@media only screen and (min-width: 1024px) {
			.media-image .content-text div.over-title-text {
				font-size: 1.2rem;
				line-height: 1.3rem;
			}
		}

		@media only screen and (min-width: 1200px) {
			.media-image .content-text div.over-title-text {
				font-size: 1.6rem;
				line-height: 1.8rem;
			}
		}

		.media-component.media-image.squared-img {
			margin-left: 5px;
			margin-right: 5px;
		}
		.carousel-promotions {
			.owl-stage-outer {
				div.tile-inner {
					.bg-img.lazyloaded {
						margin-top: 10px;
					}
					.content.content-text {
						z-index: 0;
						font-family: $font-graphik-regular;
						* {
							position: relative;
							text-transform: none;
						}
					}

					p {
						color: white;
					}

					@include media-breakpoint-down(sm) {
						.content.content-text {
							position: absolute;
							left: 10%;
							right: 10%;
							top: 100% !important;
							width: 80%;
							text-align: center;
							padding-left: 0;
							* {
							}
						}
					}

					.filiale {
						background: black;
						margin-top: 0;
					}
				}
			}
		}
	}

	.explorer {
		.media-component.media-image.squared-img {
			margin-left: 5px;
			margin-right: 5px;
		}
		.carousel-promotions {
			.owl-stage-outer {
				div.tile-inner {
					.bg-img.lazyloaded {
						margin-top: 10px;
					}

					.content.content-text {
						z-index: 0;
						font-family: $font-graphik-regular;
						* {
							position: relative;
							text-transform: none;
						}
					}

					p {
						color: white;
					}

					@include media-breakpoint-down(sm) {
						p {
							font-size: 4vw;
						}
						.content.content-text {
							position: absolute;
							left: 10%;
							right: 10%;
							width: 80%;
							padding-left: 0;

							.under-title-text {
								font-size: 2.5vw;
							}
							.over-title-text {
								font-size: 5vw;
							}
						}
						.btnWrap {
							bottom: 1.5vw;
							a.btn {
								font-size: 2.5vw;
								padding: 3vw 5vw;
								border-radius: 5px;
							}
						}
					}

					@include media-breakpoint-up(md) {
						p {
							font-size: 2vw;
						}

						.content.content-text {
							.under-title-text {
								font-size: 1.35vw;
							}
							.over-title-text {
								font-size: 2.5vw;
							}
						}

						.btnWrap {
							bottom: 0.25vw;
							a.btn {
								font-size: 1.2vw;
								padding: 1.5vw 2.5vw;
								border-radius: 3px;
							}
						}
					}
					@media (min-width: 1024px) {
						p {
							font-size: 1.5vw;
						}

						.content.content-text {
							.under-title-text {
								font-size: 0.9vw;
							}
							.over-title-text {
								font-size: 1.8vw;
							}
						}
						.btnWrap {
							bottom: 0.2vw;
							a.btn {
								font-size: 0.7vw;
								padding: 1vw 1.6vw;
								border-radius: 3px;
							}
						}
					}

					@media (min-width: 1343px) {
						p {
							font-size: 20px;
						}

						.content.content-text {
							.under-title-text {
								font-size: 12px;
							}
							.over-title-text {
								font-size: 24px;
							}
						}
						.btnWrap {
							bottom: 2.72px;
							a.btn {
								font-size: 12px;
								padding: 13px 16px;
								border-radius: 3px;
							}
						}
					}

					.filiale {
						background: black;
						margin-top: 0;
					}
				}
			}
		}
	}

	.for-carousel-promotions.aDecouvrir {
		.carousel.carousel.owl-theme {
			.mask {
				$arrow-gradiant: rgba(246, 246, 246, 0) 0%, rgba(246, 246, 246, 0.5) 20%, #f6f6f6 37%;
				&.left-arrow {
					background-image: linear-gradient(to left, $arrow-gradiant);
				}
				&.right-arrow {
					background-image: linear-gradient(to right, $arrow-gradiant);
				}
			}
			.owl-nav {
				.owl-prev,
				.owl-next {
					padding: 14px 0;
					border: 3px solid black;
					use {
						fill: black;
					}
				}
			}
		}
	}

	.aDecouvrir {
		.carousel-promotions .owl-stage-outer .content.content-img.with-text {
			border: 1px solid black;
			.btnWrap {
				position: absolute;
				bottom: 7px;
				width: 100%;
				.btn {
					z-index: 2;
					font-family: $font-graphik-semibold;
					&:hover {
						color: black;
					}
				}
				.tile-inner {
					position: relative;
					z-index: 0;
				}
			}
		}
	}

	.for-carousel-promotions.explorer {
		.carousel.carousel.owl-theme {
			.mask {
				$arrow-gradiant: rgba(246, 246, 246, 0) 0%, rgba(246, 246, 246, 0.5) 20%, #f6f6f6 37%;
				&.left-arrow {
					background-image: linear-gradient(to left, $arrow-gradiant);
				}
				&.right-arrow {
					background-image: linear-gradient(to right, $arrow-gradiant);
				}
			}
			.owl-nav {
				.owl-prev,
				.owl-next {
					padding: 14px 0;
					use {
						fill: black;
					}
				}
			}
		}
	}

	.explorer {
		.carousel-promotions .owl-stage-outer .content.content-img.with-text {
			border: 1px solid black;
			.btnWrap {
				position: absolute;
				width: 100%;
				text-align: left;

				.btn {
					background-color: white;
					border-color: white;
					z-index: 2;
					font-family: $font-graphik-bold;
					&:hover {
						color: white !important;
						background-color: transparent !important;
					}
				}
				.tile-inner {
					position: relative;
					z-index: 0;
				}
			}
		}
	}

	.mainCarrouselAcueil {
		a.btn:hover {
			color: white;
		}
	}
	.carousel-standard.carousel.carousel.carousel-full-width {
		@include media-breakpoint-down(sm) {
			.carousel-text-block::after {
				background: none;
			}
		}

		h2 .under-title-text:after {
			display: none !important;
		}

		.owl-nav.side-arrows {
			@include media-breakpoint-down(sm) {
				top: 21vw !important;
				svg {
					height: 70%;
					width: 70%;
				}
			}
			display: block;

			.owl-next {
				right: -1.3333333333vw;

				@include media-breakpoint-up(md) {
					right: -0.6rem;
				}

				@include media-breakpoint-up(lg) {
					right: -0.5rem;
				}

				@include media-breakpoint-up(xxl) {
					right: 0.1rem;
				}
			}

			.owl-prev {
				left: -1.3333333333vw;

				@include media-breakpoint-up(md) {
					left: -0.6rem;
				}

				@include media-breakpoint-up(lg) {
					left: -0.5rem;
				}

				@include media-breakpoint-up(xxl) {
					left: 0.1rem;
				}
			}
		}
		.owl-dots {
			display: block;
			left: 50%;
			transform: translate(-50%, 0);
			margin: 0;
			position: absolute;
			bottom: 0;
			@include media-breakpoint-down(sm) {
				display: none;
			}
			span {
				background: none;
			}
		}

		.desktop-content {
			.row .columns {
				padding-right: 1.5%;
				.btn {
					font-family: $font-graphik-semibold;
					padding: 5% 9%;
					border-style: solid;
					box-sizing: border-box;
					border-color: #0061d4;
					background-color: #0061d4;
				}
			}
		}
		div.mobile-content {
			bottom: 0 !important;
			.media-component.media-text {
				padding: 0 !important;
				height: 39vw;
				.content-inner {
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0;
					div.image-title {
						h2 {
							color: white;
							font-family: $font-graphik-semibold;

							.under-title-text {
								font-family: $font-graphik-regular;
								color: white;
							}
						}
						h3 {
							color: white;
						}
					}
					.rich-text {
						.btn {
							font-family: $font-graphik-medium;
							padding: 2vw 3vw;
							color: white;
							&:hover {
								color: black;
							}
						}
					}
				}
			}
		}
	}
}

.theme-bg-casino .mainCarrouselAcueil .carousel-standard.carousel.carousel.carousel-full-width div.mobile-content .media-component.media-text .content-inner .rich-text .btn{
	@include media-breakpoint-down(sm) {
		text-align:center;
		min-width:120px;
		width:auto;
	}
}

.ej-carousel-item-link{
	display: block;
	height: 100%;
	width: 100%;
}


.theme-bg-portail {
	.for-carousel-standard .mobile-content .subtitle {
		margin-bottom: 0;
	}
}

body{
	&.theme-bg-poker{
		.aDecouvrir{
			.carousel-promotions{
				.owl-stage-outer{
					div{
						&.tile-inner{
							.content{
								&.content-text{
									top: calc(50% + 1.4vw)!important;
								}
							}
						}
					}
				}
			}
		}
	}
}

.btn.hover-black {
	&:hover {
		border-color: black !important;
		color: black !important;
	}
}

.btn.hover-white {
	&:hover {
		border-color: white !important;
		color: white !important;
	}
}