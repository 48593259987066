$button_color: rgb(13, 203, 143);

.theme-bg-bingo{
	background-color: #fff;
}

body.theme-bg-bingo {
	font-family: 'graphik_regular';
	@include ms-respond(font-size, 1);
	
	div {
		line-height: 1.3em;
	}

	div > *{
		line-height: 1.3em;
	}

	.btn.btn_bingo_theme{
		border-radius: 40px;
	}

	.section-page{
		
		.btn:hover{
			opacity: 0.75;	
		}
	
		.btn.btn_bingo_theme, .btn.btn_bingo_theme:hover{
			background-color: $button_color !important;
			
		}
	}		
}

.theme-bg-bingo a{
	color: #06845C;
	text-decoration: underline;
}

.theme-bg-bingo a.sans_underline{
	text-decoration: unset;
}

.ctn-shadow{
	box-shadow: 1px 1px 13px 0px #A4A4A4;
  }

.takeFullWidth{	
	width: 100vw !important;
	position: relative !important;
	left: 50% !important;
	right: 50% !important;
	margin-left: -50vw !important;
	margin-right: -50vw !important;
	max-width: none !important;
}

.carousel div .card .media-component {
    height: auto !important;
}