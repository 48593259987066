.columns {
    width: 100%;

    @include media-breakpoint-up(md) {
        padding-top: 20px;
    }

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &.no-top-margin {
        padding-top: 0;
    }

    &.google-maps .tile-sizer:after {
        padding-top: 35%;
    }

    + .tabs-container {
        margin-top: 0;
    }

    .columns,
    .column {
        height: 100%;
    }

    .tile-inner {
        margin: 0;
    }

    + .media-see-more {
        margin-top: 0;
    }

    &.header-block {
        margin-top: 0;
    }

    &.sticky-bar {
        margin-top: 0;
    }
}

.tile-sizer {
    width: 100%;

    &:after {
        padding-top: 56.25%;
    }
}

.squared-img .tile-sizer:after {
    padding-top: 100%;
}

.bottom-margin {
    margin-bottom: 16vw !important;
}

@include media-breakpoint-up(md) {

    .bottom-margin {
        margin-bottom: 7.2rem !important;
    }
}

@include media-breakpoint-up(lg) {
    .row.content-container {

        &.offer-block,
        &.related-articles-block {
            display: block;

            .columns,
            .column {
                display: inline-block;
            }
        }

        .after-line {
            &:after {
                position: absolute;
                bottom: -1.8rem;
                left: 0.5rem;
                right: 0;
                width: 8.2rem;
                height: 4rem;
            }

            + .rich-text {
                margin-top: 3.5rem;
            }
        }
    }

    .bottom-margin {
        margin-bottom: 5rem !important;
    }
}

@include media-breakpoint-down(sm) {
    .row.content-container.disable-on-mobile {
        display: none;
    }
}

@include media-breakpoint-only(md) {
    .row.content-container.disable-on-tablet {
        display: none;
    }
}
