body {
	&.theme-bg-casino {
		footer .link-lists.nous-joindre {
			background-color: #242424;

			@include media-breakpoint-up(lg) {
				background-color: transparent;
			}
		}
	}
}
