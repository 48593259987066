.theme-bg-poker {
	.media-text {
		.image-title .under-title-text {
			margin-bottom: 4vw;
			position: relative;

			@include media-breakpoint-up(md) {
				margin-bottom: 1.8rem;
			}

			@include media-breakpoint-up(lg) {
				margin-bottom: 1.5rem;
			}

			&::after {
				background-color: #fff;
				top: 7.2vw;
				content: '';
				height: 0.2rem;
				left: 0;
				position: absolute;
				width: 15.5vw;

				@include media-breakpoint-up(lg) {
					top: 2vw;
					width: 4.2vw;
				}
				@include media-breakpoint-only(md) {
					top: 2vw;
					width: 4.2vw;
				}
			}
		}

		.container-progressif {
			font-weight: bold;
			padding-left: 2vw; 
			position: absolute;
			text-align: left;
			width: 100%;
		}
	}

	.media-text .rich-text .btn {
		color: black;
		padding: 15px 60px;
		text-decoration: none;
	}

	.media-text .rich-text p {
		line-height: 1.5em;
	}

	h2,
	.h2 {
		.under-title-text {
			&:after {
				top: 7.2vw;

				@include media-breakpoint-up(md) {
					top: 2vw;
				}

				@include media-breakpoint-up(lg) {
					top: 2vw;
				}

				@include media-breakpoint-up(xxl) {
					top: 2vw;
				}
			}
		}
	}

	.media-text {
		&.coinsArrondis {
			border-radius: 20px;
		}
		.image-title {
			// color: #fff;

			h2 {
				font-family: 'graphik_bold', Arial, Helvetica, sans-serif;
			}

			h2,
			.h2 {
				font-size: 8vw;
				line-height: 1.25;

				@include media-breakpoint-up(md) {
					font-size: 2.5vw;
				}

				.under-title-text {
					font-family: 'graphik_semibold', Arial, Helvetica, sans-serif;
					font-size: 4.1vw;
					line-height: 1.37;

					@include media-breakpoint-up(md) {
						font-size: 1.1vw;
						font-family: $font-graphik-medium;
					}
				}
			}

			.h2 {
				// color: #000;
			}
		}

		.rich-text a:not(.btn) {
			text-decoration: underline;
		}

        .container-progressif .jackpot-number {
			background: none;
			font-family: graphik_black,Arial,Helvetica,sans-serif;
            line-height: 2;
        }
	}

	.bloc-image-texte {
		.media-text .rich-text .btn {
			padding: 15px 25px;
		}
	}

	.bloc-image-texte.imagefirst {
		.txt .bgImage {
			background-size: contain;
		}
	}

	.desktop-content,
	.mobile-content {
		.media-text {
			background-color: #000;
		}
	}
}
