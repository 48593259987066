.lexique {
	div {
		&.lexique-liens {
			margin-bottom: 1vw;

			a {
	   		    font-family:'graphik_bold', Arial, Helvetica, sans-serif !important;
				font-size: 26px;
				padding-right: 10px;
			}
		}
	
		&.titre-lettre {
			background-color: #EDEDED; /* beige */
   		    font-family:'graphik_bold', Arial, Helvetica, sans-serif !important;
			font-size: 26px;
			line-height: 1;
			margin-bottom: 12px;
			padding-top: 1vw;
			padding-bottom: 1vw;

			a {
				color: #E30613 !important; /* rouge */
			}
		}

		.panel {
			margin: 0 1.4rem 1.4rem;
		}

		.panel-heading {
			background-color: initial;
			border-bottom: 1px solid #ddd;
			color: #333;
			font-size: 3vw;
			font-weight: bold;
			line-height: 1.5;

	        @include media-breakpoint-up(lg) {
				font-size: 1.4rem;
			}
		}
			
		.panel-body {
			background-color: initial;
			font-size: 3vw;
			line-height: 1.25;

			p {
				@include media-breakpoint-up(lg) {
					font-size: 1.4rem;
				}
	        }
			
			a {
				text-decoration: underline !important;
			}

		}
			
	}
}