.media-video {

  iframe {
    display: block;
  }
  
  .flex-video {
    padding-bottom: 0;
  }
  
  .youtube-container{
    background-position: center;
    background-size: cover;
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    overflow: hidden;
    opacity: 1;
    padding-top: 56.25%; // 16:9
    z-index: 17 !important;

    @include media-breakpoint-down(sm) {
      background-position: 0 -19vw;
    }
  }

  .youtube-container > iframe{
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    max-height: 47vw;
    width: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
    pointer-events: none;
    z-index: 18;
    @include media-breakpoint-up(md) {
      max-height: 34vw !important;
    }
    @include media-breakpoint-up(lg) {
      max-height: 42vw !important;
    }
    @include media-breakpoint-up(xl) {
      max-height: 26vw !important;
    }
    @include media-breakpoint-up(xxl){
      max-height: 500px !important;
    }    
  }
  

  .youtube-container > .video-consent-overlay{
    position: absolute;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    max-height: 35vw;

    background-color: var(--brk-dec-color-main-extradark);
    box-sizing:border-box;

    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    z-index: 17;

    @include media-breakpoint-up(md){
      max-height: 28vw !important;
    }
    @include media-breakpoint-up(lg){
      max-height: 42vw !important;
    }
    @include media-breakpoint-up(xl){
      height: 450px !important;
    }
    @include media-breakpoint-up(xxl){
      height: 500px !important;
    }
    @include media-breakpoint-up(xxxl){
      height: 572px !important;
    }
  }

  .youtube-container .video-consent-overlay .bg-image {
    position: absolute;
    height: auto;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.2;
    z-index: -1 !important;
    @include media-breakpoint-down(sm) {
      height: 180px;
    }
  }

  .youtube-container .video-consent-overlay .scrollable-section{
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #90a4ae #cfd8dc;
    height: fit-content;
    margin: 6px 12px !important;
    @include media-breakpoint-up(md){
      margin: 6px auto 6px 24px !important;
      max-width: 58vw !important;
    }
    @include media-breakpoint-up(lg){
      margin: 6px auto 6px 64px !important;
      max-width: 56vw !important;
    }
    z-index: 18 !important;
  }

  .youtube-container .video-consent-overlay .scrollable-section .logo{
    @include media-breakpoint-up(md){
      margin-left: unset !important;
      margin-top: 16px;
    }
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    height: 23px;
    width: 102px;
    svg {
      height: 100%;
      width: 100%;
    }
  }

  .video-consent-overlay-logo{
    height: 23px;
    width: 102px;
    svg {
      height: 100%;
      width: 100%;
    }
  }

  .youtube-container .video-consent-overlay .scrollable-section::-webkit-scrollbar-track{
    background: #cfd8dc;
  }

  .youtube-container .video-consent-overlay .scrollable-section::-webkit-scrollbar-thumb{
    background-color: #90a4ae;
    border-radius: 6px;
    border: 2px solid #cfd8dc;
  }

  .youtube-container .video-consent-overlay .scrollable-section::-webkit-scrollbar{
  	width: 7px;
  }
  
  .youtube-container .video-consent-overlay .scrollable-section .video-consent-overlay-titre h3{
    --ratio: var(--brk-text-ratio-sm);
    @include media-breakpoint-up(md){
      --ratio: var(--brk-text-ratio-md);
    }
    @include media-breakpoint-up(lg){
      --ratio: var(--brk-text-ratio-lg);
    }
    color: var(--brk-text-inverted-body-base-font-color);
    font-family: graphik_semibold, Arial, Helvetica, sans-serif;
    font-size: calc(var(--brk-text-heading-3-font-size)/var(--ratio)) ;
    font-weight: var(--brk-text-heading-3-font-weight);
    letter-spacing: var(--brk-text-heading-3-letter-spacing);
    line-height: var(--brk-text-heading-3-line-height);
    text-align: left;
    text-decoration: var(--brk-text-heading-3-text-decoration);
    text-transform: var(--brk-text-heading-3-text-transform);
  }

  .youtube-container .video-consent-overlay .scrollable-section .video-consent-overlay-text p{
    --ratio: var(--brk-text-ratio-sm);
    @include media-breakpoint-up(md){
      --ratio: var(--brk-text-ratio-md);
    }
    @include media-breakpoint-up(lg){
      --ratio: var(--brk-text-ratio-lg);
    }
    color: var(--brk-text-inverted-body-base-font-color);
    font-family: Graphik, Arial, Helvetica, sans-serif;
    font-size: calc(var(--brk-text-body-base-font-size)/var(--ratio));
    font-weight: var(--brk-text-body-base-font-weight);
    letter-spacing: var(--brk-text-body-base-letter-spacing);
    line-height: var(--brk-text-body-base-line-height);
    text-align: left;
    text-decoration: var(--brk-text-body-base-text-decoration);
    text-transform: var(--brk-text-body-base-text-transform);
  }

  .youtube-container .video-consent-overlay-accept-button{
    &:hover {
      background-color: var(--brk-button-primary-inverted-background-color-hover);
      border-color: var(--brk-button-primary-inverted-border-color-hover);
      color: var(--brk-button-primary-inverted-font-color-hover);
      text-decoration: var(--brk-button-primary-text-decoration-hover);
    }
    &:focus-visible {
      background-color: var(--brk-button-primary-inverted-background-color-focus);
      border-color: var(--brk-button-primary-inverted-border-color-focus);
      outline-color: var(--brk-interactive-inverted-outline-color-focus);
      text-decoration: var(--brk-button-primary-text-decoration-focus);
    }  
    margin: 6px auto !important;
    @include media-breakpoint-up(md) {
      margin: 6px auto 6px 24px !important;
    }
    @include media-breakpoint-up(lg){
      margin: 12px auto 12px 64px !important;
    }
    min-height: 30px;
    z-index: 20 !important;
    position: sticky;
    align-items: center;
    background-color: var(--brk-button-primary-inverted-background-color-default);
    border: var(--brk-button-primary-border-width) solid var(--brk-button-primary-inverted-border-color-default);
    border-radius: var(--brk-button-primary-border-radius);
    box-sizing: border-box;
    color: var(--brk-button-primary-inverted-font-color-default);
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    font-family: var(--brk-button-primary-font-family);
    font-size: calc(var(--brk-text-heading-3-font-size)/var(--ratio));
    font-weight: var(--brk-button-primary-font-weight);
    height: var(--brk-button-primary-height);
    justify-content: center;
    letter-spacing: var(--brk-button-primary-letter-spacing);
    line-height: var(--brk-button-primary-line-height);
    outline: var(--brk-interactive-outline-width) solid transparent;
    outline-offset: var(--brk-interactive-outline-offset);
    padding: 0 var(--brk-button-primary-padding-horizontal);
    text-decoration: var(--brk-button-primary-text-decoration-default);
    text-transform: var(--brk-button-primary-text-transform);
  }

  .button-fonce {
    &:hover {
      background-color: var(--brk-button-primary-standard-background-color-hover) !important;
      border-color: var(--brk-button-primary-standard-border-color-hover) !important;
      color: var(--brk-button-primary-standard-font-color-hover) !important;
    }
    &:focus-visible{
      background-color: var(--brk-button-primary-standard-background-color-focus) !important;
      border-color: var(--brk-button-primary-standard-border-color-focus) !important;
      outline-color: var(--brk-interactive-standard-outline-color-focus) !important;
    }
    background-color: var(--brk-button-primary-standard-background-color-default) !important;
    border: var(--brk-button-primary-border-width) solid var(--brk-button-primary-standard-border-color-default) !important;
    color: var(--brk-button-primary-standard-font-color-default) !important;
  }
  
  .bouton-clair {
    &:hover {
      background-color: var(--brk-button-primary-inverted-background-color-hover) !important;
      border-color: var(--brk-button-primary-inverted-border-color-hover) !important;
      color: var(--brk-button-primary-inverted-font-color-hover) !important;
    }
    &:focus-visible {
      background-color: var(--brk-button-primary-inverted-background-color-focus) !important;
      border-color: var(--brk-button-primary-inverted-border-color-focus) !important;
      outline-color: var(--brk-interactive-inverted-outline-color-focus) !important;
    }
    background-color: var(--brk-button-primary-inverted-background-color-default) !important;
    border: var(--brk-button-primary-border-width) solid var(--brk-button-primary-inverted-border-color-default) !important;
    color: var(--brk-button-primary-inverted-font-color-default) !important;
  }

  .yt-ambiance-video {
    background-position: center;
    background-size: cover;
    display: block;
    max-height: 380px;
    overflow: hidden;
    position: absolute;
    padding-top: 56.25%; // 16:9
    transform: translateY(-50%);
    top: 50%;
    width: 100%;
    
    @include media-breakpoint-down(sm) {
      background-position: 0 -19vw;
    }

    iframe {
      height: 100%;
      left: 0;
      object-fit: cover;
      object-position: center;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
    }
    
  }
  
  .yt-play-pause {
    position: absolute;
    z-index: 2;
    top: 15px;
    left: 15px;
    width: 32px;
    line-height: 32px;
    border-radius: 50%;
    border: 0 none;
    background-color: rgba(0,0,0,.50);
    text-indent: -1000px;
    transition: all 0.3s ease;
    opacity: 0;
    
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 15px;
      width: 5px;
      z-index: 2;
      text-indent: 0;
      background-color: $color-w1;
      transform: translate(-140%, -50%);
      transition: all 0.3s;
    }
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 15px;
      width: 5px;
      z-index: 2;
      text-indent: 0;
      border: 0 none;
      background-color: $color-w1;
      transition: all .3s;
      transform: translate(40%, -50%);
    }
    &.paused:before {
      height: 0;
      width: 0;
      opacity: 0;
    }
    &.paused:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 0;
      border: transparent solid 9px;
      z-index: 2;
      text-indent: 0;
      border-left-color: $color-w1;
      border-left-width: 12px;
      background-color: transparent;
      transform: translate(-20%, -50%);
    }
  }
}

@include media-breakpoint-up(md) {
  .media-video {
    .yt-ambiance-video {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
    &:hover .yt-play-pause {
      opacity: 0.5;
    }
    
    .yt-play-pause:hover,
    .yt-play-pause:focus {
      opacity: 1;
    }
  }
}

/* Ajouter des modifications pour le mobile pour la RUNS-460*/
@include media-breakpoint-down(sm) {
  .media-video {
    iframe {
      display: block !important;
      margin-bottom: 0px !important;
      top: 0 !important;
    }
  }
}

.bloc-video {
  .media-video {
    margin-top: 10.6666666667vw;
      margin-bottom: 10.6666666667vw;
      
      @include media-breakpoint-up(md) {
      margin-bottom: 4.8rem;
      margin-top: 4.8rem;
    }
    
    @include media-breakpoint-up(lg) {
      margin-bottom: 8rem;
      margin-top: 8rem;
    }
  }
}
