
// debut commun
footer {
    margin: 0;

    @include media-breakpoint-down(sm) {
        .certification-icons > li {
            display: inline-block;
            text-align: center;
            width: 48%;
            padding-left: 5%;
            padding-right: 5%;

            &.logo-aidejeu,
            &.logo-carte-credit,
            &.logo-wla,
            &.logo-choix-de-pub-blanc,
            &.logo-choix-de-pub-default {
                width: 100%;
            }
        }
    }

   @include media-breakpoint-only(md) {
        .certification-icons > li {
            display: inline-block;
            text-align: center;
            width: 32%;
            padding-left: 5%;
            padding-right: 5%;

            &.logo-wla {
                width: 100%;
            }
        }
    }

    .link-lists {

        > li {
			border-bottom: 1px solid #fff;
		}
	}
   
    .autre-contenu {
    	padding: 1.4rem 1.4rem 0 1.4rem;
    }
}

@include media-breakpoint-up(lg) {
    .theme-bg-sports {
        footer {
            // Section link lists
            .link-lists {
                border-bottom: 1px solid $color-g2;
                border-top: 1px solid $color-g2;

                li {
                    border-right: 1px solid $color-g2;

                    &:last-child {
                        border-right: none;
                    }

                    li {
                        border-right: none;
                    }
                }

                &.nous-joindre {
                    border-bottom: 1px solid $color-g2;

                    li {
                        border-right: none;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    footer {
        // Section link lists
        .link-lists {

            > li {
                vertical-align: top;
                border-bottom: none;
                display: inline-block;

                width: 212px;

                &:first-child {
                    border-top: none;
                }

                a {
                    font-size: 1.2rem;
                }

                &:first-child > ul:before {
                    border-left: none;
                }
            }

            button {
                line-height: 1.2em;
                font-family: $theme-font-bold;
                font-size: 1.4rem;
                max-width: 28.8rem;
                padding: 0.8rem 3rem;
                cursor: default;

                &:after {
                    display: none;
                }

                + ul {
                    display: block;
                    padding: 0;
                    background: transparent;
                    max-width: 28.8rem;
                    padding: 0 3rem;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 2rem;
                        bottom: 2rem;
                        width: 0.1rem;
                        border: none;
                        margin-left: -2.8rem;
                    }

                    li {
                        line-height: 1em;
                        padding: 0.3rem 0;

                        a {
                            transition: color 0.3s;
                        }
                    }
                }
            }
        }

        .certification-icons {
            text-align: center;
            margin-bottom: 0.5rem;
            margin-top: 0.7rem;

            .logo-menu-lq {
                max-width: 12.5rem;
            }

            .logo-jeu-doit-rester-un-jeu {
                max-width: 10.5rem;
            }

            .logo-aidejeu {
                max-width: 17.5rem;
            }

            .logo-carte-credit {
                max-width: 20rem;
                
                svg {
                	width: 20em;
                }
            }

            .logo-wla {
                margin-top: 15px;
                margin-left: 0;

                svg {
                    max-width: 33rem;
                }
            }
            .logo-choix-de-pub-blanc,.logo-choix-de-pub-default{
            	margin-top: 15px;
                margin-left: 0;

                svg {
                    max-width: 33rem;
                }
            }

            li {
                display: inline-block;
                padding: 0;
                margin: 0;

                + li {
                    // border-left: 1px solid $footer-border-color;
                    border-left: none;

                    // padding-left: 8rem;
                    margin-left: 10.5rem;
                }
            }
        }

        .copyright .notice {
            font-size: 1rem;
        }
    }
}

// fin commun
footer {
    clear: both;

    //Sections social icon
    .social-icons {
        text-align: center;
        margin: 3.46667vw 0 1.86667vw;

        & > li {
            display: inline-block;

            & + li {
                margin-left: 8vw;
            }

            a,
            button {
                display: block;

                transition: opacity 0.3s;

                &:hover {
                    opacity: .5;
                }
            }

            button {
                display: inline-block;
                background: transparent;
                border: 0;
                padding-right: 1px;

                // fix pour android, icone share
           
            }

            svg {
                display: block;
                width: 7.6vw;
                height: 6.66667vw;
            }
        }
    }

    // Section link lists
    .link-lists {
        margin-bottom: 8.4vw;

        > li {
            text-align: left;

            body.theme-bg-sports & {
                border-bottom: 1px solid $footer-border-color-sport;

                &:first-child {
                    border-top: 1px solid $footer-border-color-sport;
                }
            }

            @include media-breakpoint-up(lg) {
                border-bottom: none;

                body.theme-bg-sports & {
                    border-bottom: none;

                    &:first-child {
                        border-top: none;
                    }
                }
            }
        }

        button {
            position: relative;
            color: inherit;
            font-size: 4.26667vw;
            line-height: 12.4vw;
            background: transparent;
            border: 0 none;
            width: 100%;
            appearance: none;
            border-bottom: medium none;

            body.theme-bg-sports & {
                font-family: $theme-font-sport-bold;
                border-bottom: 1px solid $footer-border-color-sport;
            }

            @include media-breakpoint-up(lg) {
                body.theme-bg-sports & {
                    border-bottom: none;
                }
            }

            cursor: pointer;
            outline: none;
            text-align: left;
            padding: 0 4.66667vw;

            &:after {
                content: "";
                position: absolute;
                right: 6.13333vw;
                top: 45%;
                width: 2vw;
                height: 2vw;
                margin-top: -0.93333vw;
                border-bottom: 2px solid #fff;
                border-right: 2px solid #fff;
                transform: rotate(45deg);
                transition: transform 0.35s;
            }

            &.active {
                &:after {
                    transform: rotate(225deg);
                }
            }
        }

        li {
            body.theme-bg-sports & {
                font-family: $theme-font-sport;
            }
        }

        li:last-child button {
            border-bottom: none;
        }

        button + ul {
            padding: 1.06667vw 4.66667vw 1.6vw;
            display: none;

            li {
                font-size: 4.26667vw;
                line-height: 12.4vw;

                a {
                    color: #fff;
                    display: block;
                }
            }
        }
    }

    .certification-icons {
        & > li {
            padding: 0 3.06667vw;
            height: 9.46667vw;
            margin-top: 4vw;
            width: 40%;
        }

        .logo-wla {
            width: 80%;
        }
		
		.logo-choix-de-pub-blanc{
			width: 80%;
		}
        
        .logo-aidejeu {
            width: 40%;
        }

        .logo-carte-credit {
            width: 40%;
        }
    }

    .copyright .notice {
        color: rgba(255, 255, 255, 0.5);
        text-align: center;
        font-size: 2.66667vw;
        margin: 3.06667vw 0 4vw;
    }
}

@include media-breakpoint-up(md) {
    footer {

        //Sections social icon
        .social-icons {
            margin: 0.84rem 0 0.84rem;

            & > li {
                & + li {
                    margin-left: 3.6rem;
                }

                svg {
                    width: 3.42rem;
    				height: 3rem;
                }
            }
        }

        // Section link lists
        .link-lists {
            margin-bottom: 3.78rem;

            button {
                font-size: 1.68rem;
                line-height: 5.58rem;
                padding: 0 4.8rem;

                &:after {
					right: 4.8rem;
					width: 0.9rem;
					height: 0.9rem;
					margin-top: -0.42rem;
                }
            }

            button + ul {
                padding: 0.48rem 4.8rem 0.72rem;

                li {
                    font-size: 1.44rem;
   					line-height: 4.32rem;
                }
            }
        }

        .certification-icons {
            & > li {
                padding: 0 1.38rem;
				height: 4.26rem;
				margin-top: 1.8rem;
            }
        }

        .copyright .notice {
			font-size: 1.2rem;
			margin: 1.38rem 0 1.8rem;
        }
    }
}

@include media-breakpoint-up(lg) {
    footer {
        margin: 0;

        //Sections social icon
        .social-icons {
            margin: 1.4rem 0 1.4rem;

            & > li {
                display: inline-block;

                & + li {
                    margin-left: 8.4rem;
                }

                svg {
                    width: 3.1rem;
                    height: 2.7rem;
                }
            }
        }

        // Section link lists
        .link-lists {
            text-align: center;
            margin-bottom: 1.3rem;
            padding: 2.3rem 0 3.2rem;

            > li {
                vertical-align: top;
                border-bottom: none;
                display: inline-block;

                &:first-child {
                    border-top: none;
                }

                a {
                    font-size: 1.2rem;
                }

                &:first-child > ul:before {
                    border-left: none;
                }
            }

            button {
                line-height: 1.2em;
                font-family: $theme-font-bold;
                font-size: 1.4rem;
                max-width: 28.8rem;
                padding: 0.8rem 3rem;
                cursor: default;

                &:after {
                    display: none;
                }

                + ul {
                    display: block;
                    padding: 0;
                    background: transparent;
                    max-width: 28.8rem;
                    padding: 0 3rem;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 2rem;
                        bottom: 2rem;
                        width: 0.1rem;
                        margin-left: -2.8rem;
                    }

                    li {
                        line-height: 1.1em;
                        padding: 0.3rem 0;

                        a {
                            transition: color 0.3s;
                        }
                    }
                }
            }
        }

        .certification-icons {
            text-align: center;

            .logo-aidejeu {
                max-width: 17rem;
            }

            .logo-carte-credit {
                max-width: 20rem;
            }

            li {
                display: inline-block;
                padding: 0;
                margin: 0;

                + li {
                    padding-left: 0.8rem;
                    margin-left: 1rem;
                }
            }
        }

        .copyright .notice {
            font-size: 1rem;
        }
    }
}

.screen-resolution-display {
    color: #000;
    position: fixed;
    bottom: 1em;
    right: 1em;
    width: 142px;
    min-height: 3em;
    padding: .5em;
    border: 1px solid;
    background: lightyellow;
    opacity: .3;
    font-family: monospace;
    font-size: 12px;
    z-index: 100;

    transition: opacity 0.35s;

    &:hover,
    &:active {
        opacity: 1;
    }
}

footer {

    .social-icons {
        & > li {
            svg {
                display: inline-block;
                vertical-align: middle;
                margin-left: 1.3333333333vw;
                margin-right: 1.3333333333vw;
                
                @include media-breakpoint-up(md) {
                    margin-left: 0.6rem;
                }
                
                @include media-breakpoint-up(lg) {
                    margin-left: 1rem;
                }
                
                @include media-breakpoint-up(md) {
                    margin-right: 0.6rem;
                }
                
                @include media-breakpoint-up(lg) {
                    margin-right: 1rem;
                }
            }

            a {
                text-transform: uppercase;
            }
        }
    }

    .link-lists {
        margin-bottom: 2vw;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }

        &.nous-joindre {
            margin-bottom: 0.2vw;
            text-align: center;
            margin-bottom: 1.2rem;
            padding-top: 4vw;
            padding-bottom: 4vw;

            @include media-breakpoint-down(sm) {
                border: none;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            @include media-breakpoint-only(md) {
                margin-top: 20px;
                margin-bottom: 35px;
			}

			@include media-breakpoint-up(md) {
                margin-bottom: 0;
                padding-top: 2rem;
                padding-bottom: 2rem;
            }
			
			@include media-breakpoint-up(lg) {
				border-bottom: 1px solid #242424;
				border-top: 1px solid #242424;
			}

            .btn {
                text-transform: uppercase;
                font-size: 2.8vw;
                margin-top: 0;
                margin-bottom: 0;

                @include media-breakpoint-up(md) {
                    font-size: 1.26rem;
                }

                @include media-breakpoint-up(lg) {
                    font-size: 1.3rem;
                }
            }


            li {
                border: none;
                vertical-align: middle;
                width: auto;
                line-height: 4.6666666667vw;
                border: none;
                text-align: center;
                display: inline-block;

                @include media-breakpoint-up(md) {
                    line-height: 2.4rem;
                }

                @include media-breakpoint-up(lg) {
                    line-height: 2rem;
                }

                @include media-breakpoint-up(xxl) {
                    line-height: 2.2rem;
                }
            }

            .service-clientele {
                margin-top: 0;
                margin-bottom: 0vw;
                margin-left: 0vw;
                margin-right: 6vw;
                text-align: center;
       
                @include media-breakpoint-up(md) {
                    margin-bottom: 0.6rem;
                }
                
                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                }
                
                @include media-breakpoint-up(md) {
                    margin-left: 0;
                }
                
                @include media-breakpoint-up(lg) {
                    margin-left: 0;
                }
                
                @include media-breakpoint-up(md) {
                    margin-right: 0;
                }
                
                @include media-breakpoint-up(lg) {
                    margin-right: 0;
                }

                a {
                    color: #fff;
                    font-family: $theme-font-regular;
                    font-size: 4.6666666667vw;

                    @include media-breakpoint-up(md) {
                        font-family: $theme-font-black;
                        font-size: 2.04rem;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 1.9rem;
                    }

                }

                li {
                    display: list-item;
                    font-size: 4vw;

                    @include media-breakpoint-up(md) {
                        font-size: 1.8rem;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 1.6rem;
                    }

                    &:first-child {
                        @include media-breakpoint-down(sm) {
                            margin-bottom: 0.7vw;
                        }
                    }
                }

                @include media-breakpoint-up(lg) {
                    li {
                        &:first-child {
                            margin-bottom: 0.2vw;
                        }
                    }
                }

                @include media-breakpoint-up(xxl) {
                    li {
                        &:first-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            

            @include media-breakpoint-up(md) {
                float: none;

                a {
                    font-size: 1.5rem;
                }

                li {
                    font-size: 1.4rem;
                    vertical-align: middle;
                    width: 25rem;
                    margin-left: 0.5rem;
                    margin-right: 0.5rem;

                    &:first-child {
                        margin-right: 5rem;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                li {
                    width: 100%;

                    &:first-child {
                        margin-right: 0;
                    }
                }

                > li {
                    width: 24%;
                }
            }

            @include media-breakpoint-up(xxl) {
                > li {
                    width: 22%;
                }
            }
        }
    }

    li:last-child {
        border-bottom: none;
    }

    .certification-icons {
        text-align: center;

        & > li {
            padding-left: 5%;
            padding-right: 5%;

            &.logo-jeu-doit-rester-un-jeu {
                width: 50%;
                height: 60px;
            }

            &.logo-aidejeu {
                width: 48%;
                height: 60px;
            }

            &.logo-carte-credit {
                width: 55%;
            }

            &.logo-wla {
                width: 95%;
                height: 60px;
            }
            
            &.logo-choix-de-pub-blanc {
                width: 95%;
                height: 25px;
            }

            &:first-child {
                padding-left: 5%;
                padding-right: 5%;
            }

            &:last-child {
                padding-right: 5%;
            }
        }

        li:last-child {
            border-bottom: none;
            margin-bottom: 12px;

            body.theme-bg-sports & {
                margin-top: 9px;
            }
        }

        ul.nous-joindre {
            padding-top: 5px;
            padding-bottom: 5px;

            li:last-child {
                margin-bottom: 0;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .certification-icons > li {
            display: inline-block;
            text-align: center;
            width: 32%;
            padding-left: 5%;
            padding-right: 5%;

            &.logo-jeu-doit-rester-un-jeu {
                width: 38%;
            }

            &.logo-aidejeu {
                width: 40%;
            }

            &.logo-carte-credit {
                width: 35%;
            }

            &.logo-wla {
                width: 100%;
            }
            
            &.logo-choix-de-pub-blanc {
                width: 100%;
            }
        }

        li:last-child {
            border-bottom: none;
            margin-bottom: 12px;

            body.theme-bg-sports & {
                // margin-top: 9px;
            
            }
        }

        ul.link-lists li:last-child {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        .certification-icons > li {
            display: inline-block;
            text-align: center;
            width: 100%;
            padding-left: 1%;
            padding-right: 1%;
            margin-right: 1.5rem;

            &:first-child {
                padding-left: inherit;
                padding-right: inherit;
            }

            &.logo-jeu-doit-rester-un-jeu {
                width: 100%;
                max-width: 20rem;
                height: 60px;
                padding-top: 6px;
            }

            &.logo-aidejeu {
                width: 100%;
                height: 54px;
            }

            &.logo-carte-credit {
                width: 100%;
            }

            &.logo-wla {
                width: 360px;
                height: 60px;
            }
            
            &.logo-choix-de-pub-blanc {
                width: 10%;
                height: 50px;
            }
        }

        li:last-child {
            border-bottom: none;

            // margin-bottom: 12px;
        
        }

        ul.link-lists li:last-child {
            margin-bottom: 0;
        }
    }
}


footer {
	svg {

		use {
			fill: #fff;
		}
	}
}

footer {
    background-color: #000;

	.autre-contenu {
		max-width: none;
		padding: 0;

        > .columns {
            padding-top: 0;
        }

		.footer123 {
			max-width: none;
			background: #f6f6f6;

            > .row {
                max-width: none;
            }
		}

		.item-banniere {
			padding: 10px 20px;
		}
	}

	
	.link-lists {
		color: white;
		& > li {
			color: $blanc-casse-poker;
		}
	}

	div > ul > li > ul > li {
		color: white;
		& > a {
			color: white;
		}
	}

	& > div > div > ul > li > a {
		color: white;
	}
}

@media only screen and (max-width: 1023px) {
	footer .link-lists {
        margin-bottom: 0;

		&.nous-joindre {
			margin-top: 0;
			margin-bottom: 0;
        }
        
        & > li:last-child {
			border-bottom: none;
		}

		button + ul {         
            a {
                color: #fff;
            }
		}

		.nous-joindre-item {
			border: none;
		}
	}
}

body.theme-bg-portail {
    footer {
        background-color: $footer-bg-color;

        .link-lists {
            button + ul {
                @include media-breakpoint-down(md) {
                    background-color: #fff;
                }

                a {
                    color: #000;
                    
                    @include media-breakpoint-up(lg) {
                        color: rgba(255, 255, 255, 0.55);
                    }
                }
            }

            > li {
                border-color: #336da6;
            }
		}
		
		.link-lists.nous-joindre {
            background-color: #073159;
            border-color: #336da6;
            
            @include media-breakpoint-up(lg) {
                background-color: transparent;
            }
		}
    }
}


body.theme-bg-sports {
    footer {
        background-color: $color-n1;
        .link-lists {
            button {
                &.active {
                    background-color: $footer-bg-color-sports;

                    @include media-breakpoint-up(lg) {
                        background-color: $footer-bg-color;
                    }
                }

                + ul {
                    background-color: $m-footer-link-list-bg-sports;

                    @include media-breakpoint-up(lg) {
                        background-color: transparent;
                    }

                    li {
                        a,
                        &:hover a {
                            color: rgba(255, 255, 255, 0.55);
                        }
                    }
                }
            }
        }
    }
}

/* Ajout styles page d'Accueil */

ul.link-lists.nous-joindre li a.btn {
    border-radius:8px !important;
}


body.footer-theme--blue,
body.theme-bg-mon-compte {

    footer {
        background-color: #00407D;

        .link-lists {

            &.nous-joindre {
                border-top-color: #336da6;
                border-bottom-color: #336da6;
            }

            button + ul li a {
                color: rgba(255,255,255,0.55);
            }
        }
    }
}